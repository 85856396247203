.navBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    gap: 0.25rem;
    left: 0;
    margin: .5rem 0 0 1rem;
    text-decoration: none;
}

.navBtn__Icon {
    width: 1rem;
    height: 1rem;
}
.navBtn__textContent {
    font-family: "Source Sans 3", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 120%;
}


@media only screen and (max-width: 900px) {
    .navBtn__textContent {
        display: none;
    }
}

@media only screen and (max-width: 520px) {
    .navBtn {
        margin: 1.25rem 0 0 1.25rem;
    }
}