.modal-add-note {
  background-color: white;
  border-radius: 20px;
  width: 56.9vw;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.6vw;
}
.modal-add-note > h1 {
  margin: 0 2rem 0 1rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--headings-blue);
  padding: 3.7vw 0 3.2vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-add-note > h1 > img {
  margin-left: 3vw;
  height: 30px;
}
.modal-add-note > p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2.6vw;
  margin-top: 0.5vw;
  width: 91%;
  font-family: 'Nunito Sans';
}
.modal-add-note > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-right: 1%;
}
.modal-add-note > img {
  position: absolute;
  right: 1.9vw;
  top: 1.9vw;
  cursor: pointer;
  height: 1.1vw;
  min-height: 13px;
}
.cancel-button {
  padding: 8px 16px;
  width: 76px;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #cbd0e1;
  border-radius: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
}
.add-note-modal-button {
  padding: 10px 42.5px;
  min-width: 76px;
  font-weight: 600;
  background: var(--color-primary);
  border: none;
  border-radius: 20px;
  color: white;
  white-space: nowrap;
  font-family: 'Montserrat';
  cursor: pointer;
  width: fit-content;
}
.notes-body {
  max-height: 50vh;
  overflow-y: scroll;
}
.edit-note-wrapper,
.edit-note-body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.edit-note-wrapper {
  flex-direction: column;
}
.edit-note-body > textarea {
  padding: 24px 32px;
  font-family: 'Nunito Sans';
  color: var(--primary-typography);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 20px;
  min-height: 200px;
  resize: none;
  width: 95%;
  box-sizing: border-box;
}
.notes-body::-webkit-scrollbar,
.edit-note-body > textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notes-body::-webkit-scrollbar-track,
.edit-note-body > textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.notes-body::-webkit-scrollbar-thumb,
.edit-note-body > textarea::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}
.edit-note-body > button {
  position: absolute;
  bottom: 16px;
  right: 2vw;
}
.note-container {
  display: flex;
  flex-direction: column;
}
.note-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.1vw;
}
.note-content,
.note-content > span {
  max-width: 85%;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-typography);
}
.note-content > span {
  color: #c2c2c2;
}
.note-buttons > img {
  cursor: pointer;
}
.note-buttons > img:first-child {
  margin-right: 1.1vw;
}
@media only screen and (max-width: 900px) {
  .modal-add-note {
    width: 89vw;
  }

  .edit-note-body > button {
    right: 3vw;
  }
}
@media only screen and (max-width: 520px) {
  .modal-add-note {
    width: 85vw;
  }
  .edit-note-body > textarea {
    padding: 16px 20px;
  }
  .note-wrapper {
    flex-direction: column;
  }
  .edit-note-body > button {
    bottom: -43px;
    right: 0;
    width: 95%;
  }
  .notes-tip {
    margin: 50px 0 0 2.5vw;
  }
  .modal-add-note > img {
    right: 3.9vw;
    top: 3.9vw;
  }

  .note-content,
  .note-content > span {
    max-width: 95%;
  }

  .modal-add-note > div {
    padding: 0 0 0 1.47vw;
  }
}
