.tooltip {
    position: absolute;
    top: 50px;
    display: none;
    left: -15px;
    box-shadow: 0px 6px 16px -8px rgba(47, 45, 70, 0.06), 0px 8px 32px rgba(47, 45, 70, 0.14);
    border-radius: 4px;
    z-index: 100;
}
.tooltip__content {
    position: relative;
    cursor: pointer;
}

.tooltip--visible {
    display: block;
}

.tooltip__title {
    font-size: 16px;
    color: #030054;
    font-weight: 600;
    padding: 0 0 5px;
    margin: 0;
}

.tooltip__triangle {
    position: absolute;
    z-index: 99;
    display: inline-block;
    width: 25px;
    height: 20px;
}

.tooltip__triangle.top {
    top: -24px;
}

.tooltip__triangle.bottom {
    bottom: -24px;
    transform: rotate(180deg);
}