.container * {
    margin: 0;
    box-sizing: border-box;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    font-family: 'Source Sans 3', sans-serif;
}

.label,
.input::placeholder {
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1rem;
}

.label {
    font-weight: 600;
}

.input::placeholder {
    font-weight: 400;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    position: relative;
}
.input {
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.50);
    display: flex;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    cursor: pointer;
    border: 2px solid var(--dark-grey);
    border-radius: 10px;
    background-color: var(--white-color);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.input::placeholder {
    color: rgba(0, 0, 0, 0.50);
}
.input:focus {
    outline: none;
}

.inputArrow {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    margin: 0.5rem 1.25rem 0.5rem 0;
    right: 0;
    top: 0;
    cursor: pointer;
    background-image: url("../../../images/arrowSmallGrey.svg");
    background-size: cover;
}