.youtube-modal {
    border-radius: 10px;
    width: 50vw;
    max-width: 800px;
    overflow: hidden;
}
.youtube-modal-close {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #464646;
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-modal-close::before,
.youtube-modal-close::after {
    content: '';
    position: absolute;
    width: 60%;
    height: 4px;
    border-radius: 4px;
    background-color: #ffffff;
}

.youtube-modal-close::before {
    transform: rotate(45deg);
}

.youtube-modal-close::after {
    transform: rotate(-45deg);
}

.youtube-modal-body iframe {
    width: 100%;
    height: initial;
    aspect-ratio: 16 / 10;
}

@media only screen and (max-width: 520px) {
    .youtube-modal {
        width: 85vw;
    }
    .modal-advanced-search-universal-blur {
        height: 47vw;
    }
}

