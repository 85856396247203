.checkboxContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.checkboxContainer input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.1rem solid var(--color-primary);
    border-radius: 4px;
    background: var(--white-color);
}