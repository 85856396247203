.line {
  display: block;
}

.home-page {
  background-color: #f4f3f3;
  padding-top: 2.1vw;
  font-family: "Source Sans 3", sans-serif;
}

.advanced-wrapper {
  text-decoration: none;
}

.advanced-btn {
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: #00B4D8;
  cursor: pointer;
  /*padding: 16px 16px;*/
  border: 1px solid #FFFFFF;
  box-shadow: 4px 4px 20px rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1.1vw;
  width: 11vw;
  height: 3.4vw;
}

.advanced-btn img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.advanced-btn span {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.25vw;
  padding: 0;
  white-space: nowrap;
}

.shadow {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.shadow-second,
.shadow-bg {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

.shadow-third {
  position: absolute;
  z-index: 100001;
  top: 22.5vw;
  right: 24vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shadow-edit {
  position: fixed;
  z-index: 1000000;
  right: 0;
  left: 0;
  margin: auto;
  top: 43.2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shadow .search-college-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000000;
  position: fixed;
  top: 17.5vw;
}

.shadow .search-college>.search-college-input {
  background-color: #F1F1F1;
}

.search-college-cont {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}

.search-college-cont-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8vw;
  text-align: center;
  color: #464646;
  margin-bottom: 20px;
}

.home-page-wrapper {
  background-color: white;
  margin: 0 4.6vw;
  border-radius: 10px;
}

.home-page-navigation {
  display: flex;
  justify-content: space-evenly;
  padding: 0 5.8vw;
  margin-bottom: 0.3vw;
}

.chart-data-header-mobile {
  display: none;
}

.chart-data-legends-mobile {
  display: none;
}

.home-page-navigation>a {
  text-decoration: none;
}

.home-page-navigation>span,
.home-page-navigation>a {
  font-weight: 600;
  font-size: 1.7vw;
  color: var(--headings-blue);
  padding: 2vw 0 0.7vw 0;
}

.home-search-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 5vw 0 9.5vw 0;
  margin: 0 5.9vw 4.7vw;
  border-radius: 10px;
  background-image: url('../../images/college_insights_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
}

.home-search-background::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #000000B2;
  border-radius: inherit;
}

.home-search-background-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page-shadow {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.home-search-background>div>div>h3,
.home-search-background>div>h3 {
  font-weight: 600;
  font-size: 1.8vw;
  margin: 0 0 2.2vw 0;
  line-height: 1.9vw;
  cursor: default;
  font-family: 'Source Sans 3', sans-serif;
}

.home-page-navigation>.selected-nav {
  color: var(--color-primary);
  border-bottom: 0.3vw solid var(--color-primary);
  border-radius: 0.3vw;
  width: fit-content;
  margin-bottom: 0.2vw;
}

.search-college {
  width: 48vw;
  display: flex;
  justify-content: center;
  gap: 1vw;
  align-items: center;
  position: relative;
}

.search-college-advanced-search {
  cursor: pointer;
  height: 3.5vw;
}

.advanced-search-btn {
  display: flex;
  justify-content: center;
  position: relative;
}

.advanced-btn-hover h4 {
  font-size: 16px;
  margin: 0;
  color: #464646;
}

.advanced-btn-hover p {
  margin: 0;
  font-size: 14px;
}

.advanced-search-btn .advanced-btn-hover {
  text-align: center;
  background-color: #ffffff;
  pointer-events: none;
  line-height: 150%;
  position: absolute;
  bottom: calc(100% + 5px);
  padding: 5px 10px;
  width: 200px;
  border-radius: 4px;
  font-size: 16px;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.advanced-search-btn::after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 15px;
  background-color: #ffffff;
  /*transform: rotate(45deg);*/
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  clip-path: polygon(100% 45%, 0 45%, 50% 100%);
}

.advanced-search-btn:hover .advanced-btn-hover,
.advanced-search-btn:hover::after {
  opacity: 1;
  visibility: visible;
}

.search-college>.search-college-input {
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.05);
  background: #f1f1f1;
  border-radius: 10px;
  height: 3.6vw;
  border: none;
  width: 100%;
  height: 3.5vw;
  padding: 0 3.8vw;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 1.2vw;
  cursor: pointer;
  font-family: 'Source Sans 3', sans-serif;
  margin-right: 10px;
}

.search-college>.search-college-input {
  background: white;
}

.search-college>.search-college-img {
  position: absolute;
  left: 1.5vw;
  height: 1.3vw;
  top: 1.2vw;
}

.search-college>span {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: var(--color-primary);
  position: absolute;
  right: 0;
  height: 100%;
  width: 5.5vw;
  padding: 0;
}

.search-college>span>img {
  position: relative;
  top: 0.9vw;
  left: 2.3vw;
  width: 1.3vw;
}

.recommended-colleges-wrapper {
  border-radius: 10px;
}

.recommended-colleges-wrapper>header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2.3vw 8.7vw 3vw;
}

.recommended-colleges-wrapper>span {
  font-weight: 600;
  font-size: 1.3vw;
  text-decoration: underline;
  line-height: 1.9vw;
  color: var(--color-primary);
  text-decoration-thickness: 0.13vw;
  text-underline-offset: 0.6vw;
  display: flex;
  text-align: end;
  flex-direction: column;
  padding: 0.5vw 14.7vw 0 0;
  margin-bottom: 2.3vw;
  cursor: pointer;
}

.recommendation-by-categories {
  width: 100%;
}

.recommendation-by-categories-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recommended-colleges-wrapper>header .recommendation-by-categories-description>h6 {
  margin: 0;
  font-size: 1.8vw;
}

.recommended-colleges-wrapper>header>div>div {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 0.93vw;
}

.recommended-colleges-wrapper .recommended-colleges-recommends {
  color: var(--primary-typography);
}

.recommended-colleges-subtitle {
  margin: 4px 0 .5vw;
  font-size: 1.1vw;
  color: var(--dark-grey);
  font-weight: 400;
}

.recommended-colleges-wrapper .recommended-colleges-recommends>h3 {
  color: var(--primary-typography);
  font-weight: 600;
  font-size: 1.05vw;
  margin-bottom: 1.04vw;
}

.recommended-colleges-wrapper .trending-college-description>h3 {
  color: var(--headings-blue);
  font-family: 'Montserrat';
}

.recommended-colleges-wrapper .trending-college-photo>img {
  height: 200px;
}

.recommended-colleges-wrapper>header>div>div>span,
.recommended-colleges-wrapper>header>div>div>a {
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 2;
  padding: 0;
  text-decoration: none;
}

.recommended-colleges-btns {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0 !important;
}

.edit-btn-wrapper,
.edit-btn-mobile-wrapper {
  text-decoration: none;
  align-self: flex-end;
  margin-bottom: 0 !important;
}

.edit-list-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  color: var(--color-primary);
  font-weight: 600;
  font-size: 18px;
}

.edit-list-btn::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
}

.edit-list-btn.locked {
  color: var(--dark-grey);
}

.edit-list-btn.locked::before {
  background-color: var(--dark-grey);
}

.edit-list-btn>img {
  width: 16px;
  object-fit: contain;
}

.recommended-colleges-wrapper>header>div>div>img {
  cursor: pointer;
  width: 0.7vw;
}

.recommended-colleges-wrapper>header>div>button {
  font-weight: 600;
  font-size: 0.93vw;
  padding: 0.7vw 1.3vw;
}

.colleges-by-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 0 8.7vw;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-wrapper>header>h6 {
  margin: 0;
  font-size: 1.8vw;
}

.calendar-wrapper>header>img {
  cursor: pointer;
  width: 1.5vw;
}

.calendar-wrapper>header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vw 17.2vw 3vw 14.7vw;
  box-sizing: border-box;
  width: 100%;
}

.chart {
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.1);
  border-radius: 2vw;
  overflow: hidden;
  margin-bottom: 5.6vw;
}

.chart-data {
  width: 64.5vw;
  display: grid;
}

.chart-lables {
  background: var(--color-primary);
  color: #ffffff;
  padding: 7.9vw 1.3vw 1.4vw 2.1vw;
  display: grid;
  width: 14.9vw;
}

.chart-lables>p {
  margin: 0;
  font-size: 0.93vw;
  height: 3.6vw;
}

.chart-lables>img {
  width: 100%;
}

.chart-data-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
}

.chart-data-header>h5 {
  color: var(--dark-grey);
  margin: 1.2vw;
  font-size: 1.1vw;
  line-height: 1.9vw;
}

.chart-data-header>img {
  cursor: pointer;
  align-self: center;
  height: 0.8vw;
}

.chart-data-header>.right-arrow {
  transform: rotate(180deg);
}

.chart-data-months {
  color: var(--dark-grey);
  margin: 0 1.9vw 0.6vw 2.4vw;
  display: flex;
  height: fit-content;
  width: 57.5vw;
  justify-content: space-between;
}

.chart-data-months>span {
  font-weight: 700;
  font-size: 0.93vw;
  line-height: 1.1vw;
  white-space: nowrap;
  padding: 0;
}

.chart-section {
  background: #eadfed;
  height: 1.3vw;
  margin: 1.2vw 3.4vw 1.2vw 0.9vw;
  border-radius: 2vw;
  position: relative;
  width: 60.2vw;
}

.chart-section>div {
  height: 100%;
  border-radius: 2vw;
  position: absolute;
}

.chart-data-legends {
  display: flex;
  justify-content: space-between;
  margin: 0.4vw 12.6vw 1.6vw 2vw;
}

.chart-data-legends>div {
  font-weight: 600;
  font-size: 0.93vw;
  color: var(--dark-grey);
  display: inline-flex;
  align-items: center;
}

.chart-data-legends>div>span {
  height: 1.1vw;
  width: 1.1vw;
  padding: 0;
  border-radius: 50%;
  margin-right: 0.6vw;
  cursor: default;
}

.parent {
  background: var(--parent-color);
}

.family {
  background: var(--family-color);
}

.student {
  background: var(--student-color);
}

.recommendation-by-categories>div>span {
  color: var(--color-primary);
}

.recommendation-by-categories>div {
  position: relative;
}

.recommendation-by-categories>div>.autocomplete {
  width: 90%;
  top: 150%;
}

.look-up {
  transform: rotate(180deg);
}

.look-down {
  transform: rotate(0);
}

.colleges-by-categories>div.no-list-container-home {
  width: 100%;
}

.no-list-container-home>h6 {
  text-align: center;
}

.no-list-container.no-list-container-home>img {
  width: 240px;
  height: 160px;
}

.visit-modal-third-box-recommended-by {
  color: #464646;
  font-size: 1.1vw;
  margin: 0;
}

.visit-modal-third-box h6 {
  margin: 15px 0 0;
  font-size: 1.4vw;
}

.visit-modal-third-box p {
  margin: 5px 0 0;
}

@media only screen and (max-width: 900px) {

  .advanced-btn {
    padding: 16px 14px;
    font-size: 2.4vw;
    height: 7vw;
    width: auto;
  }

  .advanced-btn span {
    font-size: 2.25vw;
  }

  .shadow .search-college-cont {
    top: 30vw;
  }

  .advanced-search-btn::before {
    font-size: 14px;
  }

  .home-page {
    padding-top: 3.2vw;
  }

  .home-page-wrapper {
    margin: 0;
  }

  .home-page-navigation {
    justify-content: space-between;
    margin-bottom: 1.3vw;
    padding-top: 0.8vw;
  }

  .home-page-navigation>.selected-nav {
    border-bottom: 0.5vw solid var(--color-primary);
  }

  .home-search-background {
    margin: 0 5.8vw 4.7vw;
    padding: 7.5vw 6.6vw 20vw;
    font-size: 3.2vw;
  }

  .home-search-background>div>div>h3,
  .home-search-background>div>h3 {
    margin-bottom: 4.3vw;
  }

  .home-page-navigation>a,
  .home-page-navigation>span {
    font-size: 3.2vw;
  }

  .colleges-by-categories {
    gap: 5.2vw;
    padding: 0 5.3vw;
  }

  .recommended-colleges-wrapper>header {
    padding: 4vw 5.8vw 5.8vw;
  }

  .search-college>.search-college-input,
  .chart-data-header>h5,
  .recommended-colleges-wrapper>header>div>div,
  .recommended-colleges-wrapper>header>div>div>span,
  .recommended-colleges-wrapper>header>div>div>.recommended-colleges-wrapper>header>div>button,
  .recommended-colleges-wrapper>span {
    font-size: 1.8vw;
    text-align: center;
    padding: 1.3vw 0;
  }

  .recommended-colleges-wrapper>header>div>div>a,
  .recommended-colleges-wrapper>header>div>div>span {
    font-size: 2.6vw;
    text-decoration-thickness: 0.26vw;
    align-self: flex-end;
    margin-bottom: 10px;
  }

  .recommended-colleges-wrapper>header>div>div {
    padding-bottom: 4.6vw;
  }

  .recommendation-by-categories>div>.autocomplete {
    top: 70%;
    right: 36px;
  }

  .recommended-colleges-wrapper>header>div>div>img {
    width: 10px;
    padding-left: 4px;
  }

  .recommended-colleges-wrapper>header>div>h6,
  .home-search-background>div>div>h3,
  .home-search-background>div>h3 .calendar-wrapper>header>h6 {
    font-size: 3.2vw;
  }

  .recommended-colleges-wrapper>header>div>button {
    padding: 1.3vw 2.6vw;
  }

  .recommended-colleges-wrapper>span {
    margin: 3.2vw 0 5.3vw;
    font-size: 2.6vw;
    text-decoration-thickness: 0.26vw;
  }

  .recommendation-by-categories-top {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .recommended-colleges-wrapper>header .recommendation-by-categories-top {
    text-align: left;
    padding: 0;
  }

  .recommended-colleges-wrapper>header .recommendation-by-categories-description>h6 {
    font-size: 2.2vw;
  }

  .recommended-colleges-wrapper>header .recommended-colleges-recommends {
    padding: 0;
  }

  .recommended-colleges-wrapper>header>div>div {
    font-weight: 600;
    font-size: 0.93vw;
  }

  .recommended-colleges-subtitle {
    margin-bottom: 1.5vw;
    font-size: 2vw;
  }

  .recommended-colleges-wrapper .recommended-colleges-recommends>h3 {
    margin-bottom: 1.5vw;
    font-size: 2vw;
  }

  .search-college {
    width: 71.8vw;
  }

  .search-college>.search-college-input {
    padding: 0 0 0 7.5vw;
    text-align: start;
    height: 7vw;
    font-size: 2.4vw;
  }

  .search-college-advanced-search {
    height: 7vw;
  }

  .search-college>.search-college-img {
    width: 2.68vw;
    height: 2.68vw;
    left: 3vw;
    top: 2vw;
  }

  .search-college>span {
    width: 12vw;
  }

  .search-college>span>img {
    width: 2.68vw;
    height: 2.68vw;
    left: 4.5vw;
    top: 2.2vw;
  }

  .chart-lables>p,
  .chart-data-months>span {
    font-size: 10px;
  }

  .chart-data-legends>div {
    font-size: 8.5px;
  }

  .chart-data-legends {
    margin-right: 5.6vw;
  }

  .chart-lables {
    padding-top: 13vw;
  }

  .chart-lables>p {
    height: 6.5vw;
  }

  .chart-section {
    height: 2.3vw;
    margin: 2.2vw 3.4vw 2.2vw 0.9vw;
  }

  .chart-data-header>img {
    height: 7px;
  }

  .chart-data-legends>div>span {
    width: 10px;
    height: 10px;
  }

  .calendar-wrapper {
    margin-top: 4.3vw;
  }

  .calendar-wrapper>header {
    padding: 0 5.8vw 4.3vw;
  }

  .calendar-wrapper>header>img {
    width: 2.9vw;
  }
}

@media only screen and (max-width: 645px) {
  .chart-lables>p {
    font-size: 8px;
  }

  .chart-lables {
    padding-top: 12vw;
  }
}

@media only screen and (max-width: 520px) {
  .advanced-btn {
    height: auto;
    margin-top: 15px;
    font-size: 16px;
  }

  .advanced-btn span {
    font-size: 4.25vw;
  }

  .shadow .search-college-cont {
    top: 41vw;
  }

  .shadow .search-college-cont-text {
    display: block;
  }


  .advanced-search-btn::before {
    font-size: 12px;
  }

  .line {
    display: none;
  }

  .home-page-wrapper {
    margin: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .home-page-shadow {
    border-radius: 0;
  }

  .home-page-navigation {
    padding: 3.5vw 5.8vw 0;
    justify-content: space-evenly;
  }

  .home-page-navigation>a,
  .home-page-navigation>span {
    font-size: 4.1vw;
    padding: 0 0 1.8vw 0;
  }

  .home-page-navigation>.selected-nav {
    border-bottom-width: 0.5vw;
    border-top: 0;
  }

  .home-search-background>div>div>h3,
  .home-search-background>div>h3 {
    display: none;
  }

  .home-search-background {
    padding: 21vw 0;
    margin: 0;
    margin-bottom: 0;
    border-radius: 0;
  }

  .search-college {
    width: calc(100vw - 50px);
    flex-direction: column;
  }

  .search-college>.search-college.input {
    height: 10.3vw;
    padding: 0 0 0 10.8vw;
    font-size: 3.1vw;
  }

  .search-college>.search-college-img {
    height: 18px;
    width: auto;
    object-fit: contain;
    top: 18px;
    left: 20px;
  }

  .search-college>span {
    width: 14.1vw;
  }

  .search-college>span>img {
    width: 3vw;
    top: 4vw;
    left: 5.4vw;
  }

  .recommended-colleges-wrapper {
    padding-top: 0;
    background-color: white;
  }

  .recommended-colleges-wrapper>header {
    flex-direction: column;
    padding: 0;
  }

  .recommendation-by-categories {
    align-self: flex-start;
    padding: 3vw 4.9vw;
  }

  .recommended-colleges-wrapper>header .recommendation-by-categories-description>h6 {
    font-size: 4.1vw;
  }

  .recommended-colleges-wrapper>header>div>div {
    font-size: 3.6vw;
  }

  .recommended-colleges-wrapper>header>div>div>img {
    width: 3.6vw;
    padding-left: 0.4vw;
  }

  .recommended-colleges-wrapper>header>div>button {
    padding: 2.6vw;
    white-space: nowrap;
    font-size: 3.6vw;
    margin: 7.4vw 0 1.3vw 5.9vw;
  }

  .recommended-colleges-wrapper>header>div:last-child {
    align-self: flex-start;
    width: initial;
    background-color: #fff;
  }

  .recommended-colleges-wrapper>header .recommended-colleges-recommends {
    padding-bottom: 20px;
  }

  .recommended-colleges-btns {
    flex-direction: column;
  }

  .edit-btn-mobile-wrapper {
    display: flex;
    justify-content: flex-end;
    align-self: initial;
    margin: 0 -4.9vw;
    padding: 17px 4.9vw 0;
    background-color: #f3f2f2;
    transform: translateY(3vw);
  }

  .recommended-colleges-wrapper>header>div>div>a,
  .recommended-colleges-wrapper>header>div>div>span {
    font-size: 3.6vw;
    text-decoration-thickness: 0.5vw;
    text-underline-offset: 1.3vw;
  }

  .recommended-colleges-subtitle {
    margin-bottom: 18px;
    font-size: 3vw;
  }

  .recommended-colleges-wrapper .recommended-colleges-recommends>h3 {
    margin-bottom: 2vw;
    font-size: 3vw;
  }

  .recommended-colleges-wrapper>span {
    font-size: 3.6vw;
    margin-bottom: 0;
    text-decoration-thickness: 0.5vw;
    text-underline-offset: 1.5vw;
    padding-right: 5.6vw;
  }

  .calendar-wrapper {
    margin-top: 9.5vw;
  }

  .colleges-by-categories {
    background-color: #f3f2f2;
    margin-bottom: -12%;
    margin-top: 0;
    padding: 30px 4.3vw 15%;
  }

  .calendar-wrapper>header>h6 {
    font-size: 4.1vw;
  }

  .calendar-wrapper>header {
    padding: 7.7vw 5.6vw 10vw 4.9vw;
  }

  .calendar-wrapper>header>img {
    width: 3.6vw;
  }

  .chart-lables>p {
    font-size: 3.1vw;
    height: 10.7vw;
  }

  .chart-lables>p:last-of-type {
    height: fit-content;
  }

  .chart-data-header>h5 {
    font-size: 3vw;
  }

  .chart-data-legends-mobile>div {
    font-size: 3.6vw;
    font-weight: 600;
    color: #a7a7a7;
    margin-bottom: 2.8vw;
    display: flex;
  }

  .chart-data-legends-mobile>div>span {
    border-radius: 50%;
    margin-right: 2.8vw;
    padding: 5.4vw 5.4vw 0 0;
  }

  .chart-data-legends-mobile {
    display: block;
    margin: 0 0 9.5vw 2.3vw;
  }

  .chart-data-legends {
    display: none;
  }

  .chart-data-header {
    display: none;
  }

  .chart-data-header-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 4.9vw;
  }

  .chart-data-header-mobile>h5 {
    color: var(--dark-grey);
    font-size: 5.1vw;
    margin: 0 5.6vw;
  }

  .chart-data-header-mobile>img {
    cursor: pointer;
    height: fit-content;
    align-self: center;
    width: 2.6vw;
  }

  .chart-data-header-mobile>.right-arrow {
    transform: rotate(180deg);
  }

  .chart-data-months>span {
    font-size: 3.6vw;
    color: var(--primary-typography);
    line-height: 4.1vw;
    padding-right: 7.7vw;
  }

  .chart-data-months {
    width: fit-content;
    margin: 6.4vw 0 -1.3vw 10.6vw;
  }

  .chart-data {
    overflow-x: scroll;
    width: unset;
    background-color: white;
    margin-bottom: 5.6vw;
  }

  .chart-data::-webkit-scrollbar {
    height: 1.3vw;
  }

  .chart-data::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 5.1vw;
    margin: 0 3.6vw 5.6vw 15.1vw;
  }

  .chart-data::-webkit-scrollbar-thumb {
    background-color: var(--primary-typography);
    border-radius: 5.1vw;
    cursor: pointer;
  }

  .chart-section {
    height: 4.9vw;
    margin: 6.4vw 3.1vw;
    width: 192.3vw;
    border-radius: 7.7vw;
  }

  .chart-section>div {
    border-radius: 7.7vw;
  }

  .chart-lables {
    padding: 15.6vw 2.6vw 5.6vw 3.3vw;
    min-width: 36.9vw;
    box-sizing: border-box;
  }

  .chart-lables>img {
    display: none;
  }

  .chart {
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.1);
    width: 90.8vw;
    justify-content: flex-start;
    border-radius: 5.1vw;
    margin-bottom: 5.2vw;
  }

  .search-college-advanced-search {
    width: 10.5vw;
  }

  .search-college>.search-college-input {
    box-sizing: border-box;
    border-radius: 5px;
    height: 54px;
    font-size: 18px;
    margin: 0;
    padding-left: 56px;
  }

  .search-college>.search-college-input::placeholder {
    font-size: 18px;
  }
}

@media only screen and (min-width: 2000px) {
  .recommended-colleges-wrapper .trending-college-photo>img {
    height: fit-content;
  }

  .recommended-colleges-wrapper>header>div>button {
    border-radius: 60px;
  }
}

.visit-modal {
  background-color: white;
  border-radius: 4px;
  min-width: 385px;
  width: 30vw;
  max-width: 386px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: calc(100% + 5px);
}

.visit-modal::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  transform: rotate(45deg);
  position: absolute;
  display: block;
  align-self: center;
  bottom: 0;
}

.visit-modal-second {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: auto;
  width: 30vw;
  min-width: 392px;
  padding: 0;
  bottom: 100%;
}

.visit-modal-second-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background-color: var(--color-primary);
  padding: 5px 19px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}


.visit-modal-second p {
  margin: 0;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #403E59;
}

/*.visit-modal.visit-modal-second .visit-modal-cont-bottom {*/
/*  margin-top: 10px;*/
/*}*/

.visit-modal-second-box {
  box-sizing: border-box;
  background: #00b4d8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 14px;
  gap: 8px;
  align-self: center;
  margin: 10px 10px 10px 3px;
  border: 1px solid #FFFFFF;
  box-shadow: 4px 4px 20px rgba(255, 255, 255, 0.3);
  height: 3.5vw;
}

.visit-modal-second-box-inner {
  position: absolute;
  background-color: #ffffff;
  border-radius: 4px;

}

.visit-modal-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background-color: var(--color-primary);

  padding: 5px 18px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.visit-modal-main {
  display: flex;
  /*gap: 12px;*/
  padding: 9px 20px;
  border-bottom: 1px solid rgba(126, 16, 110, 0.2);
}

.visit-modal-main-text {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #464646;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.visit-modal-main-text>strong {
  font-size: 1.4vw;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.visit-modal-main-img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-top: 5px;
}

.visit-modal-main-text-third {
  white-space: pre-wrap;
}

.visit-modal-main-text-arrow-down {
  box-sizing: border-box;
  position: absolute;
  top: 60px;
  width: 20px;
  height: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
}

.visit-modal-main-text-arrow-down-second {
  position: absolute;
  top: 76px;
  left: 165px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
}

.visit-modal-main-text-arrow-down-third {
  position: absolute;
  top: 125px;
  left: 200px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
}

.visit-modal-cont-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 9px 18px;
}

.visit-modal-cont-bottom-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 56px;
  left: calc(50% - 37px);
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

.visit-modal-cont-bottom-cont-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.visit-modal-btn {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  width: 95px;
  height: 32px;
  align-items: flex-end;
  cursor: pointer;
}

.visit-modal-btn-second {
  color: var(--color-primary);
  background-color: #fff;
  margin-left: 8px;
}

.visit-modal-cont-bottom-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #403e59;
  margin: 0;
}

.visit-modal-btn:hover {
  color: #fff;
  background: #7e106e;
}

.visit-modal-btn:active {
  background: #530b4a;
}

.visit-modal-first {
  position: absolute;
  top: 60px;
  left: -74px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
}

.visit-modal-first-additionalText {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #464646;
  margin: 0 0 30px 0;
}

.visit-modal-first-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.visit-modal-first-pc-search {
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 5;
  width: 20px;
  height: 20px;
}

.visit-modal-first-mobile-search {
  display: none;
}

.visit-modal-first-input {
  position: relative;
  width: 490px;
  background: #f1f1f1;
  border-radius: 10px;
  border: 0px solid #f1f1f1;
}

.visit-modal-first-input::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  padding-left: 50px;
  color: rgba(0, 0, 0, 0.5);
}

.visit-modal-first-advanced-search {
  margin-left: 10px;
}

.visit-modal-third {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  justify-content: center;
  max-width: 390px;
  border-radius: 4px;
  background: white;
  /*padding: 20px 10px;*/
  bottom: calc(100% + 20px);
  right: 10vw;
}

.visit-modal-third-box {
  padding: 10px 25px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  color: #7e106e;
  width: 76.5vw;
}

.visit-modal-third-box h5 {
  position: relative;
  margin: 0;
  color: #7e106e;
}

.visit-modal-third-box h5::before {
  content: '';
  width: 100%;
  position: absolute;
  height: 2px;
  background-color: #7e106e;
  bottom: 0;
}

.visit-modal-third-additionalText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #7e106e;
  padding-bottom: 5px;
  border-bottom: 2px solid #7e106e;
}

.visit-modal-second-box>img {
  display: block;
  width: 16px;
}

.visit-modal-second-box>p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3vw;
  text-align: center;
  color: #fff;
  margin: 0;
}

.visit-modal-main-third {
  flex-direction: column;
}

.visit-modal-third-header {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background: #7E106E;
  border-radius: 4px 4px 0px 0px;
  padding: 5px 18px;
}

.visit-modal-main-third {
  padding: 0;
}

.visit-modal-cont-bottom-text-third {
  margin: 0;
}

.visit-modal-cont-bottom-third,
.visit-modal-main-text-third {
  padding: 9px 18px;
}

/*New lock section*/
.lockedContent__Container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.lockedContent {
  filter: blur(5px);
  pointer-events: none;
}

.containerLockCard {  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
}

.lockCardHome {
  padding: 1rem 8.7vw;
}



@media only screen and (max-width: 1250px) {
  .visit-modal-main-text-arrow-down {
    left: 10vw;
  }

  /*.visit-modal-second-box {*/
  /*  right: 2vw;*/
  /*  margin: 5px 10px 10px 0;*/
  /*}*/

  .visit-modal-main-text-arrow-down-third {
    top: 115px;
    left: 16vw;
  }

  .visit-modal-main-text {
    margin: 0;
  }

  .visit-modal-third {
    bottom: calc(100% + 20px);
  }


}

@media only screen and (max-width: 1150px) {
  .shadow-third {
    right: 23vw;
    top: calc(22vw + 7px)
  }

  .visit-modal-third {
    margin-left: -10vw;
  }

  .visit-modal-btn {
    font-size: 1.4vw;
    width: 86px;
    height: 28px;
  }

  .visit-modal-second {
    width: 37vw;
  }

  .visit-modal-second-modal {
    font-size: 1.4vw;
    width: 100px;
    height: 28px;
  }

  .visit-modal-third {
    margin-left: 45vw;
  }
}

@media only screen and (max-width: 950px) {
  .visit-modal-main {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 901px) {
  .shadow-third {
    position: absolute;
    z-index: 100001;
    top: 37vw;
    right: calc(13vw - 10px);
  }

  .visit-modal {
    width: 50vw;
  }

  .visit-modal-main-text {
    /*font-size: 2vw;*/
    text-align: left;
  }

  .visit-modal-main-text-arrow-down {
    left: 18vw;
  }

  .search-college-cont-text {
    font-size: 2.5vw;
    margin-bottom: 25px;
  }

  .visit-modal-second-box {
    height: 7vw;
    padding: 16px 18px;
  }

  .visit-modal-second-box>img {
    width: 2vw;
  }

  .visit-modal-second {
    width: 50vw;
    margin-left: -20vw;
  }

  .visit-modal-second.visit-modal::before {
    right: 26%;
  }

  .visit-modal-main-text-arrow-down {
    left: 35vw;
  }

  .visit-modal-third {
    right: 4vw;
    margin-left: 0;
  }

  .visit-modal-third-box h3 {
    font-size: 12px;
  }

  .visit-modal-third-box h6 {
    font-size: 2.2vw;
    text-align: left;
  }

  .visit-modal-third-box p {
    font-size: 1.6vw;
    margin: 10px 0 0;
  }

  .visit-modal-third-box {
    width: 88vw;
    padding: 15px 25px;
  }

  .shadow-edit {
    top: calc(70vw + 10px);
  }


  .visit-modal-second {
    bottom: calc(100% + 13px);
  }

  .visit-modal-second-box>p {
    font-size: 20px;
    white-space: nowrap;
  }

  .visit-modal-second {
    width: 50vw;
    margin-left: -26vw;
  }

  .visit-modal-second.visit-modal::before {
    right: 22%;
  }

  .visit-modal-main-second {
    padding: 5px 15px 5px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {

  /*.visit-modal-header {*/
  /*  font-size: 2.32vw;*/
  /*}*/
  .visit-modal-btn {
    font-size: 2vw;
    width: 95px;
    height: 28px;
  }

  .visit-modal-second {
    width: 50vw;
    margin-left: -34vw;
  }

  .visit-modal-second.visit-modal::before {
    right: 16%;
  }

  .visit-modal-second-box>p {
    font-size: 18px;
  }
}

/*@media only screen and (max-width: 651px) {*/
/*  .visit-modal-third-box {*/
/*    margin-top: 35px;*/
/*  }*/

/*  .visit-modal-third {*/
/*    bottom: calc(100% + -24px);*/
/*  }*/
/*}*/
@media only screen and (max-width: 520px) {
  .shadow-third {
    left: 0;
    right: 0;
    margin: auto;
    top: calc(59vw + 47px);
  }

  .visit-modal {
    width: 100%;
    min-width: auto;
    max-width: calc(100vw - 30px);
  }

  .visit-modal-header {
    font-size: 16px;
  }

  .visit-modal-main-text {
    font-size: 14px;
  }

  .visit-modal-second {
    width: 100%;
    max-width: calc(100% - 30px);
    margin-left: 0;
    bottom: calc(100% + 5px);
  }

  .visit-modal-second.visit-modal::before {
    right: 18%;
  }

  .shadow-edit {
    top: 101vw;
    right: 0;
    left: 0;
  }

  .visit-modal-main-text-arrow-down-second {
    left: 40.5vw;
  }

  .visit-modal-btn {
    white-space: nowrap;
    font-size: 14px;
    /*width: 75px;*/
  }

  .visit-modal-second-box-second>img {
    width: 16px;
  }

  .visit-modal-second-box>p {
    font-size: 16px;
  }

  .visit-modal-second-box {
    margin: 0;
    height: 70px;
    width: 190px;
  }

  .visit-modal-second-modal {
    font-size: 2.5vw;
    width: 85px;
  }

  .visit-modal-second.visit-modal::before {
    right: 0;
    left: 0;
    margin: auto;
  }

  .visit-modal-second-header {
    font-size: 16px;
  }

  .visit-modal-second p {
    font-size: 14px;
  }

  .visit-modal-third {
    right: 0;
    left: 0;
    margin: auto;
  }

  .visit-modal-third-box {
    padding: 10px;
  }

  .visit-modal-third-box h3 {
    font-size: 12px;
  }

  .visit-modal-third-box h6 {
    font-size: 16px;
  }

  .visit-modal-third-box p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .visit-modal-second-box {
    height: 60px;
    width: 160px;
    right: -12vw;
  }

  .visit-modal-main-text-arrow-down-second {
    left: 37vw;
    top: 72px;
  }

  .visit-modal-cont-bottom-text {
    font-size: 14px;
    margin: 0;
  }

  .visit-modal-third-box-recommended-by {
    color: #464646;
    font-size: 3vw;
  }

  .visit-modal-third-header {
    font-size: 2.7vw;
  }
}

@media only screen and (max-width: 391px) {
  .shadow-third {
    top: calc(64vw + 25px);
  }

  .visit-modal-third-header {
    font-size: 14px;
  }

  .visit-modal-main-text-third {
    padding: 9px 18px;
  }

  .visit-modal-third-box-recommended-by {
    font-size: 3.5vw;
  }


  .visit-modal-main-text-arrow-down {
    top: 88px;
  }

  .visit-modal-main-text {
    font-size: 12px;
    font-weight: bold;
  }

  .search-college-cont-text {
    font-size: 3.5vw;
  }

  /*.search-college > .search-college-input::placeholder {*/
  /*  font-size: 3.4vw;*/
  /*}*/

  /*.search-college > .search-college-img {*/
  /*  left: 3vw;*/
  /*  width: 4vw;*/
  /*}*/

  .visit-modal-main-text-arrow-down-second {
    top: 72px;
    left: 42vw;
  }

  .visit-modal-second-box {
    top: 102px;
    right: -13.5vw;
  }

  .visit-modal-third-box {
    top: 115px;
    padding: 10px;
  }

  .visit-modal-main-text-arrow-down {
    /*top: 108px;*/
    left: 39vw;
  }

  .visit-modal-header {
    font-size: 14px;
  }

  /*.visit-modal-btn {*/
  /*  width: 65px;*/
  /*}*/

  .visit-modal .visit-modal-cont-bottom {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .visit-modal .visit-modal-cont-bottom-two {
    display: flex;
    align-items: center;
  }

  /*.visit-modal-btn {*/
  /*  height: 21px;*/
  /*}*/

  .visit-modal-cont-bottom-text {
    font-size: 12px;
    margin: 0;
  }
}

@media only scree and (max-width: 350px) {}