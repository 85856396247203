.modal-unsubscribe {
  background-color: white;
  border-radius: 20px;
  width: 50vw;
  max-width: 570px;
}

.modal-unsubscribe>h1 {
  font-weight: 600;
  font-size: clamp(1rem, 1.2vw + 0.1rem, 1.7rem);
  line-height: 28px;
  color: var(--headings-blue);
  margin: 0;
  padding: 3% 10% 0 5%;
}

.modal-unsubscribe>p {
  font-weight: 400;
  font-size: clamp(0.9rem, 1vw + 0.1rem, 1.5rem);
  line-height: 24px;
  margin-bottom: 38px;
  padding-left: 5%;
  margin-top: 8px;
}

.modal-unsubscribe>div {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e6ed;
  padding: 2% 5% 3%;
}

.modal-unsubscribe>img {
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
}

.cancel-button {
  padding: 8px 16px;
  width: 100px;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #cbd0e1;
  border-radius: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
}

.unsubscribe-modal-button {
  padding: 8px 16px;
  width: 100px;
  font-weight: 600;
  background: var(--color-primary);
  border: none;
  border-radius: 20px;
  color: white;
  white-space: nowrap;
  margin-left: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
}

.modal-unsubscribe>.unsubscribe-body {
  display: flex;
  flex-direction: column;
  border: none;
}

.unsubscribe-body>div {
  display: flex;
}

.unsubscribe-body>div>h1 {
  font-size: clamp(0.9rem, 1vw + 0.1rem, 1.5rem);
}

.unsubscribe-body>div>img {
  margin-right: 10px;
}

@media only screen and (max-width: 520px) {
  .modal-unsubscribe {
    width: 85vw;
  }

  .modal-unsubscribe>p {
    margin-bottom: 10px;
  }

  .unsubscribe-body>div>img {
    width: 18px;
  }
}