.modal-scholarship {
  background-color: white;
  border-radius: 20px;
  width: 50vw;
  max-width: 570px;
}
.modal-scholarship > h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--headings-blue);
  margin: 0;
  padding: 3% 0 0 5%;
  width: 80%;
}
.modal-scholarship > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 5%;
  margin-top: 8px;
}
.modal-scholarship > div {
  display: flex;
  justify-content: flex-end;
  padding: 2% 5% 3%;
}
.modal-scholarship > img {
  position: absolute;
  right: 5%;
  top: 13%;
  cursor: pointer;
}

.modal-scholarship .cancel-button {
  padding: 8px 16px;
  color: white;
  width: 70px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  background: #7e106e;
  border: none;
  border-radius: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
  font-size: 14px;
}
@media only screen and (max-width: 520px) {
  .modal-scholarship {
    width: 85vw;
  }
}
