.container {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans 3', sans-serif;
    background-color: var(--light-grey-smoke);
}

.container * {
    box-sizing: border-box;
}

.filtersBtn {
    position: absolute;
    left: 0;
    top: -3.5rem;
    color: var(--color-primary);
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 2px;
    cursor: pointer;
    background: var(--light-grey-smoke);
}

.filtersBtnIcon {
    width: 2rem;
    height: 2rem;
    background: url("../../images/arrowDownPink.svg");
    background-size: cover;
}

.filtersBtnIcon.active {
    transform: rotateX(180deg);
}

.resultsWithFiltersBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.5rem 2.5rem;
    gap: 1.5rem;
    background: var(--light-grey-smoke);
}

.resultsWithFiltersContainer {
    position: relative;
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 2.5rem;
    max-width: 85rem;
    width: -webkit-fill-available;
}

.noResultsContainer {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.noResultsContainer__Title {
    text-align: center;
    line-height: initial;
    margin: 0;
    color: var(--headings-blue);
    font-size: 2rem;
    font-weight: 600;
}

.noResultsContainer__Image {
    width: 80%;
    margin-top: 1.5rem;
    max-width: 25rem;
}

.upgradeAccount__Image {
    width: 80%;
    margin-top: 5rem;
    max-width: 35rem;
}

.loader {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    margin: 10rem;
}

.upgradeBtn {
    background: var(--color-primary);
    border: none;
    border-radius: 40px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1rem;
    margin: 2rem 0;
    outline: none;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
}


@media only screen and (max-width: 900px) {
    .container.filtersOpen {
        position: relative;
        overflow-y: hidden;
    }

    .container.filtersOpen:before {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        background-color: #000000B2;
        border-radius: inherit;
        z-index: 11;
    }

    .noResultsContainer__Title {
        font-size: 1.5rem;
    }

    .noResultsContainer__Image {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {

    .resultsWithFiltersContainer,
    .resultsWithFiltersBlock {
        padding: 0;
    }
}