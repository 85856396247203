
/* KLARNA */
:root {
    --light-grey: #F6F9FC;
    --dark-terminal-color: #0A2540;
    --accent-color: #635BFF;
    --radius: 3px;
}

#payment-form {
    border: #F6F9FC solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    display: none; /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size:0.7em;
    overflow: scroll;
}

.payment-form-klarna {
    margin-left: 1.75rem;
}
.payment-form-klarna > * {
    margin: 10px 0;
}
.payment-form-klarna > label {
    font-weight: 600;
    font-size: 18px;
    color: var(--headings-blue);
}

.payment-form-klarna-button {
    background-color: var(--color-primary);
    border-radius: 40px;
    color: white;
    border: 0;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    padding: 1vw 0;
    font-size: 1.1vw;
    width: 12vw;
    height: 50px;
    min-width: 140px;
}
.payment-form-klarna-button:hover {
    filter: contrast(115%);
}
.payment-form-klarna-button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
}
.payment-form-klarna-button:disabled {
    opacity: 0.5;
    cursor: none;
}

.payment-form-klarna-input {
    display: block;
    font-size: 16px;
    width: 100%;
    margin: 25px 0 10px;
    background-color: rgba(239, 239, 239, 0.3);
    border: 1px solid var(--border-color);
    border-radius: 0.7vw;
    padding: 1.3vw 1.2vw;
}

.payment-form-klarna-label {
    display: block;
}

#loading-klarna-payment {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}


@media only screen and (max-width: 520px) {
    .payment-form-klarna-input {
        padding: 20px 18px;
        border-radius: 10px;
    }
    .buy-plan-mobile-button {
        text-align: center;
        text-decoration: none;
    }
}


@media only screen and (max-width: 391px) {
    .payment-form-klarna-button {
        height: 45px;
        min-width: 140px;
    }
}
