.college-details-section {
  background: #ffffff;
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1%;
  margin: 5% 0;
}
.colleges-details-wrapper-container-under {
  padding: 3% 3%;
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.colleges-details-wrapper {
  padding: 0;
}
.section-hr-line {
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
}
.college-details-section > h6 {
  margin: 1% 0 3% 3%;
  font-size: 2vw;
}
.college-details-section > div {
  padding: 2% 5% 4% 5%;
}
.college-details-section > div > p {
  display: flex;
  align-items: center;
  color: var(--primary-typography);
  font-size: 1.2vw;
}

.price-calculation {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-calculation > a > button {
  padding: 1vw 3.7vw;
  white-space: nowrap;
  font-size: 1.2vw;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: white;
  border-radius: 50px;
  font-weight: 600;
}

.race-info-block.costs {
  display: block;
}

.unlocked-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.unlocked-field > span {
  color: #464646;
  font-weight: 600;
  white-space: nowrap;
}

.disable {
  display: none;
}
@media only screen and (min-width: 2000px) {
  .price-calculation > a > button {
    border: 2px solid var(--color-primary);
  }

  .college-details-section-locked-schoolarships > .price-calculation-locked > a > button {
    border: 2px solid var(--primary-typography);
  }
}
@media only screen and (max-width: 900px) {
  .price-calculation > a > button {
    font-size: 1.3vw;
  }
}
@media only screen and (max-width: 520px) {
  .price-calculation {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-calculation > a > .price-calculation-finance-btn {
    padding: 12px;
    white-space: nowrap;
    width: 100%;
    font-size: 4vw;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: white;
    border-radius: 50px;
    font-weight: 600;
  }

  .race-info-block .race-res-block-financial {
    gap: 8px;
  }

  .colleges-details-wrapper-container-under {
    padding: 0;
    background-color: #fff;
  }

  .unlocked-field > span {
    font-size: 3.6vw;
    padding-right: 0;
  }
}
