.container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 2.5rem 2.75rem;
    background: var(--white-color);
    border-radius: 12px;
    width: -webkit-fill-available;
    max-width: 85rem;
    position: relative;
}

.title,
.title__Amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    font-family: 'Source Sans 3', sans-serif;
}

.title {
    justify-content: space-between;
    z-index: 2;
}
.title__Amount {
    gap: 0.5rem;
}
.title__AmountText {
    color: var(--color-primary);
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}
.plusIcon {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: relative;
    background-image: url("../../../../images/plusIconNotTransparent.svg");
    background-size: cover;
    transition: transform 0.2s ease, background-color 0.2s ease;
    z-index: 2;
}
.plusIcon.selected {
    background-image: url("../../../../images/minusForForm.svg");
}
.plusIcon:active {
    transform: scale(0.95);
}

.sortingMethodAutocomplete {
    top: 3rem;
}

.autocomplete {
    max-height: 20rem;
    padding: .5rem 0;
    width: 15rem;
    left: 90%;
    top: 1rem;
    overflow-y: scroll;
}
.autocomplete:active {
    z-index: 100;
    width: 15rem;
    left: 90%;
    top: 1rem;
}
.autocomplete > div {
    gap: .5rem;
    padding-right: 1rem;
    padding-left: 1rem;
}
.autocomplete > div,
.sortingMethodAutocomplete > div {
    width: 100%;
}
.autocomplete > div > img,
.sortingMethodAutocomplete > div > img {
    position: static;
}
.autocomplete > div:hover,
.sortingMethodAutocomplete > div:hover {
    background: rgba(126, 16, 110, 0.12);
}

.sortSelectContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
    width: 40%;
    z-index: 1;
}

.sortSelectWrapper {
    width: 100%;
    z-index: 1;
}

.sortDirectionButton {
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
}

.lockedContent__Container {
    position: relative;
    display: flex;
    justify-content: center;
}

.lockedContent {
    filter: blur(5px);
    pointer-events: none;
}

.hide {
    display: none;
}


@media only screen and (max-width: 900px) {
    .sortSelectContainer {
        width: 50%;
    }
}

@media only screen and (max-width: 520px) {
    .container {
        gap: 1.5rem;
        padding: 1.5rem 1.25rem;
        border-radius: 0;
    }

    .title__Amount {
        gap: 0.25rem;
    }

    .title__AmountText {
        font-size: 1rem;
    }

    .plusIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .sortSelectContainer {
        width: 50%;
    }

    .autocomplete {
        left: 0;
    }
}