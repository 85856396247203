.save-list-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding: 40px 92px;
    width: 600px;
    height: 235px;
    position: relative;
    box-sizing: border-box;

    border-radius: 24px;
    background: var(--white-color);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
}

.save-list-modal__cross {
    position: absolute;
    top: 28px;
    right: 28px;

    display: block;
    width: 12px;
    height: 12px;

    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.save-list-modal_text {
    color: var(--headings-blue);
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.save-list-modal__cont-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.save-list-modal__btn {
    color: var(--white-color);
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    border: 0;
    border-radius: 32px;
    background: var(--color-primary);
    width: 180px;
    height: 48px;
    cursor: pointer;
}

.save-list-modal__btn.no {
    border: 1px solid #a7a7a7;
    color: var(--headings-blue);
    background: transparent;
}

@media only screen and (max-width: 610px) {
    .save-list-modal {
        width: 85%;
        margin: 0 auto;
        padding: 20px 30px;
        gap: 30px;
        height: 200px;
    }

    .save-list-modal__btn,
    .save-list-modal_text {
        font-size: 20px;
    }

    .save-list-modal__btn {
        width: 140px;
    }
}

@media only screen and (max-width: 410px) {
    .save-list-modal {
        padding: 15px 25px;
    }

    .save-list-modal__btn,
    .save-list-modal_text {
        font-size: 18px;
    }

    .save-list-modal__btn {
        width: 120px;
    }
}
