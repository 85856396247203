.college-details-section {
  background: #ffffff;
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1%;
  margin: 5% 0;
}
.unlocked-filed {
  display: flex;
  flex-wrap: wrap;
  padding-right: 1%;
  padding-bottom: 1%;
  height: 9vw;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.majors-header > span {
  align-items: center;
}
.unlocked-filed::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.unlocked-filed::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.unlocked-filed::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}
.unlocked-filed > div {
  /*background: rgba(3, 0, 84, 0.05);*/
  /*border: 1px solid #030054;*/
  /*border-radius: 4px;*/
  color: #464646;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /*margin: 1% 1% 1% 0;*/
  padding: 5px 10px;
  text-align: center;
  height: fit-content;
}
.majors-header {
  margin: 0 0 5% 0;
  font-size: 2vw;
  border: 1px solid #c2c2c2;
}
.majors-header > span {
  white-space: break-spaces;
  cursor: default;
}
.colleges-details-wrapper-container-under {
  padding: 3% 3%;
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.colleges-details-wrapper {
  padding: 0;
}
.section-hr-line {
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
}
.college-details-section > h6 {
  margin: 1% 0 3% 3%;
  font-size: 2vw;
  font-weight: bold;
}
.race-res-block > span,
.undergraduates-res-block > span {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--primary-typography);
  cursor: default;
  white-space: nowrap;
}
.undergraduates-res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.college-details-section > div {
  padding: 2% 0 4% 5%;
}
.college-details-section > div > p {
  display: flex;
  align-items: center;
  color: var(--light-grey);
  font-size: 1.2vw;
}
.college-details-section > div > p > img {
  padding-right: 10px;
  height: 2vw;
}
.college-details-section > div > .unlocked-field {
  color: var(--primary-typography);
}

.college-details-section .college-details-section-locked {
  align-content: start;
  position: relative;
}
.college-details-section-locked .race-res-block > span {
  color: #464646;
}
.college-details-section-locked > div > p {
  margin-top: 1%;
  font-size: 1.2vw;
}
.college-details-section .college-details-section-locked .right-main-block-res {
  margin-right: 30%;
}
.college-details-section .college-details-section-locked .right-main-block-res > span {
  margin-top: 4%;
}
.race-info-block {
  display: flex;
  justify-content: space-between;
}
.race-info-block .race-res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.race-res-block > span {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--headings-blue);
}
.race-res-block > span,
.undergraduates-res-block > span {
  width: 100%;
  text-align: right;
}
.race-info-block > div > h4 {
  margin: 0;
  line-height: 3vw;
  font-weight: 600;
  font-size: 1.5vw;
}
.upgrade-profile-message {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  padding: 2% 5%;
  position: absolute;
  top: 4%;
  left: 29%;
}
.upgrade-profile-message > img {
  width: 3.3vw;
}
.race-res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.race-res-block > span {
  color: #464646;
  white-space: nowrap;
}
.upgrade-profile-message > p {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--headings-blue);
  padding: 3%;
  margin: 0;
}
.upgrade-profile-message > span {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 1vw;
  color: var(--headings-blue);
  margin: 0;
}
.upgrade-profile-message > a {
  padding: 3% 0;
  white-space: nowrap;
  width: 13.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 40px;
  font-weight: 600;
  margin: 5%;
  text-decoration: none;
  text-align: center;
}
.college-details-section > div .unlocked-field {
  font-size: 1.2vw;
  margin-top: 1%;
}
.majors-header {
  margin: 0 0 5% 0;
  font-size: 2vw;
}
.college-details-section-locked {
  background-color: darkgray;
  display: flex;
  justify-content: space-between;
}
.locked_bgc {
  width: 100%;
  height: 100%;
  background-color: darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}

.unlocked-field-major {
    height: 80px;
}

.unlocked-field-major > div {
  padding: 12px 6px;
  font-size: 1.3vw;
}
.majors-header-title {
  padding-right: 2px;
}

.college-details-section-link-faqs {
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5%;
}

.college-details-section-link-faqs > a {
  font-size: 14px;
  font-weight: 600;
  color: #464646;
}
@media only screen and (max-width: 1100px) {
  .unlocked-field-major > div {
    padding: 10px 6px;
    font-size: 1.35vw;
  }
}
@media only screen and (max-width: 950px) {
  .unlocked-field-major > div {
    font-size: 1.6vw;
  }

  .college-details-section-link-faqs,
  .college-details-section-link-faqs > a {
    font-size: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .college-details-section > div .unlocked-field,
  .race-res-block > span {
    font-size: 12px;
  }
  .majors-header > span,
  .undergraduates-res-block > span {
    font-size: 12px;
  }
  .unlocked-filed > div {
    font-size: 10px;
  }

  .college-details-section-locked > div > p {
    margin-top: 1%;
    font-size: 1.2vw;
  }

  .unlocked-field-major > div {
    font-size: 1.6vw;
  }
}
@media only screen and (max-width: 750px) {
  .colleges-details-wrapper-container-under > .colleges-details-wrapper > .majors-header,
  .unlocked-field-major {
    height: 65px;
  }

  .majors-header {
    padding: 5px 30px;
  }

  .college-details-section-link-faqs,
  .college-details-section-link-faqs > a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 650px) {
  .colleges-details-wrapper-container-under > .colleges-details-wrapper > .majors-header,
  .unlocked-field-major {
    height: 70px;
  }
  .unlocked-field-major > div {
    padding: 8px 6px;
    font-size: 1.35vw;
  }

  .college-details-section-link-faqs,
  .college-details-section-link-faqs > a {
    font-size: 10px;
  }
}

@media only screen and (max-width: 520px) {
  .majors-header-title {
    font-size: 2.1vw;
  }
  .upgrade-profile-message {
    background-color: darkgrey;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    width: 96%;
    left: 0;
    top: 63%;
    border-radius: 0;
  }
  .upgrade-profile-message > img {
    width: 7.5vw;
  }
  .upgrade-profile-message > p {
    font-weight: 600;
    font-size: 4.6vw;
    color: var(--headings-blue);
    padding: 3%;
    margin: 0;
  }
  .upgrade-profile-message > span {
    position: relative;
    padding: 0;
    font-weight: 600;
    text-align: center;
    font-size: 3.6vw;
    color: white;
    white-space: normal;
    margin: 0;
    width: 70%;
  }
  .upgrade-profile-message > a {
    padding: 5% 0;
    white-space: nowrap;
    font-size: 4.6vw;
    cursor: pointer;
    border: none;
    background: white;
    color: var(--color-primary);
    border-radius: 40px;
    font-weight: 600;
    margin: 3%;
    width: 71%;
  }
  .college-details-section .college-details-section-locked {
    background-color: white;
    padding: 3%;
    padding-bottom: 10%;
    position: unset;
  }
  .majors-header .majors-header-title {
    font-size: 3.5vw;
  }
  .unlocked-filed > div {
    font-size: 3.6vw;
    padding: 5px 10px;
    border: 2px solid #030054;
  }
  .unlocked-filed {
    height: 13vh;
  }

  .college-details-section .college-details-section-locked > div > p {
    font-size: 3.6vw;
    color: #464646;
    font-weight: 600;
    margin-top: 5%;
  }
  .college-details-section .college-details-section-locked .upgrade-profile-message > p {
    font-weight: 600;
    font-size: 4.6vw;
    color: white;
    padding: 3%;
    margin: 0;
  }
  .college-details-section > div > div .unlocked-field {
    font-size: 3.6vw;
    color: #464646;
    font-weight: 400;
    margin: 0;
  }
  .college-details-section .college-details-section-locked > p {
    font-size: 3.4vw;
    font-weight: 600;
  }
  .college-details-section > h6 {
    font-size: 3.6vw;
  }

  .race-info-block .race-res-block {
    margin-right: 0;
  }
  .race-info-block .race-res-block > span {
    font-size: 3.6vw;
    color: #464646;
    font-weight: 600;
    width: auto;
    padding-right: 0;
    white-space: nowrap;
  }
  .race-info-block > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    gap: 8px;
  }
  .undergraduates-res-block > span {
    font-size: 3.4vw;
    color: #464646;
    font-weight: 600;
    line-height: normal;
    width: auto;
    padding-right: 0;
  }
  .undergraduates-res-block {
    align-items: flex-end;
    margin-right: 0;
    width: 40%;
    gap: 8px;
  }

  .race-info-block .race-res-block {
    gap: 11px;
  }

  .colleges-details-wrapper-container-under > .colleges-details-wrapper > .majors-header, .unlocked-field-major {
    height: 100px;
  }

  .unlocked-field-major > div {
    border: none;
    font-size: 2.5vw;
    text-align: start;
  }

  .majors-header > span {
    height: 100px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .colleges-details-wrapper-container-under {
    padding: 0;
    background-color: #fff;
  }

  .college-details-section-link-faqs,
  .college-details-section-link-faqs > a {
    font-size: 2.3vw;
  }
}
