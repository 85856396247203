@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Nunito+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Source+Sans+3:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600;700;900&family=Source+Serif+4:opsz,wght@8..60,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


html {
  --color-primary: #7e106e;
  --headings-blue: #030054;
  --primary-typography: #464646;
  --dark-typography: #1d1b20;
  --light-grey-smoke: #f1f1f1;
  --light-grey: #c2c2c2;
  --dark-grey: #a7a7a7;
  --light-purple: #faf6f6;
  --family-color: #eacb36;
  --student-color: #d34b38;
  --parent-color: #5e8057;
  --border-color: #d9d9d9;
  --light-blue: #00b4d8;
  --admin-icons: #00b4d8;
  --admin-main: #030654;
  --admin-hover: #4dc0b5;
  --white-color: #ffffff;
  --black-color: #000000;
}

/**,*/
/**::before,*/
/**::after {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  box-sizing: border-box;*/
/*}*/
/*.container {*/
/*  max-width: 1440px;*/
/*}*/
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: var(--primary-typography);
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

.body-hidden {
  overflow: hidden;
}

code {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-weight: 600;
  font-size: 64px;
  color: var(--headings-blue);
}

h2 {
  font-weight: 600;
  font-size: 40px;
  color: var(--headings-blue);
}

h3 {
  font-weight: 600;
  font-size: 32px;
  color: var(--headings-blue);
}

h4 {
  font-weight: 500;
  font-size: 24px;
  color: var(--headings-blue);
}

h5 {
  font-weight: 600;
  font-size: 20px;
  color: var(--headings-blue);
}

h6 {
  font-weight: 600;
  font-size: 32px;
  color: var(--headings-blue);
}

p.subtitle1 {
  font-weight: 500;
  font-size: 18px;
}

p.subtitle2 {
  font-weight: 600;
  font-size: 14px;
}

p.subtitle3 {
  font-weight: 400;
  font-size: 18px;
}

p.subtitle4 {
  font-weight: 600;
  font-size: 16px;
}

p1 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

p2 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

p3 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

caption {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--headings-blue);
}

.primary-button {
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 40px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 26px;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (min-width: 2000px) {
  .primary-button {
    border-radius: 80px;
  }
}