.download-list-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20.5px 37px 27px;

    position: relative;
    width: 850px;
    height: 635px;

    background-color: #ffffff;

    border-radius: 20px;
    box-sizing: border-box;
}

.download-list-modal__close {
    position: absolute;
    top: 27px;
    right: 47px;

    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.download-list-modal__choose-info {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #030054;
    margin: 0;
    padding: 0;
}

.download-list-modal__limit-text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #403E59;
    margin: 0 0 24px;
    padding: 0;
}

.download-list-modal__limit-text-20-schools {
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
}

.download-list-modal-select-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: start;
}

.download-list-modal-select-list-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #464646;
    margin-bottom: 8px;
}

.download-list-modal-select-list-container-main-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.download-list-modal-select-list-autocomplete {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    max-height: 200px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    top: 55px;
    left: 0;
    border-radius: 8px;
    border: 1px solid #a7a7a7;
    box-sizing: border-box;
    box-shadow: 0 0 13.037px rgba(0, 0, 0, 0.15);
    z-index: 2;
}

.download-list-modal-select-list-autocomplete::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.download-list-modal-select-list-autocomplete::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}
.download-list-modal-select-list-autocomplete::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 10px;
    cursor: pointer;
}

.download-list-modal-select-list-autocomplete-item {
    text-align: center;
    padding: 15px 0;
    margin: 0;
    width: 100%;
    height: 15px;
    cursor: pointer;
}

.download-list-modal-select-list-autocomplete-item:hover {
    background-color: #e1ebf4;
}

.download-list-modal-select-list-input {
    padding: 13px 20px;
    width: 387px;
    height: 47px;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    box-sizing: border-box;

    position: relative;
}

.download-list-modal-select-list-input,
.download-list-modal-select-list-input::placeholder {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #464646;
}

.download-list-modal-select-list-input-arrow {
    width: 10px;
    height: 10px;

    position: absolute;
    right: 21px;
}

.download-list-modal-select-list-input-arrow-opened {
    transform: rotate(180deg);
}
.download-list-modal__btn,
.download-list-modal-select-list-btn {
    width: 81px;
    /*height: 47px;*/
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: var(--color-primary);
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    margin-left: 4px;
    cursor: pointer;
    padding: 11px 8px;
    max-height: 45px;
}

.download-list-modal__btn--not-allowed {
    cursor: not-allowed;
}

.download-list-modal__btn {
    border-radius: 20px;
    align-self: end;
    width: 110px;
}

.download-list-modal__main-content {
    display: flex;
    flex-direction: row;

    width: 100%;
    border: 1px solid #c2c2c2;
    border-radius: 8px;

    min-height: 360px;
    box-sizing: border-box;
    margin-bottom: 21px;
}

.download-list-modal__main-content--second {
    min-height: 460px;
}

.download-list-modal__main-content__left-side,
.download-list-modal__main-content__right-side {
    width: 50%;
    box-sizing: border-box;
    padding: 19px 25px 11px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;overflow-y: scroll;
}

.download-list-modal__main-content__left-side {
    border-right: 1px solid #c2c2c2;
}

.download-list-modal__main-content__left-side__first-level-title,
.download-list-modal__main-content__right-side-title-column,
.download-list-modal__main-content__left-side-title-column {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
    margin: 0 0 8px;
    padding: 0;
}

.download-list-modal__main-content__right-side-list,
.download-list-modal__main-content__left-side-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*gap: 12px;*/
    align-items: flex-start;
    overflow-y: scroll;
    height: 260px;
    border-top: 1px solid #f1f1f1;
    overflow-x: hidden;
}


.download-list-modal__main-content__right-side-list--second,
.download-list-modal__main-content__left-side-list-second {
    height: 350px;
}

.download-list-modal__main-content__left-side-item,
.download-list-modal__main-content__right-side-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 6px 0;
    margin: 0;
    position: relative;
}

.download-list-modal__main-content__left-side-item {
    padding: 6px;
}

.download-list-modal__main-content__right-side-item {
    justify-content: flex-start;
    position: relative;
}
.download-list-modal__main-content__left-side-item:hover,
.download-list-modal__main-content__right-side-item:hover {
    background-color: #f1f1f1;
}

.download-list-modal__main-content__left-side-item-not-allowed:hover {
    background-color: rgba(211, 56, 65, 0.1);
}

.download-list-modal__main-content__right-side-item__name,
.download-list-modal__main-content__left-side-item-name {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #a6a6a6;
    margin: 0;
    padding: 0;
}

.download-list-modal__main-content__right-side-item__name-added,
.download-list-modal__main-content__left-side-item-name-added {
    color: #464646;
}


.download-list-modal__main-content__left-side-item__remove,
.download-list-modal__main-content__left-side-item__add {
    opacity: 0;
    margin: 0;
    padding: 0;
    transition: opacity 0.5s;
    position: relative;
}

.download-list-modal__main-content__left-side-item__remove__text,
.download-list-modal__main-content__left-side-item__add__text {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4ba62f;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.download-list-modal__main-content__left-side-item__remove__text {
    color: var(--student-color);
    white-space: nowrap;
}

.download-list-modal__main-content__left-side-item:hover > .download-list-modal__main-content__left-side-item__remove,
.download-list-modal__main-content__left-side-item:hover > .download-list-modal__main-content__left-side-item__add {
    opacity: 1;
}

.download-list-modal__main-content__left-side-item-selected,
.download-list-modal__main-content__right-side-item-selected {
    color: #464646;
}

.download-list-modal__main-content__right-side__info-selected {
    display: block;
    align-self: end;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #8da1ac;
    margin: 0;
}

.download-list-modal__main-content__right-side__info-selected--accept {
    color: #197c05;
    margin: 0;
    padding-left: 5px;
    white-space: nowrap;
}

.download-list-modal__main-content__right-side__info-selected--warning {
    color: #d33841;
    padding-left: 5px;
    margin: 0;
    white-space: nowrap;
}

.download-list-modal__main-content__right-side-item__minus {
    margin-right: 12px;
}

.download-list-modal__main-content__right-side-item::before {
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "Remove";
    top: -40px;
    z-index: 2;
    width: 80px;
    height: 32px;
    background: #403e59;
    border-radius: 4px;
    transition: 0.6s ease-in-out all;

    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
}

.download-list-modal__main-content__right-side-item:nth-child(2)::before,
.download-list-modal__main-content__right-side-item:nth-child(3)::before,
.download-list-modal__main-content__right-side-item:first-child::before {
    top: 30px;
}

.download-list-modal__main-content__right-side-item:hover::before {
    opacity: 1;
    visibility: visible;
}

.download-list-modal__main-content__right-side-item::after {
    content: "";
    position: absolute;
    width: 0;
    z-index: 1;
    height: 0;
    top: -13px;
    left: 4px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    transition: 0.8s ease-in-out all;

    border-top: 12px solid #403e59;
    opacity: 0;
    visibility: hidden;
}


.download-list-modal__main-content__right-side-item:nth-child(2)::after,
.download-list-modal__main-content__right-side-item:nth-child(3)::after,
.download-list-modal__main-content__right-side-item:first-child::after {
    top: 23px;
    transform: rotate(180deg);
}

.download-list-modal__main-content__right-side-item:hover::after {
    opacity: 1;
    visibility: visible;
}

.download-list-modal__bookmark {
    font-size: 14px;
    background-color: #d33841;
    color: #ffffff;
    position: absolute;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    z-index: 100;
    bottom: calc(100% + 14px);
    transition: 0.8s ease-in-out all;
    right: 0;
}

.download-list-modal__bookmark.hidden {
    opacity: 0;
    visibility: hidden;
}

.download-list-modal__bookmark--bottom.hidden {
    opacity: 0;
    visibility: hidden;
}

.download-list-modal__bookmark::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #d33841;
    transform: rotate(45deg);
    position: absolute;
    bottom: -6px;
    left: -10px;
    right: -125px;
    margin: auto;
}

.download-list-modal__bookmark--bottom {
    bottom: -33px;
}

.download-list-modal__bookmark--bottom::before {
    top: -6px;
    bottom: unset;
}

/*SECOND STEP*/
.download-list-modal__main-content__left-side__first-level {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px;
}

.download-list-modal__main-content__left-side__first-level:last-child {
    border-bottom: unset;
}

.download-list-modal__main-content__left-side__second-level-up,
.download-list-modal__main-content__left-side__first-level-up {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.download-list-modal__main-content__left-side__second-level-up > span,
.download-list-modal__main-content__left-side__first-level-up > span {
    position: absolute;
    width: 16px;
    height: 16px;
    transition: 0.3s ease-in-out all;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.download-list-modal__main-content__left-side__second-level.active > .download-list-modal__main-content__left-side__second-level-up > span::after,
.download-list-modal__main-content__left-side__first-level.active > .download-list-modal__main-content__left-side__first-level-up > span::after {
    transform: none;
}

.download-list-modal__main-content__left-side__second-level-up > span::before,
.download-list-modal__main-content__left-side__second-level-up > span::after,
.download-list-modal__main-content__left-side__first-level-up > span::before,
.download-list-modal__main-content__left-side__first-level-up > span::after {
    transition: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    border-radius: 2px;
}

.download-list-modal__main-content__left-side__second-level-up > span::after,
.download-list-modal__main-content__left-side__first-level-up > span::after {
    transform: rotate(90deg);
}

.download-list-modal__main-content__left-side__second-level-up > span::before,
.download-list-modal__main-content__left-side__second-level-up > span::after {
    background-color: #030054;
}

.download-list-modal__main-content__left-side__second-level {
    padding: 16px 15px;
    width: 85%;
}
.download-list-modal__main-content__left-side__second-level-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #030054;
    padding: 0;
    margin: 0;
}

.download-list-modal__main-content__left-side__third-level {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin: 0;
    position: relative;
}

.download-list-modal__main-content__left-side__third-level:hover {
    background-color: #f1f1f1;
}

.download-list-modal__main-content__left-side__third-level-not-allowed:hover {
    background-color: rgba(211, 56, 65, 0.1);
}


.download-list-modal__main-content__left-side__third-level:hover > .download-list-modal__main-content__left-side__third-level-remove {
    opacity: 1;
}

.download-list-modal__main-content__left-side__third-level-name {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #464646;
    padding: 0;
    margin: 0;
    max-width: 215px;
}

.download-list-modal__main-content__left-side__third-level-remove {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #030054;
    padding: 0;
    margin: 0;

    opacity: 0;
    transition: opacity 0.5s;
    position: relative;
}

.download-list-modal__main-content__left-side__third-level:hover > .download-list-modal__main-content__left-side-item__add,
.download-list-modal__main-content__left-side__third-level:hover > .download-list-modal__main-content__left-side-item__remove {
    opacity: 1;
}

.download-list-modal__cont-bottom-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.download-list-modal__btn--back {
    background-color: #fff;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    position: relative;
}

.download-list-modal__main-content__right-side-item-selected-colleges {
    color: #464646;
}

.download-list-modal__btn-clear {
    border: 0;
    background: #fff;
    color: var(--color-primary);
    margin: 0 10px 10px 0;
    border-bottom: 1px solid var(--color-primary);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    padding: 0;
}

.download-list-modal-cont-select-list-and-clear {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%
}

.download-list-modal__main-content__left-side-item__add,
.download-list-modal__main-content__left-side-item__remove {
    display: flex;
    justify-content: center;
    padding-right: 25px;
}

.download-list-modal__main-content__left-side-item__add.columns,
.download-list-modal__main-content__left-side-item__remove.columns {
    padding-right: 0;
}


@media only screen and (max-width: 768px) {
    .download-list-modal {
        width: 760px;
    }
}
