.stripe-form *,
.stripe-form *::after,
.stripe-form *::before {
  box-sizing: border-box;
}

.stripe-form {
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.75rem
}
.old-credit-mobile {
  display: none;
}
.stripe-form-type-payment-cont > h5 {
  margin: 0 0 10px;
  font-size: 1rem;
  display: flex;
  align-items: stretch;
}
.stripe-form-type-payment-cont > h5 > img {
  margin-right: 10px;
}
.stripe-form-type-payment-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  list-style: none;
  margin: 0 0 25px 1.75rem;
  padding: 0;
}
.stripe-form-type-payment-item {
  border: 1px solid #464646;
  border-radius: 10px;
  padding: 8px 20px;
  cursor: pointer;
 }
.stripe-form-type-payment-item-active {
  border-color: var(--color-primary);
}
.stripe-form-type-payment-item-disabled {
  cursor: not-allowed;
}
.stripe-form-type-payment-item-active > p {
  color: var(--color-primary);
}
.stripe-form-type-payment-item > img {
  width: auto;
  height: 20px;
  object-fit: contain;
}
.stripe-form-type-payment-item > img:last-child {
  height: 30px;
}
.stripe-form-type-payment-item > p {
  margin: 0;
  font-size: clamp(10px, 0.93vw + 1px, 15px);
}
.stripe-form-title {
  margin: 0 0 18px;
  font-size: 1rem;
  display: flex;
  align-items: stretch;
}
.stripe-form-title > img {
  margin-right: 10px;
}
.stripe-form-type-payment-item:last-child > img {
  width: 40px;
}
.stripe-form-wrapper {
  display: flex;
  height: -webkit-fill-available;
  flex-direction: column;
  align-items: center;
}
.stripe-form-wrapper > button {
  padding: 1.3vw 0;
  width: 20vw;
  min-width: 165px;
  margin: 30px 0 0;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Source Sans 3";
}
.stripe-form > label,
.stripe-form > div > label {
  font-size: 1rem;
  width: 100%;
  font-weight: 600;
  color: var(--headings-blue);
  padding: 0;
}
.stripe-form > span > span {
  margin: 7px !important;
  width: 0 !important;
  height: 15px !important;
}
.stripe-form-wrapper > .error {
  color: red;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}
.card-date-cvc,
.card-user-name{
  display: flex;
  justify-content: space-between;
  gap: 2.2vw;
  margin-top: 25px;
}

.card-user-name input {
  width: 100%;
}

.card-number {
  width: 75%;
  padding-top: 1.7vw;
}
.privacy-checkbox > img {
  align-self: baseline;
  margin-right: 10px;
}
.card-number-input,
.card-date-input,
.card-cvc-input,
.card-holder {
  border: 1px solid var(--border-color);
  padding: 1.3vw 1.2vw;
  border-radius: 0.7vw;
  margin: 0;
}

.card-holder {
  font-size: 16px;
  width: 100%;
  line-height: 120%;
}

.card-date-cvc > label {
  width: 45%;
}
.pay-loading {
  text-align: center;
  padding: 5% 0;
}
.discount-field {
  display: flex;
  flex-direction: column;
  margin-top: 3.2vw;
}
.discount-field > input {
  font-family: sans-serif;
  font-size: 16px;
  height: 1.2em;
  line-height: 1.2em;
}
.stripe-form > .privacy-checkbox {
  font-size: 1.1vw;
  margin-top: 35px;
  font-weight: 400;
  display: block;
}
.stripe-form-apple-btn {
  background: black;
  padding: 5px;
  border: 1px solid black;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  text-decoration: none;
}

.privacy-checkbox p {
  margin: 0;
  font-size: 1rem;
}

.privacy-checkbox > p > a {
  color: inherit;
}

.privacy-checkbox-cont {
  display: flex;
}

.stripe-form-wrapper input[type='radio'], .stripe-form-wrapper input[type='radio']:checked {
  width: 22px;
  height: 22px;
  flex: 0 0 22px;
  border-color: var(--color-primary);
}

.stripe-form-wrapper input[type='radio'], .stripe-form-wrapper input[type='radio']:checked::before {
  width: 12px;
  height: 12px;
}

.stripe-form-wrapper input[type='radio']:checked::before {
  background-color: var(--color-primary);
}

.stripe-form-wrapper input[type='radio'] {
  width: 22px;
  height: 22px;
  border-width: 2px;
}

input[type='checkbox'] {
  appearance: none;
  margin: 0;
  margin-right: 0.6vw;
  font: inherit;
  color: var(--light-grey);
  min-width: 20px;
  height: 20px;
  border: 1.5px solid var(--border-color);
  border-radius: 6px;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  cursor: pointer;
  margin-top: 2px;
}
input[type='checkbox']::before {
  content: '';
  width: 11px;
  height: 13px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-primary);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}
.card-radios {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.8vw;
}
.card-radios > div {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.card-radios > div > p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Source Sans 3';
  font-size: clamp(16px, 1.2vw + 1px, 26px);
}
.payment-loading > span {
  padding: 0;
}
.payment-loading {
  margin: 5vw 10%;
  padding: 3% 10%;
}
.stripe-form-upgrade-locked {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  background: var(--primary-typography);
}

.stripe-form-upgrade-locked-img {
  padding-right: 5px;
  width: 25px;
  height: 25px;
}
@media only screen and (max-width: 1024px) {
  .stripe-form-type-payment-cont > h5 {
    font-size: 16px;
  }

  .stripe-form-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .stripe-form > label,
  .stripe-form > div > label  {
    font-size: 13px;
  }
  .stripe-form-wrapper > button,
  .stripe-form .ElementsApp input {
    font-size: 2vw;
  }
  .stripe-form-wrapper input[type='radio']::before {
    width: 10px;
    height: 10px;
  }

  .stripe-form > label,
  .card-date-cvc > label:first-child,
  .stripe-form > div > label {
    font-size: 20px;
  }

  .stripe-form-upgrade-locked-img {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .stripe-form {
    padding: 0 0 0 0;
  }
  .stripe-form-type-payment-list {
    margin: 0 0 25px 0;
  }
  .stripe-form-wrapper input[type='radio'] {
    height: 5.1vw;
  }
  .stripe-form .card-radios > div > p {
    font-size: 14px;
  }
  .buy-plan-form .stripe-form > .card-radios {
    flex-direction: row;
  }
  .old-credit {
    display: none;
  }
  .old-credit-mobile {
    display: block;
  }
  .card-radios > div > p.violet {
    color: var(--color-primary);
  }
  .stripe-form-wrapper input[type='radio']::before {
    height: 14px;
    width: 14px;
  }
  .card-number-input,
  .card-date-input,
  .card-cvc-input,
  .card-holder {
    padding: 20px 18px;
    margin: 0;
  }
  .privacy-checkbox input[type='checkbox'] {
    margin-right: 2.5vw;
    flex: 0 0 20px;
  }
  .discount-field {
    margin-top: 10vw;
  }
  .stripe-form > .privacy-checkbox {
    margin: 30px 0 8vw 0;
  }

  .stripe-form-type-payment-list {
    gap: 12px;
  }

  .card-number {
    margin-top: 20px;
  }

  .discount-field > input {
    padding: 19px 18px;
    height: auto;
    line-height: 120%;
    margin-top: 10px;
  }
}
