.social-medias {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.social-medias__copy-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

.social-medias__copy-link {
    position: relative;
}

.social-medias__email {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.social-button__name,
.social-medias__name {
    padding: 0;
    margin: 0 0 0 5px;

    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', 'sans-serif';
    color: #030054;
}

.social-medias__copy-link {
    width: 100%;
    font-size: 14px;
    color: #030054;
}
.social-medias__copy-link__cont-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 0;
    border-radius: 50%;
    margin: 0 5px 0 0;
}

.social-medias-copied {
    display: none;
    position: absolute;
    top: -15px;
    right: 5px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background: #030054;
    padding: 5px;
    font-size: 10px;
    color: white;
}

.social-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;

    min-width: 48px;
    min-height: 48px;
    background-color: transparent;
}

.social-button__inside {
    display: flex;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    background-image: url("../../images/facebookIconBlue.svg");
    /*transition: all 1s;*/
}

.social-button__inside:hover {
    min-width: 48px;
    min-height: 48px;
}

.facebook:hover {
    background-image: url("../../images/facebookIconLightBlue.svg");
}

.facebook-messenger {
    background-image: url("../../images/facebookMessengerIcon.svg");
}
.facebook-messenger:hover {
    background-image: url("../../images/facebookMessengerLightIcon.svg");
}

.twitter {
    background-image: url("../../images/twitterIcon.svg");
}

.twitter:hover {
    background-image: url("../../images/twitterLightIcon.svg");
}

.email {
    background-image: url("../../images/emailIcon.svg");
}

.email:hover {
    background-image: url("../../images/emailLightIcon.svg");
}

.copy {
    background-image: url("../../images/copyIcon.svg");
}

.copy:hover {
    background-image: url("../../images/copyLightIcon.svg");
}

@media only screen and (max-width: 590px) {
    .social-medias {
        flex-direction: row;
        align-items: flex-start;
        gap: unset;
    }

    /*.social-button,*/
    /*.social-medias__email,*/
    /*.social-medias__copy-link {*/
    /*    flex-direction: column;*/
    /*    width: 85px;*/
    /*}*/

    .social-medias__copy-link {
        text-align: center;
    }

    .social-button__name,
    .social-medias__copy-link,
    .social-medias__name {
        font-weight: 500;
        font-size: 14px;
    }
}
