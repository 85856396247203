.my-list-college-hr {
  background: rgba(241, 241, 241, 1);
  border: none;
  height: 1vw;
  margin-left: -45%;
  width: 150%;
}
.my-list-college {
  position: relative;
  padding: 4vw 1.9vw 1.9vw;
  background-color: rgba(241, 241, 241, 1);
  border-radius: 10px;
  width: 25.6vw;
  box-sizing: border-box;
}

.my-list-college.not-allowed {
  position: relative;
}

.not-allowed-modal {
  position: absolute;
  inset: 0;
}

.my-list-college > div {
  display: flex;
  justify-content: space-between;
}

.my-list-college > .my-list-college-route {
  display: block;
  position: relative;
}

.not-in-list > div,
.not-in-list > img,
.not-in-list > header > img,
.not-in-list > h4 {
  opacity: 0.5;
}
.not-in-list > header {
  color: rgba(3, 0, 84, 0.5);
}
.net-price-calc > span > a > button,
.net-price-calc > span > a > .btn-locked {
  padding: 0.5vw;
  white-space: break-spaces;
  font-size: 1vw;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: transparent;
  border-radius: 50px;
  font-weight: 700;
}

.scholarship-buttons > a {
  width: 100%;
}

.scholarship-buttons > a > button,
.scholarship-buttons-locked > a {
  width: 100%;
  padding: 0.7vw;
  white-space: break-spaces;
  font-size: 1vw;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: transparent;
  border-radius: 50px;
  font-weight: 700;
}

.scholarship-buttons-locked > a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-color: var(--primary-typography);
  background-color: transparent;
  cursor: not-allowed;
}

.scholarship-buttons-locked > a > button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: var(--primary-typography);
  border-color: var(--primary-typography);
  font-size: 0.8vw;
}

.college-list-photo {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 18vw;
}
.college-header-my-list > img {
  height: 28px;
  width: 28px;
  /*margin-right: 0.7vw;*/
  margin: 0 0.3vw 0 0.4vw;
  cursor: pointer;
}
.college-header-my-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 1.98vw;
  background: rgba(241, 241, 241, 1);
  width: 100%;
  z-index: 2;
  top: 0;
  gap: 10px;
  height: 30px;
}
.college-header-my-list > span {
  font-weight: 600;
  font-size: 1.4vw;
  color: var(--headings-blue);
  white-space: inherit;
  cursor: pointer;
}
.college-header-my-list > span:hover {
  text-decoration: underline;
}

.college-header-my-list-title {
  padding-right: 0;
}
.college-header-my-list > .autocomplete {
  width: 90%;
}
.college-header-my-list > .autocomplete > div {
  padding-left: 20%;
  /*padding-left: 16%;*/
  /*padding-right: 20px;*/
  font-weight: 400;
  font-size: 16px;
  color: var(--primary-typography);
}
.my-list-college > .my-list-college-route > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9vw 1.3vw;
}

.my-list-college > div:nth-child(even) {
  background-color: white;
}

div.my-list-college > div.my-list-college-route {
  background-color: rgb(255, 255, 255, 0);
}

.my-list-college > .my-list-college-route > div:nth-child(odd) {
  background-color: white;
}
.my-list-college.padding-my-list-college-mobile > div:nth-child(even) {
  background: rgba(241, 241, 241, 1);
}
.my-list-college.padding-my-list-college-mobile > div:nth-child(odd) {
  background: white;
}
.my-list-college.padding-my-list-college-mobile > .my-list-college-route:nth-child(odd) {
  background: rgba(241, 241, 241, 1);
}
.my-list-college > div > p,
.my-list-college > .my-list-college-route > div > p {
  color: var(--headings-blue);
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  max-width: 60%;
  margin: 0;
}
.my-list-college > .my-list-college-route > div > p {
  width: 100%;
}
.my-list-college > div > span,
.my-list-college > .my-list-college-route > div > span {
  color: var(--primary-typography);
  font-weight: 600;
  font-size: 0.93vw;
  max-width: 40%;
  padding: 0;
}
.my-list-college > .my-list-college-route > h4 {
  width: 100%;
  padding: 0.2vw 1.3vw;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid #030054;
}

.my-list-college > span {
  width: 100%;
}
.my-list-college > .recommended {
  border: 0.01vw solid var(--color-primary);
  border-radius: 1.3vw;
  background: rgba(126, 16, 110, 0.1);
  color: var(--color-primary);
  font-weight: 400;
  font-size: 0.8vw;
  padding: 0.13vw 0.7vw;
  position: absolute;
  top: 0.8vw;
  left: 2vw;
  width: fit-content;
  z-index: 2;
}
.my-list-college .my-list-college-title {
  margin: 0 4.5vw;
  padding: 0.2vw 1.3vw;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1vw;
  color: white;
  background-color: #030054;
}
.my-list-college > .my-list-college-route > div > p {
  color: var(--primary-typography);
}
.my-list-college > div.my-list-college-route > div.my-list-modal-profile,
.my-list-college > div.my-list-college-route > div.my-list-modal-admission,
.my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
  background-color: rgba(70, 70, 70, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1%;
  position: absolute;
  left: -1%;
  padding: 8% 8%;
}

.my-list-college > div.my-list-college-route > div.my-list-modal-profile {
    top: 15%;
    padding: 24% 8%;
}

.my-list-college > div.my-list-college-route > div.my-list-modal-admission {
    top: 26%;
    padding: 83% 8%;
}

.my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
    top: 49%;
    padding: 15.5% 8%;
}

.my-list-college-route > div.my-list-modal-profile > img,
.my-list-college-route > div.my-list-modal-admission > img,
.my-list-college-route > div.my-list-modal-schoolarship > img {
  width: 3.3vw;
}
.my-list-college-route > div.my-list-modal-profile > p,
.my-list-college-route > div.my-list-modal-admission > p,
.my-list-college-route > div.my-list-modal-schoolarship > p {
  font-weight: 600;
  font-size: 1.2vw;
  color: #fff;
  padding: 3%;
  margin: 0;
  text-align: center;
}
.my-list-college-route > div.my-list-modal-profile > div.my-list-modal-text,
.my-list-college-route > div.my-list-modal-admission > div.my-list-modal-text,
.my-list-college-route > div.my-list-modal-schoolarship > div.my-list-modal-text {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 1.1vw;
  color: #fff;
  margin: 0;
  text-align: center;
}
.my-list-college-route > div.my-list-modal-profile > a,
.my-list-college-route > div.my-list-modal-admission > a,
.my-list-college-route > div.my-list-modal-schoolarship > a {
  padding: 3% 0;
  white-space: nowrap;
  width: 13.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  color: var(--color-primary);
  opacity: 1;
  z-index: 1;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  margin: 5%;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 40px;
}

.my-list-college > .my-list-college-route > div.scholarship-buttons-locked {
  background-color: transparent;
}

.my-list-college > div.my-list-college-route > div.scholarship-buttons,
.my-list-college > div.my-list-college-route > div.scholarship-buttons-locked {
  width: 100%;
  box-sizing: border-box;
  background: rgba(241, 241, 241, 1);
}

.my-list-primary-button,
.my-list-primary-button-locked {
  width: 100%;
  padding: 0.7vw;
  white-space: break-spaces;
  font-size: 1.3vw;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: transparent;
  border-radius: 50px;
  font-weight: 600;
}

.my-list-primary-button-locked {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--primary-typography);
  border-color: var(--primary-typography);
}

.my-list-college h4.my-list-college-title {
  font-size: 1.5vw;
  min-width: fit-content;
}
@media only screen and (min-width: 2000px)  {
  .scholarship-buttons > a > button,
  .my-list-primary-button,
  .net-price-calc > span > a > button, .net-price-calc > span > a > .btn-locked {
    border: 3px solid var(--color-primary);
  }

  .scholarship-buttons > a > button, .scholarship-buttons-locked > a {
    border-radius: 100px;
  }

  .my-list-primary-button, .my-list-primary-button-locked {
    border-radius: 70px;
  }
}
@media only screen and (min-width: 1600px) {
  .my-list-primary-button-locked > img {
    width: 1.3vw;
  }
}
@media only screen and (max-width: 1350px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 85% 8%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
        top: 50%;
        padding: 13.5% 8%;
    }
}
@media only screen and (max-width: 1250px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 89% 8%;
    }
}

@media only screen and (max-width: 1150px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 91% 8%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
        top: 50%;
        padding: 13% 8%;
    }
}

@media only screen and (max-width: 1050px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        top: 26.5%;
        padding: 95% 8%;
    }
}

@media only screen and (max-width: 900px) {
  .my-list-college > .my-list-college-route > div > p,
  .my-list-college > .my-list-college-route > div > span {
    font-size: 1.8vw;
  }
  .my-list-primary-button,
  .my-list-primary-button-locked {
    font-size: 2.3vw;
  }
  .my-list-college h4.my-list-college-title,
  .my-list-college > h4,
  .my-list-college > .my-list-college-route > h4,
  .college-header-my-list {
    font-size: 2.2vw;
  }
  .my-list-college > .my-list-college-route > div > img {
    width: 15px;
    height: 30px;
  }
  .college-header-my-list > .college-header-my-list-title {
    font-size: 2.5vw;
  }
  .my-list-college > .recommended {
    font-size: 1.5vw;
  }
  .my-list-college > div.my-list-college-route > div.my-list-modal-profile,
  .my-list-college > div.my-list-college-route > div.my-list-modal-admission,
  .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
    padding: 12%;
    left: 2.6%;
  }

  .scholarship-buttons > a > button,
  .scholarship-buttons-locked > a > button {
    font-size: 1.8vw;
  }

  .my-list-college > div > p,
  .my-list-college > div > span {
    font-size: 1.9vw;
    padding: 0.9vw 1.3vw;
  }

  .net-price-calc > span > a > button {
    font-size: 10px;
  }
  .college-header-my-list > img {
    height: 13px;
  }
  .my-list-college-route > div.my-list-modal-profile > div.my-list-modal-text,
  .my-list-college-route > div.my-list-modal-profile > p,
  .my-list-college-route > div.my-list-modal-admission > p,
  .my-list-college-route > div.my-list-modal-schoolarship > p {
    white-space: nowrap;
    font-size: 1.28vw;
  }
  .my-list-college.padding-my-list-college-mobile {
    padding-top: 5vw;
  }

  .my-list-college-route > div.my-list-modal-profile > div.my-list-modal-text,
  .my-list-college-route > div.my-list-modal-admission > div.my-list-modal-text,
  .my-list-college-route > div.my-list-modal-schoolarship > div.my-list-modal-text {
    font-size: 1.28vw;
  }


  .my-list-college > .my-list-college-route > div > img.my-list-modal-profile-img {
    width: 30px;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
    top: 23%;
    padding: 22% 12%;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
      top: 30%;
      padding: 72% 12%;
  }

   .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
      top: 59%;
      padding: 12% 12%;
  }
}
@media only screen and (max-width: 820px) {
  .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
    padding: 24% 12%;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
    top: 30.5%;
    padding: 75% 12%;
  }
}
@media only screen and (max-width: 720px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
        padding: 24% 12%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 80% 12%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
            padding: 11.5% 12%;
        }
    }
@media only screen and (max-width: 620px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
        padding: 26% 12%;
    }

   .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 88% 12%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
            padding: 10.5% 12%;
    }
}
@media only screen and (max-width: 520px) {
  .my-list-college.not-allowed .not-allowed-modal {
    display: none;
  }

  .my-list-college > div > p,
  .my-list-college > div > span {
    font-size: 3.5vw;
  }
  .division-name {
    min-width: fit-content;
  }

  .my-list-college h4.my-list-college-title {
    font-size: 3.8vw;
  }
  .net-price-calc > span > a > button,
  .net-price-calc > span > a > .btn-locked {
    font-size: 3vw;
  }
  .my-list-college > div > span,
  .my-list-college > .my-list-college-route > div > span {
    font-size: 3.6vw;
    font-weight: 700;
  }
  .my-list-college > div > span.centerButton {
    max-width: none;
    margin: 0 auto;
  }
  .scholarship-buttons > span > a > button,
  .scholarship-buttons-locked > span > a > button {
    font-size: 3vw;
    padding: 2.7vw 7.67vw;
  }
  .scholarship-buttons > span > a > button:first-child,
  .scholarship-buttons-locked > span > a > button:first-child {
    margin: 1.5vw 0;
  }

  .my-list-college .college-header-my-list > img {
    height: 8.5vw;
    margin-right: 2vw;
  }
  .my-list-college .college-header-my-list,
  .my-list-college .college-header-my-list > span {
    background-color: white;
    font-size: 4.1vw;
    height: max-content;
  }
  .college-header-my-list {
    width: 100%;
  }
  .selected-list > .my-list-college {
    background-color: white;
    padding: 5vw 11.1vw 0;
    width: 100%;
  }
  .net-price-calc > span > a > button {
    font-size: 3.6vw;
    padding: 1.5vw;
  }
  .my-list-college > h4,
  .my-list-college > .my-list-college-route > h4 {
    font-size: 4.1vw;
    margin: 4vw 0;
  }

  .my-list-college-title {
    font-size: 3.8vw;
    margin: 6vw 0 0;
    padding: 3vw;
    border-radius: 15px;
  }

  .scholarship-buttons > a > button,
  .scholarship-buttons-locked > a > button {
    font-size: 3.8vw;
  }

  .scholarship-buttons-locked > a > button {
    font-size: 3.5vw;
    width: min-content;
    white-space: nowrap;
  }

  .my-list-college > .my-list-college-route > .div.my-list-modal-schoolarship,
  .my-list-college > .my-list-college-route > div > p {
    font-size: 3.6vw;
    font-weight: 400;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-profile,
  .my-list-college > div.my-list-college-route > div.my-list-modal-admission,
  .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
    top: 32%;
    padding: 10px;
    left: 0%;
    width: 92%;
  }

  .my-list-college-route > div.my-list-modal-profile > a,
  .my-list-college-route > div.my-list-modal-admission > a,
  .my-list-college-route > div.my-list-modal-schoolarship > a {
    padding: 3% 0px;
    width: 28.5vw;
    font-size: 4vw;
  }

  .my-list-college-route > div.my-list-modal-profile > p,
  .my-list-college-route > div.my-list-modal-admission > p,
  .my-list-college-route > div.my-list-modal-schoolarship > p {
    text-align: center;
    font-size: 3.1vw;
  }

  .my-list-college-route > div.my-list-modal-profile > img,
  .my-list-college-route > div.my-list-modal-admission > img,
  .my-list-college-route > div.my-list-modal-schoolarship > img {
    width: 8.3vw;
  }

  .my-list-college-route > div.my-list-modal-profile > div.my-list-modal-text,
  .my-list-college-route > div.my-list-modal-admission > div.my-list-modal-text,
  .my-list-college-route > div.my-list-modal-schoolarship > div.my-list-modal-text {
    font-size: 3vw;
  }

  .my-list-primary-button,
  .my-list-primary-button-locked {
    font-size: 4.5vw;
  }
  .my-list-college > .recommended {
    border-radius: 3vw;
    font-size: 3vw;
    padding: 0.5vw 2vw;
    top: 2.8vw;
    left: 12vw;
  }
  .my-list-college.padding-my-list-college-mobile {
    padding-top: 10vw;
  }

  .my-list-college > div.my-list-college-route > div.scholarship-buttons,
  .my-list-college > div.my-list-college-route > div.scholarship-buttons-locked {
    background: transparent;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
    top: 21%;
    padding: 20% 4%;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
      top: 30%;
      padding: 56.5% 4%;
  }

  .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
      top: 61%;
      padding: 2.5% 4%;
  }
}


@media only screen and (max-width: 420px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 65% 4%;
        min-height: 130px;
    }

   .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
        top: 61%;
        padding: 1.5% 4%;
    }
}

@media only screen and (max-width: 320px) {
    .my-list-college > div.my-list-college-route > div.my-list-modal-profile {
        padding: 23% 4%;
    }

   .my-list-college > div.my-list-college-route > div.my-list-modal-admission {
        padding: 72% 4%;
    }

    .my-list-college > div.my-list-college-route > div.my-list-modal-schoolarship {
        top: 61%;
        padding: 0 4%;
    }
}