.select-btn-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.111vw;
}

.select-btn {
    padding: 0.696vw 1vw;
    border-radius: 0.555vw;
    background-color: var(--light-grey-smoke);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
}

.select-btn.selected {
    background-color: var(--primary-typography);
    color: #ffffff;
}

@media only screen and (max-width: 520px) {
    .select-btn-list {
        flex-wrap: wrap;
        gap: 8px 16px;
    }

    .select-btn {
        max-width: 100px;
        width: 25vw;
        padding: 16px 14px;
        font-size: 16px;
        border-radius: 8px;
    }
}

@media only screen and (max-width: 375px) {
    .select-btn {
        padding: 12px 8px;
        font-size: 14px;
    }
}