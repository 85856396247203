.service-component {
    padding: 1rem 2rem 4rem 2rem;
    background-color: #ffffff;
}

.service-component .title {
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 1rem 0;
}

.row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    grid-auto-rows: minmax(100px, auto);
}

.card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 7px 12px 0 rgba(0, 0, 0, .21);
}

.card__information__container {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card__image {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
}

.card__title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
}

.card__price {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-bottom: 1rem;
}

.card__button__container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card__button {
    color: #ffffff;
    background: #7e106e;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    padding: .75rem 1rem;
    text-decoration: none;
    width: 100%;
    text-align: center;
}


@media only screen and (max-width: 900px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 512px) {
    .row {
        grid-template-columns: repeat(1, 1fr);
    }
}