.confirm-download-list-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px 11px;

    position: relative;
    width: 570px;
    height: 164px;

    background-color: #ffffff;

    border-radius: 20px;
    box-sizing: border-box;
}

.confirm-download-list-modal__up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.confirm-download-list-modal__title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #030054;

    margin: 0;
    padding: 0;
}

.confirm-download-list-modal__close {
    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.confirm-download-list-modal__description {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #403e59;
    padding: 11px 0 14px;
    margin: 0;
    width: 100%;
    min-height: 40px;

    border-bottom: 1px solid #e4e6ed;
    white-space: pre-wrap;
}

.confirm-download-list-modal__description--first__main {
    display: inline-block;
    white-space: pre-wrap;
}

.confirm-download-list-modal__description--second {
    white-space: nowrap;
    margin: 0;
    padding-left: 5px;
}

.confirm-download-list-modal__description--first__main__support {
    text-decoration: underline;
}

.confirm-download-list-modal__cont-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 8px;
    box-sizing: border-box;
    width: 100%;
}

.confirm-download-list-modal__btn {
    width: 99px;
    height: 36px;
    background: var(--color-primary);
    border-radius: 20px;
    border: 1px solid var(--color-primary);


    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.confirm-download-list-modal__btn--cancel {
    margin-right: 8px;

    color: #403e59;
    background-color: #ffffff;
    border: 1px solid #cbd0e1;
}

.confirm-download-list-modal__btn--confirm {
    padding-right: 1px;
}
