.container {
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 18.75rem;
    padding: 1.5rem;
    border-radius: 12px;
    background: var(--white-color);
}

.title {
    margin: 0;
    color: var(--primary-typography);
    font-size: 1.75rem;
}

.subtitle {
    margin: 0;
    color: var(--primary-typography);
    font-size: 1.25rem;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.button {
    display: flex;
    position: relative;
    padding: 0 0.5rem;
    height: 2.5rem;
    min-width: 5rem;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    font-size: 1.25rem;
    font-weight: 600;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 12px;
    background: var(--color-primary);
    cursor: pointer;
}
.buttonArrowIcon {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../../../../../images/arrowWhiteSmall.svg");
    background-size: cover;
}

.checkboxesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .75rem;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 45%;
}

.checkboxContainer input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.1rem solid var(--color-primary);
    border-radius: 4px;
    background: var(--white-color);
}
.checkboxLabel {
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-typography);
}


.radioInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.radioInputWrapper {
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
}
.radioInputWrapper > .radioInput {
    width: 1.25rem;
    height: 1.25rem;
    appearance: none;
    margin: 0;
    border-radius: 50%;
    transform: translateY(-0.075em);
    place-content: center;
    cursor: pointer;
    border: 0.15rem solid var(--color-primary);
}
.radioInputWrapper > .radioInput:checked {
    color: var(--color-primary);
    border-color: var(--color-primary);
    width: 1.25rem;
    height: 1.25rem;
}
.radioInputWrapper > .radioInput::before {
    background-color: var(--color-primary);
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

.autocomplete {
    top: 3rem;
    left: 0;
    color: var(--primary-typography);
    font-size: 1.25rem;
    font-weight: 400;
    width: 110%;
}
.autocomplete > div {
    width: 100%;
}
.autocomplete > div:hover {
    background: rgba(126, 16, 110, 0.12);
}

.rangeWrapper {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
}

@media only screen and (max-width: 900px){
    .container {
        width: 25rem;
    }
}

@media only screen and (max-width: 520px) {
    .container {
        width: 100%;
    }
}