.admin-user-list-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    background-color: white;
    border-radius: 20px;
    width: 50vw;
    padding: 15px;
}

.admin-user-list-modal-img {
    align-self: flex-end;
    cursor: pointer;
}

.admin-user-list-modal-username {
  align-self: flex-start;
}

.admin-user-list-modal-list-backups {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 0;
}

.admin-user-list-modal-item-backups {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 100%;
}

.admin-user-list-modal-list-name {
    font-size: 1.3vw;
    font-weight: bold;
    color: #030054;
}

.admin-user-list-modal-list-cont-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.admin-user-list-modal-list-button {
    background: #7e106e;
    border: #7e106e;
    border-radius: 20px;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
}

.admin-user-list-modal-list-button-download {
    background: #030054;;
}

.admin-user-list-modal-list-line {
    width: 100%;
}

.admin-user-list-modal-zero-list-backups {
    color: #d33841;
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
}