/* eslint-disable */
.modal-first {
    box-sizing: border-box;
    background-color: white;
    border-radius: 20px;
    width: 50vw;
    min-width: 300px;
    max-width: 560px;
}

.modal-first * {
    box-sizing: border-box;
}

.modal-first.small > h1 {
    padding: 24px 24px 0;
    text-align: left;
}

.modal-first > h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-primary);
    margin: 0;
    text-align: center;
    padding: 50px 20px 0;
}

.modal-first > img {
    position: absolute;
    top: 35px;
    right: 25px;
    cursor: pointer;
}

.modal-first-confirm {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    background: var(--color-primary);
    color: #ffffff;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    border: none;
}
.modal-first-body {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 25px 20px;
}

.modal-first-footer {
    border-top: 1px solid #E4E6ED;
    display: flex;
    justify-content: flex-end;
    padding: 17px 25px 10px;
}

.modal-first-body > div {
    display: flex;
    flex-direction: column;
}

.modal-first-body > div:not(:last-of-type) {
    margin-bottom: 16px;
}

.modal-first-body > div > h1 {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    margin: 0;
}

.modal-first-body > div > ul {
    padding-left: 65px;
    font-size: 16px;
    font-weight: 600;
    color: var(--headings-blue);
    margin: 10px 0 0;
}

.modal-first-body > div > ul p {
    margin: 0 0 7px;
}

.modal-first-body > div > h1 > img {
    margin-right: 10px;
    width: 29px;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    .modal-first {
        width: 75vw;
    }
}

@media only screen and (max-width: 520px) {
    .modal-first {
        min-width: initial;
        width: 85vw;
    }

    .modal-first > h1 {
        font-size: 18px;
        line-height: 24px;
    }

    .modal-first-body > div > h1 {
        font-size: 14px;
    }

    .modal-first-body > div > h1 > img {
        width: 25px;
    }

    .modal-first-body > div > ul {
        font-size: 14px;
    }
}
