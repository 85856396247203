.landing-page *{
    box-sizing: border-box;
}

.landing-page-welcome-text,
.landing-page-college-insights-text,
.landing-page-container-logo,
.landing-page-by-text,
.landing-page-find-merit,
.landing-page-computer-img,
.landing-page-get-started-btn {
    padding: 0;
    margin: 0;
}

.landing-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 40px;
    padding-bottom: 40px;

    background: url("../../images/background-landing-page.svg") no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    min-height: 100vh;

    width: 100%;
    height: auto;

    top: 0;
    left: 0;
}

.landing-page-welcome-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;

    color: var(--color-primary);
}

.landing-page-college-insights-text {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    color: #030054;

    margin-top: 5px;
}

.landing-page-container-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 5px;
}

.landing-page-by-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: var(--color-primary);
    padding: 4px 8px 0 0
}
.landing-page-logo {
    width: 160px;
    height: 35px;
}

.landing-page-find-merit {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 2.8vw;
    letter-spacing: -0.02em;
    color: #00b4d8;
    text-align: center;

    margin-top: 30px;
}

.landing-page-computer-img {
    width: 48vw;
    height: 275px;

    margin-top: 10px;
}

.landing-page-free-account-text {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -0.02em;
    color: #030054;

    margin-top: 30px;
}

.landing-page-get-started-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    cursor: pointer;

    margin-top: 15px;

    background: var(--color-primary);
    border-radius: 40px;
    border: 0;

    padding: 12px 51px;
}

.landing-page-already-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #464646;
}

.landing-page-sign-in-link {
    color: #00b4d8;
}

@media only screen and (max-width: 950px) {
    .landing-page-computer-img {
        width: 57vw;
    }

    .landing-page-find-merit {
        font-size: 4.5vw;
    }

    .landing-page-get-started-btn {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 700px) {
    .landing-page-computer-img {
        width: 70vw;
    }

    .landing-page-find-merit {
        font-size: 5vw;
    }

    .landing-page-get-started-btn {
        margin-top: 25px;
    }
}
@media only screen and (max-width: 520px) {
    .landing-page-computer-img {
        width: 100vw;
    }

    .landing-page-find-merit {
        font-size: 5vw;
    }

    .landing-page-get-started-btn {
        margin-top: 25px;
    }
}
@media only screen and (max-width: 390px) {
    .landing-page-computer-img {
        width: 107vw;
        height: 250px;
    }

    .landing-page-college-insights-text {
        font-size: 11.2vw;
    }

    .landing-page-find-merit {
        font-size: 4.7vw;
    }

    .landing-page-get-started-btn {
        font-size: 20px;
        margin-top: 25px;
    }

    .landing-page-free-account-text {
        font-size: 24px;
    }

    .landing-page-already-text,
    .landing-page-sign-in-link {
        font-size: 14px;
    }
}