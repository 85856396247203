.peterson-links-container {
  width: 50%
}
.admin-upload-csv-container {
  background: rgba(241, 241, 241, 1);
  padding: 40px;
}
.admin-upload-csv-wrapper {
  background: white;
  padding: 40px;
}
.admin-upload-csv-nav-dashboard {
  position: relative;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}
.admin-upload-csv-nav-dashboard > p {
  font-family: 'Source Sans 3';
  font-size: 16px;
  margin-left: 10px;
  color: rgba(64, 62, 89, 1);
}
.admin-upload-csv-headers {
  font-family: 'Source Sans 3';
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.admin-upload-csv-submit-btn {
  width: 200px;
  height: 52px;
  padding: 10px 20px;
  border-radius: 30px;
  background: rgba(126, 16, 110, 1);
  border: none;
  cursor: pointer;
  font-family: 'Source Sans 3';
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: white;
  margin-top: 30px;
}

.admin-pull-btn-disabled {
  display: flex;
  text-align: center;
  width: 215px;
  height: 52px;
  padding: 10px 20px;
  border-radius: 30px;
  background: rgba(126, 16, 110, 1);
  border: none;
  cursor: not-allowed;
  font-family: 'Source Sans 3';
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: white;
  margin-top: 30px;
}

.admin-pull-btn {
  display: flex;
  text-align: center;
  width: 215px;
  height: 52px;
  padding: 10px 35px;
  border-radius: 30px;
  background: rgba(126, 16, 110, 1);
  border: none;
  cursor: pointer;
  font-family: 'Source Sans 3';
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: white;
  margin-top: 30px;
}

.admin-loader {
  margin: 5px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.admin-loader:after {
  margin: 2px;
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
  animation: admin-loader 1.2s linear infinite;
}

.peterson-links-wrapper {
  display: flex;
  justify-content: space-between;
}
.peterson-links {
  display: flex;
  align-items: center;
  position: relative;
}
.peterson-links > p {
  font-family: "Source Sans 3";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(3, 0, 84, 1);
  margin-left: 40px;
}
.peterson-link-warn {
  position: absolute;
}
.peterson-links-buttons {
  display: flex;
  align-items: center;
}
.peterson-links-buttons > button {
  height: 24px;
  padding: 0 20px;
  border-radius: 30px;
  border: none;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: white;
  white-space: nowrap;
  background: rgba(3, 0, 84, 1);
  cursor: pointer;
  margin-right: 10px;
}
.peterson-links-buttons > .download {
  background: rgba(126, 16, 110, 1);
}
.upload-csvs-body {
  display: flex;
  justify-content: space-between;
}
.upload-files-inputs > p {
  color: var(--headings-blue);
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px 0;
}
input[type='file'] {
  opacity: 0;
  position: absolute;
  width: inherit;
  cursor: pointer;
}
.upload-files-inputs > div {
  position: relative;
  width: 40vw;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding-left: 10px;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgba(167, 167, 167, 1);
}
.upload-files-inputs > div > button {
  background-color: rgba(217, 217, 217, 1);
  border: inherit;
  padding: 12px;
  cursor: pointer;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgba(70, 70, 70, 1);
}
.uploadsuccess {
  color: green;
}
.upload-error {
  color: red;
}
#colleges,
#majors,
#highschools,
#coas {
  border: none;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 43px;
  height: 12px;
}
.lds-ellipsis div {
  position: absolute;
  top: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: -5px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: -10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media only screen and (max-width: 1210px) {
  .upload-csvs-body {
    flex-direction: column;
    align-items: center;
  }
  .peterson-links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .peterson-links-wrapper {
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  .upload-files-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .upload-files-inputs > div {
    width: 90%;
  }
}
@media only screen and (min-width: 2000px){
  .upload-csvs-body {
    padding: 70px;
  }
}