.college-details-section {
  background: #ffffff;
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1%;
  margin: 5% 0;
  overflow: hidden;
}
.colleges-details-wrapper-container-under {
   padding: 3% 3%;
   background: #f1f1f1;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}
.colleges-details-wrapper {
  padding: 0;
}
.section-hr-line {
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
}
.college-details-section > h6 {
  margin: 1% 0 3% 3%;
  font-size: 2vw;
}
.college-details-section > div {
  padding: 2% 0 4% 5%;
}
.college-details-section > div > p {
  display: flex;
  align-items: center;
  color: var(--light-grey);
  font-size: 1.2vw;
}
.college-details-section > div > hr {
  height: 5px;
  border: none;
  background: rgba(217, 217, 217, 0.5);
  margin: 3% -13%;
}
.admission-res-block > span {
  display: block;
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--primary-typography);
  width: 3vw;
  cursor: default;
  padding: 0;
  text-align: end;
  white-space: nowrap;
}
.admission-res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.college-details-section > div > p > img {
  padding-right: 10px;
  height: 2vw;
}
.college-details-section > div > .unlocked-field {
  color: var(--primary-typography);
}

@media only screen and (max-width: 900px) {
  .admission-res-block > span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 520px) {
  .admission-res-block {
    margin-right: 0;
  }

  .admission-res-block > span {
    font-size: 3.4vw;
    color: #464646;
    font-weight: 600;
    width: auto;
  }
  .college-details-section > div > hr {
    display: none;
  }
}

.college-details-section-locked-admission {
  display: flex;
  background-color: darkgray;
  justify-content: space-between;
}
.college-details-section-locked-admission .race-res-block > span {
  display: block;
  color: #464646;
}
.college-details-section-locked-admission .race-res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /*margin-right: 12%;*/
}
.college-details-section > div .college-details-section-locked-admission {
  padding: 5%;
  margin: -3% -6% -5% -6%;
  position: relative;
}
.upgrade-profile-message-admissions {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  padding: 2% 2%;
  position: absolute;
  top: 15%;
  left: 31%;
}
.upgrade-profile-message-admissions > img {
  width: 3.3vw;
}
.upgrade-profile-message-admissions > p {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--headings-blue);
  padding: 3%;
  margin: 0;
}
.upgrade-profile-message-admissions > span {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 1vw;
  color: var(--headings-blue);
  margin: 0;
}
.upgrade-profile-message-admissions > a {
  padding: 3% 0;
  white-space: nowrap;
  width: 13.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 40px;
  font-weight: 600;
  margin: 5%;
  text-decoration: none;
  text-align: center;
}
.college-details-section > div > hr {
  height: 5px;
  border: none;
  background: rgba(217, 217, 217, 0.5);
  margin: 3% -13%;
}
.college-details-section > div > p > img {
  padding-right: 10px;
  height: 2vw;
}
.college-details-section > div > .unlocked-field {
  color: var(--primary-typography);
}

@media only screen and (max-width: 900px) {
  .college-details-section > div .college-details-section-locked-admission {
    padding: 6%;
  }
}

@media only screen and (max-width: 520px) {
  .college-details-section-locked-admission {
    justify-content: space-between;
  }

  .college-details-section-locked-admission > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .college-details-section-locked-admission > .upgrade-profile-message-admissions {
    background-color: darkgrey;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17% 2%;
    position: absolute;
    top: 58.5%;
    width: 96%;
    border-radius: 0;
    left: 0;
  }
  .college-details-section > div .college-details-section-locked-admission {
    background-color: white;
    padding: 9% 0 0 0;
    position: unset;
    margin: 0;
  }

  .college-details-section-locked-admission .race-res-block {
    margin-right: 1px;
  }

  .race-res-block-admission {
    padding-left: 17%;
    width: 30%;
    align-items: flex-start;
  }

  .upgrade-profile-message-admissions > img {
    width: 7.5vw;
  }
  .upgrade-profile-message-admissions > p {
    font-weight: 600;
    font-size: 4.6vw;
    color: white;
    padding: 3%;
    margin: 0;
  }
  .upgrade-profile-message-admissions > span {
    position: relative;
    padding: 0;
    font-weight: 600;
    font-size: 3.6vw;
    white-space: normal;
    text-align: center;
    width: 70%;
    color: white;
    margin: 0;
  }
  .upgrade-profile-message-admissions > a {
    padding: 4% 0;
    white-space: nowrap;
    font-size: 4.6vw;
    cursor: pointer;
    border: none;
    background: white;
    color: var(--color-primary);
    border-radius: 40px;
    font-weight: 600;
    margin: 3%;
    width: 60%;
  }

  .colleges-details-wrapper-container-under {
    padding: 0;
    background-color: #fff;
  }
}
