.admin-backups {}
.admin-backups-nav-dashboard {
    position: relative;
    background-color: #f2f3f3;
    width: 100%;
    cursor: pointer;
    padding: 5px 0 5px 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    text-decoration: none;
}

.admin-backups-nav-dashboard > img {
    width: 20px;
}

.admin-backups-nav-dashboard > p {
    color: #030054;
    font-weight: 500;
    font-size: 26px;
    margin: 0;
}

.admin-backups-main {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 15px;
}

.admin-backups-cont-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.admin-backups-main-title {
    color: #030054;
    font-size: 2vw;
    margin: 0;
}

.admin-backups-arrow-left {
    width: 30px;
    height: 30px;
}

.admin-backups-arrow-left-second {
    margin-left: -16px;
}

.admin-backups-main-button {
    color: white;
    font-size: 1vw;
    font-weight: bold;
    padding: 10px;
    width: 25%;
    border: 1px solid #030054;
    border-radius: 20px;
    background-color: #030054;
    cursor: pointer;
}

.admin-list-backups {
    padding: 0;
    margin: 0;
    list-style: none;
}
.admin-item-backups {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 7px 10px;
    border: 1px solid #a6a6a6;
    border-bottom: 0;
}
.admin-item-backups:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.admin-item-backups:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid #a6a6a6;
}

.admin-item-backups-name {
    color: #030054;
    font-weight: 500;
}


.admin-item-backups-cont-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.admin-item-backups-button {
    color: white;
    font-size: 1vw;
    font-weight: bold;
    background-color: #5e8057;
    border: 1px solid #5e8057;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
}

.admin-item-backups-button-delete {
    background-color: #d33841;
    border: 1px solid #d33841;
}

.admin-item-backups-button-download {
    background-color: #2579ba;
    border: 1px solid #2579ba;
}

.admin-backups-main-status-backup {
    color: #187122;
    background-color: #bff0b5;
    border: 0;
    border-radius: 10px;
    padding: 20px 10px;
}

.admin-backups-main-status-backup-deleted {
    background-color: #eea2a4;
    color: #d33841;
}

.admin-backups-main-status-backup-downloaded {
  background-color: #7cbbf1;
    color: #2579ba;
}

.admin-item-backups-button-disabled,
.admin-backups-main-button-disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #030054;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #030054 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dot-collision {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #030054;
    color: #030054;
}
.dot-collision::before, .dot-collision::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-collision::before {
    left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color:  #030054;
    color:  #030054;
    animation: dot-collision-before 2s infinite ease-in;
}
.dot-collision::after {
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #030054;
    color: #030054;
    animation: dot-collision-after 2s infinite ease-in;
    animation-delay: 1s;
}

@keyframes dot-collision-before {
    0%, 50%, 75%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-15px);
    }
}
@keyframes dot-collision-after {
    0%, 50%, 75%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(15px);
    }
}

.admin-backups-main-loader-cont {
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.admin-backups-main-loader-title {
    margin: 0;
}

.admin-backups-main-loader-cont-total {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    .admin-item-backups-button {
        font-size: 1.5vw;
    }

    .admin-backups-main-button {
        width: 35%;
        font-size: 1.4vw;
        white-space: nowrap;
    }

    .admin-item-backups-name {
        font-size: 2.5vw;
    }

    .admin-backups-nav-dashboard > p{
        font-size: 3vw;
    }

    .admin-backups-main-title {
        font-size: 4vw;
    }

    .admin-backups-main-button {
        width: 60%;
        font-size: 2.4vw;
    }

    .admin-backups-main-loader-title {
        font-size: 2.7vw;
    }
}
@media only screen and (min-width: 2000px) {
    .admin-item-backups-name {
        font-size: 1vw;
    }

    .admin-item-backups:first-child {
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
    }

    .admin-item-backups:last-child {
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .admin-backups-main-button,
    .admin-item-backups-button {
        padding: 20px;
        border-radius: 50px;
    }

    .admin-backups-arrow-left {
        width: 70px;
        height: 70px;
    }

    .admin-backups-arrow-left-second {
        margin-left: -38px;
    }

    .admin-backups-main {
        padding: 70px;
    }
}
@media only screen and (max-width: 520px) {
    .admin-item-backups-button {
        font-size: 2vw;
        padding: 5px;
    }

    .admin-backups-arrow-left {
        width: 20px;
        height: 20px;
    }

    .admin-backups-arrow-left-second {
        margin-left: -11px;
    }

    .admin-backups-main-status-backup {
      padding: 10px;
      font-size: 3vw;
    }

    .lds-ring {
        height: 40px;
        width: 40px;
    }

    .lds-ring div {
        width: 30px;
        height: 30px;
        border: 4px solid #030054;
        border-color: #030054 transparent transparent transparent;
    }

    .dot-collision,
    .dot-collision::after,
    .dot-collision::before {
        width: 10px;
        height: 10px;
    }

    .dot-collision::before {
        left: -10px;
    }

    .dot-collision::after {
        left: 10px;
    }

    .admin-backups-main-loader-cont-total {
        justify-content: flex-start;
        margin-left: 1.2vw;
    }

    .admin-backups-main-loader-cont {
        gap: 30px;
    }

    .admin-backups-main-loader-title {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 391px) {
    .admin-backups-nav-dashboard > img {
        width: 15px;
    }
}