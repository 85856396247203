.app-header>span {
  padding: 0;
}

.app-wrapper>button {
  padding: .5rem 1rem;
}

.app-wrapper>button>a {
  text-decoration: none;
  color: white;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0;
  width: 60%;
  margin: auto;
  font-family: 'Inter';
}

.app-description {
  text-align: center;
  font-size: 1.5rem;
}

.app-wrapper>h3 {
  color: var(--headings-blue);
}

.app-header {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.line {
  display: block;
  background-color: #f4f3f3;
  height: 1.5vw;
  border: 0;
  margin: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.flex-custom-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-white {
  color: #fff;
}

.dropdown-menu.show {
  display: block;
  visibility: visible;
}

p {
  margin-bottom: 0;
}

:focus-visible {
  outline: none;
}

.uppercase {
  text-transform: uppercase;
}

/* .header-items .header-item-container */
a:hover:after {
  border: solid #7e106e78;
  border-radius: 0;
  border-width: 0 0 3px;
  display: block;
  transition: transform .3s;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .line {
    height: 3.2vw;
  }
}

@media screen and (max-width: 520px) {
  .app {
    background-color: white;
    overflow-x: hidden;
  }

  .line {
    display: none;
  }
}