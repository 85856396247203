.mobile_select {
  display: none;
  padding: 0 3%;
  position: relative;
  z-index: 1;
}

.mobile_select::after {
  content: '';
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 0;
  border-top: 7px solid #030054;
  position: absolute;
  top: 45%;
  right: 7%;
  z-index: 1;
}

.custom_select {
  position: relative;
  padding: 15px 25px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  width: 100%;
  font-size: 3.4vw;
  color: #c2c2c2;
  -webkit-appearance: none;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 520px) {
  .mobile_select {
    display: block;
    width: 100%;
    padding: 0;
  }
}
