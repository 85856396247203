.header-profile {
  background-image: url("../../images/wave.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 260px;
}

.header-profile>h1 {
  font-weight: 600;
  font-size: 2rem;
  color: white;
  padding-top: 3rem;
  margin: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
}

.my-profile-body>img {
  min-width: 2px;
  height: 93%;
  left: 51%;
  position: absolute;
}

.my-profile-wrapper {
  background: white;
  width: 75.4vw;
  border-radius: 0.7vw;
}

.my-profile-main {
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  padding: 0 0 3rem 0;
}

.my-profile-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 5.5vw 0 0 0;
}

.my-profile-body>.my-profile-form {
  display: flex;
  justify-content: flex-start;
  gap: 5vw;
  flex-wrap: wrap;
  width: 80%;
}

.header-profile .wave {
  width: 100%;
  position: relative;
  top: -6vw;
  z-index: 0;
}

.my-profile-form .input-select>img {
  position: absolute;
  width: 1.5vw;
  right: 0;
  top: 1.5vw;
  cursor: pointer;
}

.my-profile-form .input-select .location-img {
  position: absolute;
  width: 1.5vw;
  right: 0;
  top: 10%;
  cursor: pointer;
}

.choice-radio {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: -2vw;
  margin-bottom: 6vw;
}

.choice-radio-act,
.choice-radio-sat,
.choice-radio-athlete {
  margin: 0 0 3.6vw;
}

.my-profile-form>p,
.my-profile-form>.input-select>div>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.1vw;
  align-self: baseline;
  position: relative;
  bottom: 3vw;
  margin-bottom: 0;
}

.my-profile-form .input-select>p.student-athlete {
  margin-bottom: 29px;
}

.my-profile-form>div>p.orange-text {
  color: #ff5c00;
}

.buttons-wrapper>a {
  text-decoration: none;
  text-align: center;
}

.buttons-wrapper>button,
.buttons-wrapper>a {
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 2vw;
  font-size: 1.1vw;
  font-weight: 600;
  font-family: 'Montserrat';
  cursor: pointer;
  padding: 0.3vw 1.3vw;
  white-space: nowrap;
  outline: none;
}

.buttons-wrapper>button.cancel {
  background-color: var(--white-color);
  border: 1px solid #ff0000;
  color: #ff0000;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 13px;
  z-index: 1;
}

.membership-plan-input-wrapper {
  position: relative;
  align-items: center;
  display: flex;
}

.my-profile-form .choice-radio>div>input {
  width: 1.5vw;
  height: 1.5vw;
  border: 0.2em solid var(--headings-blue);
  border-radius: 50%;
  margin: 0;
}

.my-profile-form .input-location,
.my-profile-form .input-select {
  position: relative;
  width: 27.5vw;
}

.my-profile-form .my-profile-form-act,
.my-profile-form .my-profile-form-sat {
  width: 27.5vw;
  margin-top: 6vw;
}

.my-profile-form .input-location>p,
.my-profile-form .input-select>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.1vw;
  align-self: baseline;
  padding-bottom: 1.5vw;
  position: relative;
  bottom: 95%;
  margin: 0;
}

.my-profile-form .input-select>p.orange-text {
  color: #ff5c00;
}

.my-profile-form input,
.my-profile-form>.my-profile-form-sat>input {
  border: 0.13vw solid var(--border-color);
  border-radius: 0.5vw;
  padding: 1vw;
  margin: 0;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: rgba(70, 70, 70, 0.8);
  width: 100%;
}

.my-profile-form input.my-profile-input-orange {
  border: 0.13vw solid #ff5c00;
}

.my-profile-form input.my-profile-input-orange::placeholder {
  color: #ff5c00;
}

.my-profile-form>div>input:invalid,
.input-location>input:invalid {
  border: 0.13vw solid red;
}

.my-profile-form>input:invalid::placeholder,
.input-location>input:invalid::placeholder {
  color: red;
}

.my-profile-form .input-location>input:focus:valid+p {
  color: black;
}

.my-profile-form .input-location>input:invalid+p {
  color: red;
}

.my-profile-form .choice-radio>div>input[type='radio']::before {
  width: 1.3vw;
  height: 1.3vw;
  background: var(--headings-blue);
}

.choice-radio>div {
  display: flex;
  align-items: center;
}

.choice-radio>div>p {
  font-size: 1.1vw;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 1.3vw;
}

.edit-button>button,
.edit-button>a {
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 2.6vw;
  font-size: 1.1vw;
  font-weight: 600;
  font-family: 'Montserrat';
  padding: 1.3vw 0;
  width: 23vw;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.edit-button {
  display: flex;
  justify-content: space-between;
  margin: 4.2vw 14.6vw 3.4vw 12.5vw;
}

.canceled-subscription-message {
  margin: 0 9vw;
  margin-top: 15px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: clamp(0.75rem, 1vw + 0.1rem, 2rem);
}

.membership-plan-input-wrapper>input {
  position: relative;
  border: 0.13vw solid var(--border-color);
  border-radius: 0.5vw;
  padding: 1.1vw 1.6vw;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  color: rgba(70, 70, 70, 0.8);
  width: 100%;
  cursor: default;
}

.membership-plan {
  margin: 0 8vw;
  position: relative;
}

.membership-plan>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.1vw;
  align-self: baseline;
  margin-top: 0;
}

.my-profile-form>.selected-colleges-list {
  margin-top: -1rem;
  margin-bottom: 0;
  width: 100%;
}

.selected-colleges-list>.selected-colleges-list {
  width: 50%;
  margin-bottom: 0;
}

@media only screen and (min-width: 1520px) {
  .selected-colleges-list {
    margin-top: -5rem;
  }
}

@media only screen and (max-width: 900px) {

  .my-profile-form>p,
  .my-profile-form>div>p {
    margin-top: 0.4vw;
  }

  .my-profile-form>.my-profile-form-sat {
    margin-top: 1.2vw;
  }

  .my-profile-form .input-location>p,
  .my-profile-form .input-select>p,
  .my-profile-form>p,
  .my-profile-form>div>p,
  .membership-plan>p {
    font-size: 11px;
  }

  .my-profile-form>div>p.act-score {
    margin-top: 1.6vw;
  }

  .my-profile-form input,
  .my-profile-form>.my-profile-form-sat>input,
  .choice-radio>div>p,
  .membership-plan-input-wrapper>input,
  .buttons-wrapper>button,
  .buttons-wrapper>a,
  .edit-button>button {
    font-size: 10px;
  }

  .my-profile-body>img {
    height: 99%;
  }

  .my-profile-form .selected-colleges-list span {
    white-space: break-spaces;
    font-size: 9px;
  }

  .buttons-wrapper>button,
  .buttons-wrapper>a {
    bottom: 0;
  }
}

@media only screen and (max-width: 744px) {
  .edit-button>a {
    font-size: 10px;
  }
}

@media only screen and (max-width: 520px) {
  .choice-radio {
    margin-bottom: 7.7vw;
  }

  .margin-top-mobile {
    margin-top: 40px;
  }

  .my-profile-body {
    flex-wrap: wrap;
    padding-top: 15vw;
    justify-content: center;
  }

  .my-profile-body>.my-profile-form {
    gap: 0;
  }

  .my-profile-form .selected-colleges-list {
    margin-bottom: 0.5rem;
    margin-top: -3vw;
    width: auto;
  }

  .my-profile-main {
    padding: 0;
  }

  .my-profile-form {
    display: flex;
    flex-direction: column;
  }

  .my-profile-wrapper {
    width: 100%;
  }

  .edit-button>button {
    display: none;
  }

  .my-profile-form .input-location,
  .my-profile-form .input-select {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .edit-button .my-profile-mobile-button {
    display: block;
    font-size: 4.6vw;
    padding: 3.6vw;
    width: 80.8vw;
    border-radius: 10.3vw;
  }

  .header-profile {
    background-color: white;
  }

  .header-profile>h1 {
    font-size: 4.6vw;
    padding: 5.6vw 0 0 0;
    font-weight: 600;
  }

  .header-profile .wave {
    top: -4vw;
  }

  .my-profile-body .my-profile-form {
    padding: 0;
    width: 89.5vw;
  }

  .my-profile-body .my-profile-form>div>a {
    color: #030054;
  }

  .my-profile-body .my-profile-form .input-location>p,
  .my-profile-body .my-profile-form .input-select>p {
    font-size: 4.1vw;
    font-weight: 600;
    padding-bottom: 4.1vw;
  }

  .my-profile-body .my-profile-form>p,
  .my-profile-body .my-profile-form>.input-select>div>p {
    font-weight: 600;
    font-size: 4.1vw;
    padding: 0;
    margin: 10vw 0 3vw 0;
    bottom: 17vw;
  }

  .my-profile-body .my-profile-form>.input-select>.my-profile-form-act>p,
  .my-profile-body .my-profile-form>.input-select>.my-profile-form-sat>p {
    bottom: 0;
  }

  .my-profile-form .my-profile-form-act>input,
  .my-profile-form>.my-profile-form-sat>input,
  .input-select>.my-profile-form-sat>input,
  .my-profile-form .input-select>input,
  .my-profile-form .input-location>input,
  .membership-plan-input-wrapper>input,
  .my-profile-form #psatScoreInput {
    font-size: 3.6vw;
    border-radius: 1.3vw;
    border: 0.3vw solid var(--border-color);
    padding: 3.8vw 5.1vw;
    margin-bottom: 7.7vw;
    width: 100%;
    box-sizing: border-box;
    order: 2;
  }

  .my-profile-form .my-profile-form-act,
  .my-profile-form .my-profile-form-sat {
    width: auto;
    margin-top: -4rem;
  }

  .membership-plan-input-wrapper>input {
    padding: 6vw 5.1vw;
  }

  .buttons-wrapper {
    gap: 5px;
  }

  .my-profile-form .my-profile-form-act>input,
  .my-profile-form .my-profile-form-sat>input {
    margin-bottom: 12vw;
  }

  .membership-plan-input-wrapper>input {
    margin-bottom: 0;
  }

  .my-profile-body .my-profile-form .input-select>img {
    width: 4vw;
    right: 6vw;
    top: 50%;
  }

  .my-profile-body .my-profile-form .input-select .location-img {
    top: 45%;
  }

  .choice-radio>div>input {
    width: 5vw;
    height: 5vw;
  }

  .choice-radio>div>input[type='radio']::before {
    width: 3vw;
    height: 3vw;
  }

  .choice-radio>div>p {
    font-size: 3.6vw;
    margin-left: 4vw;
  }

  .membership-plan>p {
    font-size: 4.1vw;
    padding-bottom: 2.5vw;
    margin-bottom: 0;
  }

  .my-profile-wrapper .membership-plan {
    margin: 7.7vw 5.1vw;
  }

  .buttons-wrapper {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .buttons-wrapper>button,
  .buttons-wrapper>a {
    padding: 1vw 5.1vw;
    border-radius: 7.7vw;
    font-size: 3.6vw;
    font-weight: 400;
    bottom: 15%;
    right: 2.6vw;
  }

  .choice-radio {
    margin-top: -15vw;
    width: 60%;
  }

  .edit-button {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 9.2vw 0 12.6vw 0;
  }

  .my-profile-body>img {
    display: none;
  }

  .my-profile-form>div>p {
    font-size: 4.1vw;
  }

  .edit-button .my-profile-mobile-button,
  .edit-button>a.my-profile-mobile-button-billing {
    display: block;
    font-size: 4.6vw;
    border-radius: 10.3vw;
    width: 85%;
    box-sizing: border-box;
    padding: 3.6vw;
    margin: 0;
  }

  .edit-button>a.my-profile-mobile-button-billing {
    margin-bottom: 10px;
  }

  .my-profile-form .choice-radio>div>input {
    width: 25px;
    height: 25px;
  }

  .my-profile-form .choice-radio>div>input[type='radio']::before {
    width: 16px;
    height: 16px;
  }

  .act-select-container,
  .sat-select-container {
    margin-top: 10vw;
  }

  #fullNameField {
    order: 1;
  }

  #emailField {
    order: 2;
  }

  #genderField {
    order: 3;
  }

  #roleField {
    order: 4;
  }

  #locationField {
    order: 5;
  }

  #schoolField {
    order: 6;
  }

  #budgetField {
    order: 7;
  }

  #contributionField {
    order: 8;
  }

  #majorsField {
    order: 9;
  }

  #selectedMajorsField {
    order: 10;
  }

  #incomeField {
    order: 11;
  }

  #graduationField {
    order: 12;
  }

  #gpaField {
    order: 13;
  }

  #actField {
    order: 14;
  }

  #satField {
    order: 15;
  }

  #athleteField {
    order: 16;
  }

  #psatScoreField {
    order: 17;
  }

  #psatScoreField p, #psatScoreField .act-select-container {
    bottom: 0;
    margin: 0;
  }

  #psatScoreInput {
    margin-bottom: 0 !important;
  }
}