.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    max-width: 33.75rem;
    padding: 2.5rem 5.5rem;
    background: var(--white-color);
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}

.lockIcon {
    width: 1.5rem;
    height: 2rem;
}

.title {
    font-size: 1.5rem;
    margin: 0 0 .2rem 0;
}

.subtitle,
.text {
    font-size: 1.25rem;
}

.subtitle {
    margin: 0.5rem 0;
    color: var(--headings-blue);
}

.text {
    margin: 0 0 1rem 0;
    color: #403E59;
}

.navButton {
    background: var(--color-primary);
    border: none;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1.25rem;
    outline: none;
    padding: 0.375rem 3.5rem 0.375rem 3.375rem;
    text-align: center;
    text-decoration: none;
}


@media only screen and (max-width: 520px) {
    .container {
        padding: 1.5rem 3.5rem;
    }
}