.modal_content {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 600px;
  min-width: 300px;
  width: calc(100vw - 30px);
  padding: 5%;
  box-sizing: border-box;
}

.modal_content hr {
  margin: 0;
}

.modal_content > img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 10px;
  width: 10px;
}

.text {
  font-family: 'Source Sans 3', sans-serif;
}

.modal_header {
  font-family: 'Source Sans 3', sans-serif;
  margin: 0;
  padding: 1.5vw 0;
  color: var(--headings-blue);
  font-size: 26px;
}

.modal_body {
  font-weight: 600;
  font-size: 1.1vw;
  color: var(--headings-blue);
  position: relative;
  margin-top: 15px;
}

.modal_body > img {
  position: absolute;
  z-index: 1;
  left: 5%;
  bottom: 22%;
  cursor: pointer;
}

.modal_body > p {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 18px;
}

.modal_footer {
  margin-top: 8%;
  display: flex;
  justify-content: center;
  gap: 40px
}

.type-subscription-radio-button {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.radio-option-block {
  gap: 10px;
  display: flex;
  align-items: center;
}

.radio-option-block > .radio-option:checked, .radio-option-block > .radio-option {
  height: 26.67px;
  width: 26.67px;
}

.radio-option-block > .radio-option {
  border-color: var(--color-primary);
}

.radio-package-option-block > .package-radio-option {
  border-color: var(--headings-blue);
  width: 20px;
  height: 20px;
}

.radio-package-option-block > .package-radio-option:checked {
  border-color: var(--headings-blue);
  width: 20px;
  height: 20px;
}

.radio-package-option-block > .package-radio-option::before {
  background: var(--headings-blue);
  width: 10px;
  height: 10px;
}

.type-subscription-package-radio-button {
  display: flex;
  gap: 14px;
  flex-direction: column;
}

.radio-package-option-block {
  display: flex;
  gap: 10px;
}

.package-option-text {
  margin: 0;
  font-weight: 400;
}

.radio-option-block > .radio-option::before {
  height: 14px;
  width: 14px;
}

.option-text {
  font-weight: 400;
  font-size: 24px;
}

.option-selected {
  color: var(--color-primary);
}

.package-option-text {
  font-weight: 600;
  font-size: 20px;
}

.package-option-selected {
  color: var(--headings-blue);
}

.checked-block {
  padding-top: 20px;
  gap: 0;
}

.checked-block > .checked-input {
  background: #FFFFFF;
}

.checked-block > .checked-input:checked {
  border: 1.5px solid var(--headings-blue);
}

.checked-block > .checked-input::before {
  box-shadow: inset 1em 1em var(--headings-blue);
}

.checked-block > .checked-block-label {
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
}

.checked-block > .checked-block-label.active {
  font-size: 16px;
  font-weight: 600;
  color: var(--headings-blue);
}

.coupon-input {
  width: 67%;
  font-size: 16px;
  font-weight: 400;
  padding: 3% 0 3% 3%;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-family: 'Source Sans 3', sans-serif;
}

.calendar-input {
  width: 60%;
  font-size: 16px;
  font-weight: 400;
  padding: 3% 0 3% 10%;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-family: 'Source Sans 3', sans-serif;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.custom_datepicker {
  font-size: 16px;
  font-weight: 400;
  padding: 3% 0 3% 0%;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  width: 70%;
  font-family: 'Source Sans 3', sans-serif;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  width: 100%
}

.react-datepicker__month-container > .react-datepicker__header {
  background-color: #ffffff;
}

.react-datepicker-popper {
  width: 70%;
}

.react_datepicker-wrapper {
  position: relative;
}

.custom_datepicker:before {
  content: '';
  height: 1vw;
  width: 1vw;
  background-image: url('../../images/calendar-line-icon.svg');
}

.react-calendar__tile {
  border: none;
  background: none;
  color: #464646;
  font-family: 'Source Sans 3';
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
}

.react-calendar__tile--active {
  color: white;
  border-radius: 50%;
  background: #7E106E;
}

.react-calendar__month-view__weekdays__weekday, .react-calendar__month-view__weekdays__weekday--weekend {
  color: #A7A7A7;
  font-family: 'Source Sans 3';
  font-size: 20px;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday > abbr, .react-calendar__month-view__weekdays__weekday--weekend > abbr {
  text-decoration: none;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-calendar__navigation {
  position: relative;
  padding: 18px;
  padding-bottom: 30px;
}

.react-calendar__navigation__arrow {
  border: none;
  background: #F2F2F2;
  width: 32px;
  height: 32px;
  color: #4F4F4F;
  border-radius: 50%;
  font-family: 'Source Sans 3';
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
  line-height: 0px;
}

.react-calendar__navigation__label {
  border: none;
  background: none;
  margin-right: 10px;
  color: #7E106E;
  font-family: 'Source Sans 3';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.modal_footer > .add-sub-buttons {
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  min-width: 180px;
}

.modal_footer > .add-sub-buttons.cancel {
  color: var(--headings-blue);
  background-color: #ffffff;
  border: 1px solid var(--dark-grey);
}

.modal_footer > .add-sub-buttons.ok {
  color: #ffffff;
  background: var(--color-primary);
  border: none;
}

@media only screen and (max-width: 844px) {
  .modal_content {
    width: 580px;
    padding: 20px;
  }

  .modal_body > p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px) {
  .modal_content {
    width: 480px;
  }
}

@media only screen and (max-width: 540px) {
  .modal_content {
    width: 385px;
  }

  .modal_footer > .add-sub-buttons {
    font-size: 20px;
    width: 35%;
    height: 36px;
    min-width: unset;
  }
}


@media only screen and (max-width: 390px) {
  .modal_content {
    width: 340px;
  }
}