.header-container {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 #0003, 0 6px 20px 0 #00000030;
  display: flex;
  font-family: Inter !important;
  height: 50px;
  justify-content: center;
  z-index: 4;
  position: relative;
}

.dropdown-menu{
  z-index: 9999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 50px;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

/*Dropdown*/
/*New styles*/
.header-item-container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0;
  width: fit-content;
  height: 90%;
}

.header-item-container:hover, .header-item-container.active {
  margin-top: 4px;
  border-bottom: 4px solid rgba(126, 16, 110, 0.40);
}

.header-item-container:hover>.header-item-link, .header-item-container.active>.header-item-link {
  color: #7e106d !important;
}

.header-item-container:hover>.dropdown-bg-none>button, .header-item-container.active>.dropdown-bg-none>button {
  background-color: transparent;
  color: #7e106d !important;
}

.dropdown-custom-header.dropdown, .dropdown-custom-header>.dropdown-toggle {
  height: 100%;
}

.dropdown-custom-header>.dropdown-menu>.dropdown-item {
  padding: 1rem;
  font-size: 14px;
}

.menu-mobile .dropdown-custom-header>.dropdown-menu>.dropdown-item {
  padding: 10px 20px;
  font-size: 14px;
}

.header.modal-opened {
  z-index: 1100;
}

.header>span {
  display: none;
}

.profile-menu-mobile-registered {
  display: none;
}


.header-right-block-upgrade-account {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 15.5vw;
  cursor: pointer;
  background: #7e106e;
  border-radius: 20px;
  padding: 0.5vw;
  margin-right: 1vw;
}

.header-right-block-upgrade-account-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  color: #fff;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.header-right-block-upgrade-account-img {
  width: 1.4vw;
  height: 1.4vw;
}

.header-right-block>.header-right-block-upgrade-account>.header-right-block-upgrade-account-img {
  padding-right: 0;
}

.header-right-block-img {
  margin-left: 8px;
  width: 2.7vw;
}

.header-right-block-get-started {
  position: relative;
}

.header-right-block-get-started-and-info-hub {
  position: absolute;
  top: 90%;
  right: 15%;
  padding: 27px 49px;
  background: #fff;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 3;

  display: flex;
  flex-direction: row;
}

.header-right-block>div {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}

.header-right-block>div>img {
  padding-right: 2vw;
}

.header-right-block-arrow-open {
  transform: rotateX(180deg);
}

.header-right-block>.header-right-block-login {
  padding-right: 25px;
  color: var(--primary-typography);
  cursor: pointer;
  font-size: 1.3vw;
  font-weight: 500;
  line-height: 2.2vw;
  white-space: nowrap;
}

.header-right-block-get-started-dropdown-pc>ul,
.header-right-block-info-hub-dropdown-pc>ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 34px;
  position: relative;
  margin: 16px 0 0;
  padding: 0;
}

.header-right-block-get-started-dropdown-pc>ul>li,
.header-right-block-info-hub-dropdown-pc>ul>li {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 35%;
}

.header-right-block-get-started-dropdown-pc>ul>hr,
.header-right-block-info-hub-dropdown-pc>ul>hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.header-right-block-get-started-dropdown-pc>ul>li>h6,
.header-right-block-info-hub-dropdown-pc>ul>li>h6 {
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 1.9vw;
  white-space: pre-wrap;
  margin: 0 0 4px;
}

.header-right-block-get-started-dropdown-pc>ul>li>p,
.header-right-block-info-hub-dropdown-pc>ul>li>p {
  margin: 0;
}

.header-right-block-get-started-dropdown-pc>ul>li>p>a,
.header-right-block-info-hub-dropdown-pc>ul>li>p>a {
  text-decoration: none;
  color: #2f2d77;
  font-weight: 500;
  font-size: 1.2vw;
  margin: 0;
  white-space: inherit;
  transition: color 0.1s;
  line-height: inherit;
}

.header-right-block-get-started-dropdown-pc>ul>li>p>a:hover,
.header-right-block-info-hub-dropdown-pc>ul>li>p>a:hover {
  color: #00b4d8;
}

.profile-arrow {
  width: 12px;
  object-fit: contain;
}

.menu-container {
  display: none;
}

.header .college-insights {
  border-left: 1px solid #000;
  font-family: 'Inter';
  font-weight: 700;
  color: black;
  font-size: 1rem;
  padding: 0 2rem 0 1rem;
}


.link-to-login {
  text-decoration: none;
  color: var(--primary-typography);
}


.profile-menu *,
.profile-menu-submenu * {
  box-sizing: border-box;
}

.profile-menu>div.nav-blue,
.profile-menu>a.nav-blue {
  color: #00B4D8;
}

.profile-menu>div.nav-red,
.profile-menu>a.nav-red {
  color: #D33841;
}

.profile-menu>a:last-child:hover,
.profile-menu>div:last-child:hover {
  background: rgba(126, 16, 110, 0.1);
}

.profile-menu>a:hover,
.profile-menu>div:hover {
  background: rgba(126, 16, 110, 0.1);
}

.profile-menu>div:first-child:hover,
.profile-menu>a:first-child:hover {
  background: rgba(126, 16, 110, 0.1);
}

.profile-menu>div>img,
.profile-menu>a>img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 15px;
}

.profile-menu>div>img.profile-menu-arrow,
.profile-menu>a>img.profile-menu-arrow {
  transform: rotate(-90deg);
  margin: 0 0 0 10px;
  width: 14px;
}

.profile-menu-submenu {
  box-sizing: border-box;
  position: absolute;
  z-index: 1000;
  background: #FFFFFF;
  padding: 10px;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  top: 100%;
  margin-top: 15px;
  right: -3vw;
  max-width: 355px;
  width: max-content;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: hidden;
}

.profile-menu-submenu.active {
  opacity: 1;
  visibility: visible;
}

.profile-menu-submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.submenu-wrapper.active {
  background: rgba(126, 16, 110, 0.1);
}

.profile-menu-submenu ul li:nth-child(1) {
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 35%;
}

.profile-menu-submenu ul li:nth-child(2) {
  padding-left: 20px;
  width: 65%;
}

.profile-menu-submenu h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px;
  line-height: 28px;
  color: var(--headings-blue);
}

.profile-menu-submenu p {
  margin: 0;
  line-height: 1;
}

.profile-menu-submenu li p:not(:last-of-type) {
  margin-bottom: 12px;
}

.profile-menu-submenu p a {
  color: var(--headings-blue);
  font-size: 14px;
  line-height: 1;
}

.profile-button-wrapper {
  position: relative;
}

.look-up {
  transform: rotate(180deg);
}

.look-down {
  transform: rotate(0);
}

.get-started {
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 1.3vw;
  white-space: nowrap;
  color: #ffffff;
  background: #7e106e;
  border: none;
  border-radius: 40px;
  font-family: "Source Sans 3", sans-serif;
  cursor: pointer;
  width: 8.5vw;
  height: 2.8vw;
}

.header-right-block-info-hub-open>span {
  color: #00b4d8;
}

.header-right-block {
  font-family: 'Source Serif 3', sans-serif;
}

.header-right-block>div>.header-right-block-info-title {
  cursor: pointer;
  color: var(--primary-typography);
  cursor: pointer;
  font-size: 1.3vw;
  font-weight: 500;
  font-family: 'Source Serif 3', sans-serif;
  padding-right: 5px;
  white-space: nowrap;
  border: 0 transparent;
  background: transparent;
}

.header-right-block>div>.header-right-block-info-title-open {
  cursor: pointer;
  color: #00b4d8;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  padding-right: 5px;
  white-space: nowrap;
  border: 0 transparent;
  background: transparent;
}

@media only screen and (min-width: 2000px) {
  .header-right-block>div>img {
    width: 1vw;
  }
}

@media only screen and (max-width: 900px) {
  .menu {
    width: auto;
    height: 20px;
  }

  .header {
    box-sizing: border-box;
  }

  .road2college {
    width: auto;
    height: 30px;
    padding: 0 1rem 0 0;
    margin-bottom: 0;
  }

  .dropdown-custom-header>.dropdown-menu {
    transform: unset !important;
  }

  .dropdown-custom-header>.dropdown-menu.show {
    transform: unset !important;
    inset: unset !important;
    right: -26px !important;
    top: 38px !important;
  }

  .profile-menu-mobile-registered {
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    width: 100%;
    padding: 0 20px;
    z-index: 13;
    top: 50px;
    right: 0;
    display: flex;
    flex-direction: column;
    cursor: initial;
  }

  .profile-menu-mobile-registered>.profile-menu-mobile-registered-container-btn {
    padding: 0;
  }

  .profile-menu-mobile-registered-get-started,.profile-menu-mobile-registered-login{
    padding: 10px 0;
    margin: 10px 0;
  }

  .profile-menu-mobile-registered-get-started,
  .profile-menu-mobile-registered-login {
    width: 100%;
    color: white;
    height: 50px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 2rem;
    text-transform: uppercase;
  }

  .menu-container {
    display: block;
  }


  .list-header-buttons>.primary-button-list-second {
    padding: 0.58vw 1.7vw;
    font-size: 2.15vw;
  }

  .header-right-block-upgrade-account {
    display: none;
  }

  .header-right-block-img {
    display: none;
  }

  .header>span {
    display: block;
  }



  .header-right-block>div,
  .header-right-block>span,
  .my-lists-header,
  .profile-button-wrapper {
    display: none;
  }



  .profile-menu-mobile-registered * {
    box-sizing: border-box;
  }

  .profile-menu-mobile-registered>img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .profile-menu-mobile-registered .header-mobile-question {
    margin-left: auto;
    display: flex;
  }

  .profile-menu-mobile-registered .header-mobile-item {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-primary);
    position: relative;
    padding: 5px 0 16px;
  }

  .profile-menu-mobile-registered .header-mobile-item-submenu>div {
    display: flex;
    align-items: center;
  }

  .profile-menu-mobile-registered .header-mobile-item:not(:last-child) {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header-mobile-item-submenu>span {
    position: absolute;
    width: 15px;
    height: 15px;
    transition: 0.3s ease-in-out all;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }

  .header-mobile-item-submenu.active>span::after {
    transform: none;
  }

  .header-mobile-item-submenu>span::before,
  .header-mobile-item-submenu>span::after {
    transition: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    border-radius: 2px;
  }

  .header-mobile-item-submenu>span::after {
    transform: rotate(90deg);
  }

  .profile-menu-mobile-registered .header-mobile-item.nav-blue {
    color: #00B4D8;
  }

  .profile-menu-mobile-registered .header-mobile-item.nav-red {
    color: #D33841;
  }

  .profile-menu-mobile-registered>a>.profile-menu-mobile-registered-arrowDown,
  .profile-menu-mobile-registered>div>.profile-menu-mobile-registered-arrowDown {
    position: absolute;
    right: 0%;
  }

  .profile-menu-mobile-registered>a>.profile-menu-mobile-registered-arrowUp,
  .profile-menu-mobile-registered>div>.profile-menu-mobile-registered-arrowUp {
    position: absolute;
    right: 0%;
    rotate: 180deg;
  }



  .profile-menu-mobile-registered-container-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    box-sizing: border-box;
  }

  .profile-menu-mobile-registered-get-started {
    background-color: #7e106e;
    border: 1px solid #7e106e;
  }

  .profile-menu-mobile-registered-login {
    background-color: #030054;
    border: 1px solid #030054;
  }

  .profile-menu-mobile-registered .header-mobile-item img {
    width: 18px;
    object-fit: contain;
    object-position: center left;
    margin-right: 12px;
  }

  .profile-menu-mobile-registered>a>div>img,
  .profile-menu-mobile-registered>div>div>img {
    transform: rotateX(180deg);
  }

  .profile-menu-mobile-registered>a>div>.profile-menu-mobile-registered-container-dropdown-open,
  .profile-menu-mobile-registered>div>div>.profile-menu-mobile-registered-container-dropdown-open {
    transform: rotateX(0deg);
  }

  .profile-menu-mobile-registered>.profile-menu-mobile-registered-container-dropdown {
    display: flex;
    flex-direction: column;
    align-items: inherit;
  }

  .profile-menu-mobile-registered-container-dropdown>div {
    display: flex;
    justify-content: space-between;
  }

  .profile-menu-mobile-registered-container-dropdown .header-mobile-subitem {
    list-style: none;
    gap: 40px;
    position: relative;
    margin: 16px 0 0;
    padding: 0;
  }

  .profile-menu-mobile-registered>ul {
    display: flex;
    justify-content: space-evenly;
    gap: 1vw;
    list-style: none;
    padding: 0;
  }

  .profile-menu-mobile-registered>ul.profile-menu-mobile-registered-get-started-dropdown-auth {
    gap: 10vw;
  }

  .profile-menu-mobile-registered>ul>hr {
    margin: 0;
  }

  .profile-menu-mobile-registered>ul>li>h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    white-space: pre-wrap;
    margin: 0 0 4px;
  }

  .profile-menu-mobile-registered>ul>li>p>a {
    text-decoration: none;
    white-space: pre-wrap;
    color: #2f2d77;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .header-mobile-subitem>li {
    position: relative;
    padding-top: 8px;
  }

  .header-mobile-subitem>li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 85%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .header-mobile-subitem>li:not(:last-of-type) {
    padding-bottom: 12px;
  }

  .header-mobile-subitem>li>div>p,
  .header-mobile-subitem>li>div>p>a {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    color: #2F2D77;
  }

  .header-mobile-subitem h6 {
    position: relative;
    display: flex;
    align-items: center;
  }

  .header-mobile-subitem h6>span {
    position: absolute;
    width: 15px;
    height: 15px;
    transition: 0.3s ease-in-out all;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }

  .header-mobile-subitem h6.active>span::after {
    transform: none;
  }

  .header-mobile-subitem h6>span::before,
  .header-mobile-subitem h6>span::after {
    transition: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #2F2D77;
    border-radius: 2px;
  }

  .header-mobile-subitem h6>span::after {
    transform: rotate(90deg);
  }

  .header-mobile-subitem>li>div>p>a>b {
    font-weight: 600;
  }

  .header-mobile-subitem>li>div>p:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li>h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    white-space: break-spaces;
    line-height: 140%;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li>p {
    margin: 0;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li>p>a {
    text-decoration: none;
    color: #2f2d77;
    font-weight: 400;
    font-size: 1.8vw;
    line-height: 20px;
    margin: 0;
  }

  .profile-menu-mobile-registered-log {
    width: 270px;
  }

  .get-started {
    display: none;
  }

  .profile-menu-mobile-registered-log>ul {
    position: relative;
  }

  .profile-menu-mobile-registered-log>ul>li {
    width: 33%;
  }

  .profile-menu-mobile-registered-log>ul.profile-menu-mobile-registered-info-hub-dropdown-auth {
    gap: 10vw;
  }

  .profile-menu-mobile-registered-log>ul>hr {
    position: absolute;
    height: 400px;
  }

  .header-right-block-upgrade-account {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .header span {
    font-size: 10px;
  }

  .profile-button {
    font-size: 9px;
  }

  .profile-button>img {
    width: 6px;
  }

  .profile-menu-mobile-registered-container-dropdown>ul {
    gap: 20px;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li>p>a {
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: 520px) {
  .header-container, .nav-component-container {
    padding: 0;
  }

  .header-admin {
    padding-right: 10px;
  }

  .header-right-block>span,
  .my-lists-header,
  .profile-button-wrapper {
    display: none;
  }

  .profile-menu-mobile-registered .header-mobile-question {
    margin-right: 20px;
  }

  .header-mobile-subitem>li>div>p,
  .header-mobile-subitem>li>div>p>a {
    white-space: break-spaces;
  }

  .profile-menu-mobile-registered-container-dropdown>ul>li {
    gap: 0;
  }

  .profile-menu-mobile-registered>img {
    padding: 5% 0 0;
  }

  .list-header-buttons>.primary-button-list-second {
    font-size: 3vw;
    padding: 2.6vw 3.3vw;
  }

  .dropdown-custom-header>.dropdown-menu {
    transform: translate(-160px, 12px) !important;
    inset: unset !important;
  }
}