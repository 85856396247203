.free-plan {
  position: relative;
  width: 16.5vw;
}
.popular-plan {
  position: relative;
  width: 18.6vw;
}
.plus-plan {
  position: relative;
  width: 21.1vw;
}
.free-plan > h6,
.popular-plan > h6,
.plus-plan > h6 {
  margin: 1.5vw 0 0 0;
  color: var(--color-primary);
  font-size: 1.2vw;
  text-transform: uppercase;
}
.free-plan,
.popular-plan,
.plus-pla {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.membership-plans > div > .plan-price {
  margin: 0 0 2.4vw 0;
  font-size: 1.1vw;
}
.free-plan > div > div,
.popular-plan > div > div,
.plus-plan > div > div {
  display: flex;
  width: 90%;
}
.free-plan > div,
.popular-plan > div,
.plus-plan > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.free-plan > div > div > img,
.popular-plan > div > div > img,
.plus-plan > div > div > img {
  margin-right: 0.33vw;
  width: 0.9vw;
}
.free-plan > button,
.popular-plan > button,
.plus-plan > button {
  padding: 1vw 2.6vw;
  font-size: 1.1vw;
  margin-top: 2.8vw;
}
.popular-plan > button {
  margin-top: 3.4vw;
}
.free-plan > .unsubscribe,
.popular-plan > .unsubscribe,
.plus-plan > .unsubscribe {
  background-color: white;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.popular-plan > span,
.plus-plan > span,
.free-plan > span {
  color: white;
  position: absolute;
  background: #00b4d8;
  padding: 0.2vw 0.6vw;
  border-radius: 0.3vw;
  top: -0.7vw;
  font-size: 1.1vw;
}
.free-plan > span {
  background: #23ebb2;
}
.popular-plan > div > hr,
.free-plan > div > hr,
.plus-plan > div > hr {
  width: 90%;
  border: none;
  border-bottom: 1px solid rgba(3, 0, 84, 0.5);
  margin: 0.7vw 0;
}
.free-plan > div > hr {
  margin: 1vw 0;
}
@media only screen and (min-width: 2000px) {
  .plus-plan > .unsubscribe {
    border: 4px solid var(--color-primary);
  }
}
@media only screen and (max-width: 800px) {
  .popular-plan > span,
  .plus-plan > span,
  .free-plan > span,
  .membership-plans > div > .plan-price,
  .membership-plans > div > div > div > p,
  .membership-plans > div > p,
  .free-plan > button,
  .popular-plan > button,
  .plus-plan > button {
    font-size: 8px;
  }
  .free-plan > h6,
  .popular-plan > h6,
  .plus-plan > h6 {
    font-size: 9px;
  }
}
@media only screen and (max-width: 750px) {
  .popular-plan {
    width: 20vw;
  }
  .free-plan {
    width: 18vw;
  }
  .plus-plan {
    width: 23.1vw;
  }
}
@media only screen and (max-width: 650px) {
  .popular-plan {
    width: 24vw;
  }
  .free-plan {
    width: 21.5vw;
  }
  .plus-plan {
    width: 27.2vw;
  }
}
@media only screen and (max-width: 520px) {
  .popular-plan > span,
  .plus-plan > span,
  .free-plan > span {
    font-size: 3.6vw;
    padding: 1vw 1.5vw;
    white-space: break-spaces;
    text-align: center;
    max-width: 80%;
    line-height: 4.1vw;
    top: -7vw;
    border-radius: 1vw;
  }
  .free-plan > span {
    top: -3vw;
  }
  .plus-plan > span {
    top: -5vw;
  }
  .membership-plans > div {
    min-width: 59.7vw;
    margin: 0 3.8vw;
  }
  .membership-plans > .free-plan {
    min-width: 50.8vw;
    margin-top: 6vw;
  }
  .membership-plans > .plus-plan {
    min-width: 68vw;
    margin-top: 6vw;
  }
  .free-plan > h6,
  .popular-plan > h6,
  .plus-plan > h6 {
    font-size: 4.1vw;
    margin-top: 6.4vw;
  }
  .membership-plans > div > .plan-price {
    font-size: 3.6vw;
    margin-top: 1.3vw;
  }
  .membership-plans > div > .dashed-hr {
    margin-bottom: 4.4vw;
  }
  .popular-plan > div > hr,
  .free-plan > div > hr,
  .plus-plan > div > hr {
    margin: 2.8vw 0;
  }
  .membership-plans > div > div > div > p {
    font-size: 3.6vw;
  }
  .free-plan > div > div > img,
  .popular-plan > div > div > img,
  .plus-plan > div > div > img {
    width: 4.9vw;
    margin-right: 2.3vw;
  }
  .free-plan > button,
  .popular-plan > button,
  .plus-plan > button {
    font-size: 3vw;
    padding: 2.1vw 5.4vw;
    margin-top: 3.1vw;
  }
}
