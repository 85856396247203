.marketing-page-table, .marketing-page-th, .marketing-page-td {
    border-collapse: collapse;
    border: 1px solid #c2c2c2;
}

.marketing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 70px;
    background-color: #f4f3f3;
}

.marketing-page-container {
    width: 100%;
    padding: 1.9vw 5.2vw 2vw;
    background-color: #fff;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
}

.marketing-page-title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4vw;
    color: #030054;
    margin: 0 0 1vw;
}

.marketing-page-upgrade-btn {
    font-weight: 600;
    font-size: 1.2vw;
    text-align: center;
    color: #fff;
    background: #7e106e;
    border: 1px solid #7e106e;
    border-radius: 40px;
    margin-top: 1.11vw;
    padding: 15px 32.5px;
    max-width: 300px;
    align-self: end;
    cursor: pointer;
    text-decoration: none;
}

.marketing-page-tr > td {
    padding: 0.4vw 0;
}

.marketing-page-tr > .marketing-page-tr-value {
    font-weight: 600;
    font-size: 0.97vw;
    color: #464646;
    padding-left: 25px;
}

.marketing-page-tr > .marketing-page-td-text {
    font-weight: 600;
    font-size: 0.97vw;
    color: #030054;
}

.marketing-page-tr > th {
    line-height: 5px;
    font-weight: 600;
    font-size: 1.25vw;
    color: #fff;
    background: #030054;
    padding: 0 6vw;
}

.marketing-page-tr > th:first-child {
    background-color: #fff;
}

.marketing-page-tr > th:last-child {
    background-color: #7e106e;
    padding: 0.97vw 4.5vw;
}

.marketing-page-tr > td {
    font-weight: 600;
    font-size: 0.97vw;
    color: #464646;
}
@media only screen and (max-width: 520px) {
    .marketing-page-tr > .marketing-page-tr-value {
        font-size: 1.7vw;
        padding-left: 3px;
    }

    .marketing-page-tr > th {
        font-size: 2.3vw;
    }

    .marketing-page-title {
        font-size: 2.4vw;
    }

    .marketing-page-tr > .marketing-page-td-text {
        font-weight: 600;
        font-size: 1.97vw;
        color: #030054;
    }

    .marketing-page-upgrade-btn {
        font-size: 10px;
    }

    .marketing-page {
        padding: 20px 30px;
    }
}
@media only screen and (max-width: 391px) {
    .marketing-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 10px;
        background-color: #f4f3f3;
    }

    .marketing-page-container {
        padding: 1.9vw 3vw 2vw;
    }

    .marketing-page-title {
        margin: 3.45vw 0 3.45vw;
    }

    .marketing-page-tr > th {
        font-size: 3vw;
    }

    .marketing-page-tr > .marketing-page-tr-value,
    .marketing-page-tr > .marketing-page-td-text {
        font-size: 2.5vw;
    }

    .marketing-page-title {
        font-size: 3.65vw;
    }

    .marketing-page-upgrade-btn {
        font-size: 3vw;
        margin: 3vw 0 2vw;
        padding: 12px 32.5px;
    }

    .marketing-page-tr > .marketing-page-td-text {
        white-space: nowrap;
    }

    .marketing-page-tr > .marketing-page-tr-value {
        padding-left: 5px;
    }
}