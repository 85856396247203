.admin-users-wrapper {
  background-color: #f1f1f1;
  box-sizing: border-box;
  min-height: 90vh;
}

.admin-users-wrapper * {
  box-sizing: border-box;
}

.admin-users-wrapper .admin-backups-nav-dashboard {
  background-color: transparent;
  padding: 20px 0 0 40px;
  box-sizing: border-box;
}

.admin-users-inner {
  margin: 50px auto 70px;
  background: var(--white-color);
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.08);
  width: 91%;
  max-width: 1440px;
}

.admin-users-grid-header,
.admin-users-grid-row {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 1.4fr 1.4fr 2.5fr 2.5fr 2.5fr 2.5fr;
  padding: 0 25px 0 25px;
  cursor: pointer;
}

.admin-users-grid-header {
  margin-bottom: 20px;
}

.admin-users-grid-row {
  padding-top: 18px;
  padding-bottom: 18px;
}

.admin-users-grid-row:nth-child(even) {
  background-color: #f1f1f1;
}

.admin-users-grid-header span,
.admin-users-grid-header div span {
  color: #00072B;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  text-align: center;
}

.admin-users-grid-header div span {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}

.admin-users-grid-header div span.type-of-subscription {
  position: relative;
}

.admin-users-grid-header div span.type-of-subscription:hover {
  color: #7e1063;
}

.admin-users-grid-header-container-sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}
.admin-users-grid-header-container-sort-type-of-subscription {
  position: relative;
}


.admin-users-grid-header-container-sort > .rotate {
  margin-top: 4px;
  rotate: 180deg;
}

.admin-users-grid-row span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #00072B;
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  text-align: center;
  word-break: break-all;
}

.user-sub-status-header {
  text-align: center;
  position: relative;
}

.user-sub-status-header:hover {
  color: #7e1063;
}

.admin-users-grid-row .user-sub-status {
  text-align: center;
  font-weight: 700;
}

.admin-users-grid-row .user-sub-status.active {
  color: #5E8057;
}

.admin-users-grid-row .user-sub-status.cancel {
  color: #D33841;
}

.admin-users-grid-row .user-sub-status.n-a {
  color: #2579ba;
}

.admin-users-grid-row .user-sub-status.expired {
  color: #df6819;
}

.users-list-title {
  display: flex;
  color: var(--headings-blue);
  font-size: 32px;
  font-weight: 600;
  align-items: center;
}
.users-list-title > img {
  width: 45px;
  object-fit: contain;
  margin-right: 12px;
}

.admin-users-header {
  padding: 35px 40px 25px;
}

.admin-users-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.table-header-buttons > button:last-child {
  background-color: #38c172;
}
.users-list-create-user,
.table-header-buttons > a,
.table-header-buttons > button {
  display: block;
  border: none;
  color: white;
  font-family: 'Source Sans 3', sans-serif;
  padding: 7px 20px;
  border-radius: 25px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 20px;
  background: var(--headings-blue);
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}

.users-list__create-user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 4px;
}

.users-list__create-user__text {
  white-space: nowrap;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-primary);
  margin: 0;
}


.admin-users-header-row-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
}

.table-header-buttons > a {
  margin-right: 15px;
}

.admin-users-search-input-wrapper {
  margin-top: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-users-search-input-wrapper-cont {
  position: relative;
  width: 60%;
}

.admin-users-search-input-wrapper-main-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  gap: 15px;
}

.admin-users-clear-filters {
  border: 0;
  border-radius: 30px;
  background: var(--color-primary);
  box-sizing: border-box;
  padding: 10px 20px;
  color: var(--white-color);
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.admin-users-search-input-wrapper > .admin-users-search-input-wrapper-main-cont > .admin-users-search-input-wrapper-cont > img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 17px;object-fit: contain;
  left: 15px;
}

.admin-users-search-input {
  width: 100%;
  font-size: 18px;
  color: #464646;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  /* margin: 0; */
  padding: 10px 10px 10px 45px;
}

.admin-users-search-input::placeholder {
  font-size: 18px;
}
.users-title-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.table-id,
.table-name,
.table-email,
.table-expire,
.table-subscrip,
.table-created,
.table-button {
  text-align: start;
  color: var(--headings-blue);
  padding: 1%;
  border-bottom: 2px solid #dee2e6;
}
tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-id {
  width: 5%;
}
.table-name {
  width: 20%;
}
.table-email {
  width: 20%;
}
.table-expire {
  width: 15%;
}
.table-subscrip {
  width: 15%;
}
.table-created {
  width: 15%;
}
.table-button {
  width: 10%;
}
.table-button > button {
  border: none;
  color: white;
  font-family: 'Montserrat';
  padding: 10%;
  border-radius: 25px;
  margin: 0 1%;
  white-space: nowrap;
  font-weight: 600;
  background: var(--headings-blue);
  cursor: pointer;
}
.users-table > table {
  /*padding: 0 2% 2% 2%;*/
  padding: 15px;
  width: 100%;
}
.users-table > table > thead > tr > th {
  border-top: 1px solid #dee2e6;
}

.users-table > .admin-users-list-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.users-table td {
  white-space: nowrap;
  padding: 1% 40px 1% 1%;
}
.table-name > span {
  color: white;
  background: var(--headings-blue);
  border-radius: 15px;
  padding: 3px 10px;
  margin-left: 5px;
  font-size: 15px;
}
.table-header-buttons .lds-ellipsis div {
  background: white;
}
.table-header-buttons .lds-ellipsis {
  width: 140px;
  left: 52px;
}

/*PAGINATION STYLES*/
.admin-users-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.admin-users-pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 2px;

  border: 1px solid #cbd0e1;
  border-radius: 4px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #464646;
}

.admin-users-pagination-box:last-child {
  margin-right: 0;
}

.admin-users-pagination-box-next-img {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.admin-users-pagination-drop-down {
  border: 1px solid #cbd0e1;
  border-radius: 4px;
  /*width: 32px;*/
  height: 32px;
  margin-left: 8px;
  padding: 5px 10px 5px 5px;

  position: relative;
}

.admin-users-pagination-drop-down-select {
  position: absolute;
  bottom: -75px;
  left: 0;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  height: 69px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #464646;
  border: 1px solid #cbd0e1;
  border-radius: 4px;
  background: var(--white-color);
  z-index: 100000000;
  -webkit-box-shadow: 0px 0px 30px -7px var(--black-color);
  box-shadow: 0px 0px 30px -7px var(--black-color);
}

.admin-users-pagination-drop-down-select-type-of-subscription {
  height: 270px;
  top: 39px;
  align-items: flex-start;
}

.admin-users-pagination-drop-down-select-statuses {
  height: 116px;
  bottom: -120px;
  align-items: flex-start;
}

.admin-users-pagination-drop-down-select-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
}

.admin-users-pagination-drop-down-select-page:hover {
  background: #dee2e6;
  color: #464646;
}
.admin-users-pagination-drop-down-select-page-selected {
  background: #7e106e;
  color: var(--white-color);
}

.admin-users-pagination-drop-down-select-page-selected:hover {
  background: #7e106e;
  color: var(--white-color);
}

.admin-users-pagination-drop-down-input {
  height: 100%;
  background: transparent;
  width: 120px;
}

.admin-users-pagination-drop-down-input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #464646;
}

.admin-users-pagination-drop-down-input,
.admin-users-pagination-drop-down-input:focus {
  outline: none;
   border: 0;

}

.admin-users-pagination-drop-down-arrow-down {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}

.admin-users-pagination-drop-down-arrow-up {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}

.admin-users-pagination-drop-down:hover,
.admin-users-pagination-drop-down-input:hover,
.admin-users-pagination-drop-down-arrow:hover,
.admin-users-pagination-box:hover {
  background: #edebeb;
  cursor: pointer;
}

.admin-users-pagination-box-selected,
.admin-users-pagination-box-selected:hover {
  background: #7e106e;
  color: var(--white-color);
}
.admin-users-pagination-drop-down-select-container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgba(70, 70, 70, .5);
  padding: 5px;
}

.admin-users-pagination-drop-down-select-btn {
  width: 90px;
  height: 25px;
  border-radius: 20px;

  cursor: pointer;
}

.admin-users-pagination-drop-down-select-btn-reset {
  color: var(--white-color);
  background: #7e1063;
  border: 1px solid #7e106e;
}

.admin-users-pagination-drop-down-select-type-of-subscription-cont-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  margin-left: 20px;
}

.admin-users-pagination-drop-down-select-type-of-subscription-checkmark {
  width: 15px;
  height: 15px;
  background: var(--white-color);
  border: 2px solid #7e106e;
  margin-right: 5px;
}

.admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected {
  background: #7e106e;
}

.admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img {
  width: 15px;
  height: 15px;
}

.admin-users-pagination-drop-down-select-type-of-subscription-checkmark {
  min-width: unset!important;
}

.admin-users-grid-row__name {
  white-space: unset;
  word-break: break-all;
}

.admin-users-grid-row .user-sub-type {
  word-break: break-word;
}

.admin-users-grid-no-result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.admin-users-grid-no-result-text {
  font-size: 28px;
  font-family: 'Source Sans 3', sans-serif;
}
@media only screen and (min-width: 2000px) {
  .users-title-right > input {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1175px) {
  .users-list-title {
    font-size: 28px;
  }
  .users-list__create-user__text,
  .users-list-create-user, .table-header-buttons > a,
  .table-header-buttons > button {
    font-size: 16px;
  }

  .admin-users-grid-header span,
  .admin-users-grid-header div span,
  .admin-users-grid-row span {
    min-width: 60px;
  }
}
@media only screen and (max-width: 980px) {
  .admin-users-grid-header, .admin-users-grid-row {
    display: flex;
    flex-direction: row;
  }
  .admin-users-grid-header {
    margin-bottom: 5px;
  }
  .admin-users-pagination-drop-down-input::placeholder,
  .admin-users-pagination-drop-down-input:focus::placeholder {
    font-size: 12px;
  }
  .admin-users-search-input::placeholder {
    font-size: 14px;
  }
  .admin-users-grid-header > .user-sub-name-header,
  .admin-users-grid-header > .user-sub-email-header,
  .admin-users-grid-header > .admin-users-grid-header__id,
  .admin-users-grid-header > div,
  .admin-users-grid-row > span {
    width: 150px;
    font-size: 12px;
  }

  .admin-users-grid-header > span,
  .admin-users-grid-header > div > span {
    font-size: 12px;
  }

  .admin-users-grid-header,
  .admin-users-grid-row {
    width: 1165px;
  }

  .admin-users-grid-header > .admin-users-grid-header__id,
  .admin-users-grid-row span:first-child {
    width: 60px;
  }

  .admin-users-grid {
    overflow-x: scroll;
  }

  .users-list__create-user__text,
  .users-list-create-user,
  .table-header-buttons > a,
  .table-header-buttons > button {
      font-size: 12px;
  }

  .users-list-title {
    font-size: 24px;
  }

  .users-list-title > img {
    width: 35px;
  }

  .admin-users-pagination-box {
    width: 26px;
    height: 26px;
  }

  .admin-users-search-input,
  .admin-users-pagination-drop-down {
    height: 26px;
    padding: 0 5px;
  }

  .admin-users-search-input {
    padding-left: 30px;
    font-size: 12px;
    border-radius: 6px;
  }

  .admin-users-search-input::placeholder {
    font-size: 12px;
  }

  .admin-users-search-input-wrapper > .admin-users-search-input-wrapper-cont > .admin-users-search-input-wrapper-cont > img {
    width: 14px;
    left: 10px;
  }

  .admin-users-pagination-drop-down-select-type-of-subscription {
    top: 25px;
  }

  .admin-users-grid-header__registration-date {
    display: flex;
    justify-content: center;
  }

  .admin-users-grid-header__registration-date__inside {
    width: 120px;
  }
}
@media only screen and (max-width: 780px) {
  .admin-users-search-input-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .admin-users-search-input-wrapper-cont {
    width: 63%;
  }

  .admin-users-header-row-left,
  .table-header-buttons {
    flex-direction: column;
  }

  .users-list__create-user__img {
    width: 15px;
    height: 15px;
  }

  .admin-users-clear-filters {
    font-size: 16px;
    padding: 5px 10px;
  }

  .admin-users-search-input-wrapper > .admin-users-search-input-wrapper-main-cont > .admin-users-search-input-wrapper-cont > img {
    width: 14px;
  }
  .admin-users-search-input-wrapper-main-cont {
    width: 341px;
  }

}
@media only screen and (max-width: 700px) {
  .table-header-buttons > a, .table-header-buttons > button {
    font-size: 12px;
  }

  .users-list-title {
    font-size: 28px;
  }

  .users-list-title > img {
    width: 35px;
  }
}
@media only screen and (max-width: 600px) {
  .table-header-buttons > a {
    margin-right: 5px;
  }

  .users-list-title {
    font-size: 26px;
  }

  .users-list-title > img {
    width: 30px;
    margin-right: 5px;
  }

  .admin-users-search-input::placeholder {
    font-size: 16px;
  }
}
@media only screen and (max-width: 550px) {
  .table-header-buttons > a, .table-header-buttons > button {
    padding: 8px 10px;
  }

  .table-header-buttons > a {
    margin-right: 5px;
  }

  .users-list-title {
    font-size: 24px;
  }

  .users-list-title > img {
    width: 25px;
  }

  .admin-users-search-input-wrapper-cont {
    width: 75%;
  }

  .admin-users-pagination-drop-down {
    width: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admin-users-pagination-drop-down-input {
    width: 68px;
  }

  .admin-users-pagination-drop-down-select-page,
  .admin-users-pagination-drop-down-select-page-selected,
  .admin-users-pagination-drop-down-input::placeholder {
      font-size: 10px;
  }

  .admin-users-pagination-box {
    font-size: 12px;
  }

  .admin-users-pagination-drop-down {
    margin-left: 4px;
  }

  .admin-users-pagination-drop-down {
    width: 100px;
  }

  .admin-users-pagination-drop-down-arrow .admin-users-pagination-drop-down-arrow-down {
    margin-top: 3px;
  }
}
@media only screen and (max-width: 450px) {
  .table-header-buttons > a, .table-header-buttons > button {
    padding: 6px 8px;
    font-size: 10px;
  }

  .users-list-title {
    font-size: 20px;
  }

  .users-list-title > img {
    width: 25px;
  }

  .admin-users-search-input-wrapper-cont {
    width: 100%;
  }

  .admin-users-clear-filters {
    font-size: 14px;
  }

  .admin-users-search-input-wrapper-main-cont {
    width: 300px;
  }
}
@media only screen and (max-width: 391px) {
  .table-header-buttons > a, .table-header-buttons > button {
    padding: 4px 6px;
    font-size: 10px;
  }

  .users-list-title {
    font-size: 16px;
  }

  .users-list-title > img {
    width: 20px;
  }

  .admin-users-pagination-drop-down {
    height: 27px;
  }
  .admin-users-pagination-box {
    width: 27px;
    height: 27px;
  }

  .admin-users-wrapper .admin-backups-nav-dashboard {
    padding-left: 20px;
  }
  .admin-users-header {
    padding: 25px 20px;
  }

  .admin-users-header-row-left {
    align-items: flex-start;
  }

  .admin-backups-nav-dashboard > img {
    width: 15px;
  }
}
@media only screen and (max-width: 361px) {
  .table-header-buttons > a, .table-header-buttons > button {
    font-size: 9px;
  }

  .users-list-title {
    font-size: 14px;
  }

  .users-list-title > img {
    width: 18px;
  }
}
