.membership {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vw 15.3vw 7.9vw 16.3vw;
}
.plan-title {
  display: flex;
}
.membership > h4 {
  text-align: center;
  font-size: 1.6vw;
  font-weight: 600;
  margin: 0;
}
.membership > p {
  font-weight: 600;
  font-size: 1.1vw;
  margin: 0.33vw 0 5vw 0;
  text-align: center;
}
.membership .most-popular-plan {
  background: #eacb36;
  border-radius: 0.3vw;
  margin: 0;
  font-weight: 600;
  font-size: 1.1vw;
  padding: 0.2vw 0.9vw;
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: -3vw;
}
.membership-plans {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.membership-plans > div {
  background-image: url('../../images/Membership-plan-box.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20%;
}
.membership-plans > div > .dashed-hr {
  border: none;
  border-bottom: 0.13vw dashed var(--headings-blue);
  margin: 0 0 1.6vw 0;
  width: 90%;
}
.membership-plans > div > div > div > p,
.membership-plans > div > p {
  color: var(--headings-blue);
  font-size: 0.93vw;
  font-weight: 600;
  margin: 0;
}
.membership-plans::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.membership-plans::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.membership-plans::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .membership > h4 {
    font-size: 13px;
  }
  .membership > p,
  .membership .most-popular-plan {
    font-size: 9px;
  }
}
@media only screen and (max-width: 650px) {
  .membership {
    padding: 3vw 7.3vw 7.9vw 7.3vw;
  }
  .membership .most-popular-plan {
    top: -4vw;
  }
}
@media only screen and (max-width: 520px) {
  .membership {
    padding: 8.7vw 0 14.4vw 0;
  }
  .membership > h4 {
    font-size: 4.6vw;
  }
  .membership > p {
    font-size: 3.6vw;
    margin: 5.9vw 5.6vw 0 5.6vw;
  }
  .membership-plans {
    padding-top: 22.6vw;
    overflow-x: scroll;
  }
  .membership .most-popular-plan {
    top: -17.5vw;
    font-size: 3.6vw;
    padding: 1.8vw 2.3vw;
  }
}
