.social-media-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 40.5px 37px 27px;

    position: relative;
    width: 850px;
    height: 735px;

    background-color: #ffffff;

    border-radius: 20px;
    box-sizing: border-box;
}

.social-media-modal__close {
    position: absolute;
    top: 27px;
    right: 47px;

    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.social-media-modal__choose-info {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #030054;
    margin: 0;
    padding: 0 0 32px;
    text-align: center;
}

.social-media-modal__limit-text {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #403E59;
    margin: 0 0 24px;
    padding: 0;
}

.social-media-modal__limit-text-20-schools {
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
}

.social-media-modal-select-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: start;
}

.social-media-modal-select-list-title {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #464646;
    margin-bottom: 8px;
}

.social-media-modal-select-list-container-main-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.social-media-modal-select-list-autocomplete {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    max-height: 200px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    top: 55px;
    left: 0;
    border-radius: 8px;
    border: 1px solid #a7a7a7;
    box-sizing: border-box;
    box-shadow: 0 0 13.037px rgba(0, 0, 0, 0.15);
    z-index: 2;
}

.social-media-modal-select-list-autocomplete::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.social-media-modal-select-list-autocomplete::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.social-media-modal-select-list-autocomplete::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 10px;
    cursor: pointer;
}

.social-media-modal-select-list-autocomplete-item {
    text-align: start;
    padding: 15px 20px;
    margin: 0;
    width: 90%;
    height: 15px;
}

.social-media-modal-select-list-autocomplete-item:hover {
    background-color: #e1ebf4;
}

.social-media-modal-select-list-input {
    padding: 13px 20px;
    width: 387px;
    height: 47px;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    box-sizing: border-box;

    position: relative;
}

.social-media-modal-select-list-input,
.social-media-modal-select-list-input::placeholder {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464646;
}

.social-media-modal-select-list-input-arrow {
    width: 10px;
    height: 10px;

    position: absolute;
    right: 21px;
}

.social-media-modal-select-list-input-arrow-opened {
    transform: rotate(180deg);
}

.social-media-modal__btn,
.social-media-modal-select-list-btn {
    width: 81px;
    /*height: 47px;*/
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: var(--color-primary);
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    margin-left: 4px;
    cursor: pointer;
    padding: 11px 8px;
    max-height: 45px;
}

.social-media-modal__btn--not-allowed {
    cursor: not-allowed;
}

.social-media-modal__btn {
    border-radius: 20px;
    align-self: end;
    width: 110px;
}

.social-media-modal__main-content {
    display: flex;
    flex-direction: row;

    width: 100%;
    border: 1px solid #c2c2c2;
    border-radius: 8px;

    min-height: 360px;
    box-sizing: border-box;
    margin-bottom: 21px;
}

.social-media-modal__main-content--second {
    min-height: 460px;
}

.social-media-modal__main-content__left-side,
.social-media-modal__main-content__right-side {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    padding: 19px 0 32px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.social-media-modal__main-content__left-side-title-container {
    padding-left: 25px;
}

.social-media-modal__main-content__left-side {
    border-right: 1px solid #c2c2c2;
}

.social-media-modal__main-content__left-side__first-level-title,
.social-media-modal__main-content__right-side-title-column,
.social-media-modal__main-content__left-side-title-column {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
    margin: 0 0 8px;
    padding: 0;
}

.social-media-modal__main-content__left-side-title-column,
.social-media-modal__main-content__right-side-title-column {
    font-size: 20px;
}

.social-media-modal__main-content__left-side-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 25px 20px;
    border-top: 1px solid #f1f1f1;
}

.social-media-modal__main-content__left-side-center__btn {
    color: var(--white-color);
    text-align: center;
    font-size: 16px;
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;

    border: 0;
    border-radius: 20px;
    background: var(--color-primary);

    padding: 4px 18px;
    cursor: pointer;
}

.social-media-modal__main-content__right-side-list,
.social-media-modal__main-content__left-side-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    border-top: 1px solid #f1f1f1;
}

.social-media-modal__main-content__right-side-list--second,
.social-media-modal__main-content__left-side-list-second {
    height: 350px;
}

.social-media-modal__main-content__left-side-item,
.social-media-modal__main-content__right-side-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 6px 0;
    margin: 0;
    position: relative;
}

.social-media-modal__main-content__right-side-item,
.social-media-modal__main-content__left-side-item {
    padding: 6px 25px;
    box-sizing: border-box;
}

.social-media-modal__main-content__right-side-item {
    justify-content: flex-start;
    position: relative;
}

.social-media-modal__main-content__left-side-item:hover .custom-checkbox__input {
    left: -5px;
}

.social-media-modal__main-content__left-side-item:hover {
    padding-left: 20px;
}

.social-media-modal__main-content__left-side-item:hover,
.social-media-modal__main-content__right-side-item:hover {
    background: rgba(126, 16, 110, 0.12);
}

.social-media-modal__main-content__left-side-item:hover .social-media-modal__main-content__left-side-item-text {
    display: block;
}

.social-media-modal__main-content__right-side-item__name,
.social-media-modal__main-content__left-side-item-name,
.social-media-modal__main-content__left-side-item-text {
    font-size: 14px;
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.social-media-modal__main-content__left-side-item-name {
    width: 95%;
}

.social-media-modal__main-content__left-side-item-text {
    display: none;
    color: var(--color-primary);
    font-family: 'Source Sans 3', sans-serif;
    font-size: 16px;
    font-weight: 400;
    width: 10%;
    text-align: end;
}

.social-media-modal__main-content__left-side-item-text>img {
    width: 20px;
    height: 20px;
}

.social-media-modal__main-content__right-side-item__name-added,
.social-media-modal__main-content__left-side-item-name-added {
    color: #464646;
}


.social-media-modal__main-content__left-side-item__remove,
.social-media-modal__main-content__left-side-item__add {
    opacity: 0;
    margin: 0;
    padding: 0;
    transition: opacity 0.5s;
    position: relative;
}

.social-media-modal__main-content__left-side-item__remove__text,
.social-media-modal__main-content__left-side-item__add__text {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4ba62f;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.social-media-modal__main-content__left-side-item__remove__text {
    color: var(--student-color);
    white-space: nowrap;
}

.social-media-modal__main-content__left-side-item:hover>.social-media-modal__main-content__left-side-item__remove,
.social-media-modal__main-content__left-side-item:hover>.social-media-modal__main-content__left-side-item__add {
    opacity: 1;
}

.social-media-modal__main-content__left-side-item-selected,
.social-media-modal__main-content__right-side-item-selected {
    color: #464646;
}

.social-media-modal__main-content__right-side__info-selected {
    display: block;
    align-self: end;

    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #8da1ac;
    margin: 0;
}

.social-media-modal__main-content__right-side__info-selected--accept {
    color: #197c05;
    margin: 0;
    padding-left: 5px;
    white-space: nowrap;
}

.social-media-modal__main-content__right-side__info-selected--warning {
    color: #d33841;
    padding-left: 5px;
    margin: 0;
    white-space: nowrap;
}

.social-media-modal__main-content__right-side-item__minus {
    margin-right: 12px;
}

/*.social-media-modal__main-content__right-side-item::before {*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: absolute;*/
/*    content: "Remove";*/
/*    top: -40px;*/
/*    left: 5px;*/
/*    z-index: 2;*/
/*    width: 80px;*/
/*    height: 32px;*/
/*    background: #403e59;*/
/*    border-radius: 4px;*/
/*    transition: 0.6s ease-in-out all;*/

/*    font-family: 'Source Sans 3', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    color: #ffffff;*/
/*}*/

/*.social-media-modal__main-content__right-side-item:nth-child(2)::before,*/
/*.social-media-modal__main-content__right-side-item:nth-child(3)::before,*/
/*.social-media-modal__main-content__right-side-item:first-child::before {*/
/*    top: 30px;*/
/*}*/

/*.social-media-modal__main-content__right-side-item:hover::before {*/
/*    opacity: 1;*/
/*    visibility: visible;*/
/*}*/

/*.social-media-modal__main-content__right-side-item::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 0;*/
/*    z-index: 1;*/
/*    height: 0;*/
/*    top: -13px;*/
/*    left: 19px;*/
/*    border-left: 12px solid transparent;*/
/*    border-right: 12px solid transparent;*/
/*    transition: 0.8s ease-in-out all;*/

/*    border-top: 12px solid #403e59;*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*}*/


/*.social-media-modal__main-content__right-side-item:nth-child(2)::after,*/
/*.social-media-modal__main-content__right-side-item:nth-child(3)::after,*/
/*.social-media-modal__main-content__right-side-item:first-child::after {*/
/*    top: 23px;*/
/*    transform: rotate(180deg);*/
/*}*/

/*.social-media-modal__main-content__right-side-item:hover::after {*/
/*    opacity: 1;*/
/*    visibility: visible;*/
/*}*/

.social-media-modal__bookmark {
    font-size: 14px;
    background-color: #d33841;
    color: #ffffff;
    position: absolute;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    z-index: 100;
    bottom: calc(100% + 14px);
    transition: 0.8s ease-in-out all;
    right: 0;
}

.social-media-modal__bookmark.hidden {
    opacity: 0;
    visibility: hidden;
}

.social-media-modal__bookmark--bottom.hidden {
    opacity: 0;
    visibility: hidden;
}

.social-media-modal__bookmark::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #d33841;
    transform: rotate(45deg);
    position: absolute;
    bottom: -6px;
    left: -10px;
    right: -125px;
    margin: auto;
}

.social-media-modal__bookmark--bottom {
    bottom: -33px;
}

.social-media-modal__bookmark--bottom::before {
    top: -6px;
    bottom: unset;
}

/*SECOND STEP*/
.social-media-modal__main-content__left-side__first-level {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px;
}

.social-media-modal__main-content__left-side__first-level:last-child {
    border-bottom: unset;
}

.social-media-modal__main-content__left-side__second-level-up,
.social-media-modal__main-content__left-side__first-level-up {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.social-media-modal__main-content__left-side__second-level-up>span,
.social-media-modal__main-content__left-side__first-level-up>span {
    position: absolute;
    width: 16px;
    height: 16px;
    transition: 0.3s ease-in-out all;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.social-media-modal__main-content__left-side__second-level.active>.social-media-modal__main-content__left-side__second-level-up>span::after,
.social-media-modal__main-content__left-side__first-level.active>.social-media-modal__main-content__left-side__first-level-up>span::after {
    transform: none;
}

.social-media-modal__main-content__left-side__second-level-up>span::before,
.social-media-modal__main-content__left-side__second-level-up>span::after,
.social-media-modal__main-content__left-side__first-level-up>span::before,
.social-media-modal__main-content__left-side__first-level-up>span::after {
    transition: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    border-radius: 2px;
}

.social-media-modal__main-content__left-side__second-level-up>span::after,
.social-media-modal__main-content__left-side__first-level-up>span::after {
    transform: rotate(90deg);
}

.social-media-modal__main-content__left-side__second-level-up>span::before,
.social-media-modal__main-content__left-side__second-level-up>span::after {
    background-color: #030054;
}

.social-media-modal__main-content__left-side__second-level {
    padding: 16px 15px;
    width: 85%;
}

.social-media-modal__main-content__left-side__second-level-title {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #030054;
    padding: 0;
    margin: 0;
}

.social-media-modal__main-content__left-side__third-level {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin: 0;
    position: relative;
}

.social-media-modal__main-content__left-side__third-level:hover {
    background-color: #f1f1f1;
}

.social-media-modal__main-content__left-side__third-level-not-allowed:hover {
    background-color: rgba(211, 56, 65, 0.1);
}


.social-media-modal__main-content__left-side__third-level:hover>.social-media-modal__main-content__left-side__third-level-remove {
    opacity: 1;
}

.social-media-modal__main-content__left-side__third-level-name {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #464646;
    padding: 0;
    margin: 0;
    max-width: 215px;
}

.social-media-modal__main-content__left-side__third-level-remove {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #030054;
    padding: 0;
    margin: 0;

    opacity: 0;
    transition: opacity 0.5s;
    position: relative;
}

.social-media-modal__main-content__left-side__third-level:hover>.social-media-modal__main-content__left-side-item__add,
.social-media-modal__main-content__left-side__third-level:hover>.social-media-modal__main-content__left-side-item__remove {
    opacity: 1;
}

.social-media-modal__cont-bottom-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.social-media-modal__btn--back {
    background-color: #fff;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    position: relative;
}

.social-media-modal__main-content__right-side-item-selected-colleges {
    color: #464646;
}

.social-media-modal__btn-clear {
    border: 0;
    background: #fff;
    color: var(--color-primary);
    margin: 0 10px 10px 0;
    border-bottom: 1px solid var(--color-primary);
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    padding: 0;
}

.social-media-modal-cont-select-list-and-clear {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%
}

.social-media-modal__main-content__left-side-item__add,
.social-media-modal__main-content__left-side-item__remove {
    display: flex;
    justify-content: center;
    padding-right: 25px;
}

.social-media-modal__main-content__left-side-item__add.columns,
.social-media-modal__main-content__left-side-item__remove.columns {
    padding-right: 0;
}

.social-media-modal__cont-count,
.social-media-modal__cont-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.social-media-modal__cont-count {
    position: relative;
    justify-content: flex-end;
}

.social-media-modal__choose-colleges {
    color: #464646;

    font-family: 'Source Sans 3', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    padding: 0;
    margin: 0;
}

.social-media-modal__btn-share {
    border-radius: 28px;
    border: 0;
    background: var(--color-primary);
    color: var(--white-color);
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    padding: 9px 60px 10px;
    cursor: pointer;
    z-index: 3;
}

.social-media-modal__cont-btns__sub-modal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: -45px;
    border-radius: 12px;
    background: var(--white-color);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.26);
    padding: 12px 21px;

    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.social-media-modal__cont-btns__sub-modal.hidden {
    visibility: hidden;
    opacity: 0;
    top: 0;

}

.social-media-modal__btn-clear.second {
    display: none;
}

.social-media-modal__choose-colleges-30>strong {
    color: #d33841;
}

.social-media-modal__no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    margin: 10px auto 0;

    gap: 8px;
}

.social-media-modal__no-result__img {
    height: 110px;
}

.social-media-modal__no-result__description {
    color: var(--headings-blue);
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0 0 4px;
    white-space: nowrap;
}

.social-media-modal__no-result__btn {
    border: 0;
    border-radius: 20px;
    background: var(--color-primary);
    padding: 5px 12px;

    color: var(--white-color);
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
}

/* SECOND MODAL */
.social-media-modal__submodal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    position: relative;
    width: 600px;
    height: 235px;

    background-color: var(--white-color);

    border-radius: 20px;
    box-sizing: border-box;
}

.social-media-modal__submodal__cross {
    position: absolute;
    top: 28px;
    right: 28px;

    display: block;
    width: 12px;
    height: 12px;

    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.social-media-modal__submodal__p {
    color: var(--headings-blue);
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.social-media-modal__submodal__btn {
    padding: 9px 75px;
}

@media only screen and (max-width: 860px) {
    .social-media-modal {
        width: 740px;
    }
}

@media only screen and (max-width: 750px) {
    .social-media-modal {
        width: 640px;
    }
}

@media only screen and (max-width: 650px) {
    .social-media-modal {
        width: 535px;
    }

    .social-media-modal__submodal {
        width: 500px;
    }

    .social-media-modal__submodal__p {
        font-size: 20px;
    }

    .social-media-modal__no-result {
        width: 200px;
    }

    .social-media-modal__no-result__img {
        height: 100px;
    }

    .social-media-modal__no-result__description {
        font-size: 16px;
    }

    .social-media-modal__no-result__btn {
        font-size: 14px;
    }
}

@media only screen and (max-width: 544px) {
    .social-media-modal {
        width: 340px;
        height: 700px;
        margin: 0 auto;
        padding: 24px 20px 20px;
    }

    .social-media-modal__submodal {
        width: 90%;
        margin: 0 auto;
        padding: 45px 30px 20px;
        height: 200px;
    }


    .social-media-modal__choose-info {
        font-size: 20px;
    }

    .social-media-modal__close {
        width: 12px;
        height: 12px;
        background-size: contain;
    }

    .social-media-modal__btn-share,
    .social-media-modal__main-content__left-side-title-column,
    .social-media-modal__main-content__right-side-title-column,
    .social-media-modal-select-list-title {
        font-size: 16px;
    }

    .social-media-modal-select-list-input,
    .social-media-modal-select-list-input::placeholder {
        font-size: 14px;
    }

    .social-media-modal-select-list-input-arrow {
        width: 12px;
    }

    .social-media-modal-select-list-input {
        border-radius: 12px;
        width: 100%;
        padding: 11px 16px;
    }

    .social-media-modal-select-list-container-main-content,
    .social-media-modal-select-list {
        width: 100%;
    }

    .social-media-modal__main-content {
        flex-direction: column;
        border: unset;
        overflow-y: scroll;
        gap: 8px;
        margin-bottom: 8px;
    }

    .social-media-modal__main-content__right-side,
    .social-media-modal__main-content__left-side {
        border: 1px solid #c2c2c2;
        border-radius: 12px;
        width: 100%;
        height: 320px;
        padding: 0 0 12px;
    }

    .social-media-modal__main-content__left-side-title-container {
        padding-top: 12px;
        background-color: #f1f1f1;
    }

    .social-media-modal__main-content__left-side-center {
        padding: 10px 16px;
    }

    .social-media-modal__main-content__right-side-item, .social-media-modal__main-content__left-side-item {
        padding: 5px 16px;
    }

    .social-media-modal__main-content__right-side-list,
    .social-media-modal__main-content__left-side-list {
        max-height: 164px;
    }

    .social-media-modal__choose-info {
        padding-bottom: 12px;
    }

    .social-media-modal-select-list-container-main-content {
        margin-bottom: 4px;
    }

    .social-media-modal__main-content__left-side-title-container {
        padding-left: 16px;
    }

    .social-media-modal__main-content__right-side-list {
        max-height: unset;
    }

    .social-media-modal-cont-select-list-and-clear {
        position: relative;
    }

    .social-media-modal__btn-clear {
        position: absolute;
        top: 0;
        right: 0;
        border-bottom: unset;
        display: none;
    }

    .social-media-modal__btn-clear.second {
        display: block;
        left: 0;
        bottom: 0;
        font-size: 14px;
        width: 35px;
        margin: 0;
        background: var(--white-color);
        color: var(--color-primary);
    }

    .social-media-modal__cont-btns__sub-modal.hidden {
        top: 0;
    }

    .social-media-modal__cont-btns__sub-modal {
        top: -40px;
        width: 85%;
        padding: 6px 10px;
        justify-content: space-between;
    }

    .social-media-modal__main-content__left-side-item-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
    }

    .social-media-modal__main-content__left-side-item-text>img {
        width: 18px;
        height: 18px;
    }

    .custom-checkbox__input {
        margin-right: 15px;
    }

    .social-media-modal__main-content__left-side-center__btn {
        padding: 0 18px;
    }

    .social-media-modal__cont-count {
        margin-bottom: 12px;
    }

    .social-media-modal__close {
        top: 24px;
        right: 24px;
    }

    .social-media-modal__main-content__left-side-center.no-result {
        display: none;
    }

    .social-media-modal__no-result {
        width: 250px;
        gap: 4px;
        margin-top: 25px;
    }

    .social-media-modal__no-result__img {
        height: 60px;
    }

    .social-media-modal__no-result__description {
        font-size: 14px;
    }

    .social-media-modal__no-result__btn {
        font-size: 16px;
        padding: 2px 12px;
    }
}

@media only screen and (max-width: 390px) {
    .social-media-modal {
        width: 340px;
    }

    .social-media-modal__big-array {
        width: 95%;
        padding: 30px 20px 20px;
        height: 190px;
    }

    .social-media-modal__big-array_p {
        padding-top: 10px;
        font-size: 18px;
    }

    .social-media-modal__big-array__cross {
        top: 16px;
        right: 16px;
    }

}

@media only screen and (max-width: 345px) {
    .social-media-modal {
        width: 310px;
    }

    .social-media-modal__big-array_p {
        font-size: 18px;
    }
}

/* loader */
.container-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loader div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: var(--color-primary);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.loader div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.loader div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.loader div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.loader div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.loader div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.loader div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.loader div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.loader div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.loader div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.loader div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.loader div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.loader div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

@media only screen and (max-width: 1366px) {
    .social-media-modal {
        height: 30rem;
        overflow: auto;
    }
}

@media only screen and (max-width: 544px) {
    .loader {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
    }

    .loader div {
        position: absolute;
        width: 3px;
        height: 3px;
        background: var(--color-primary);
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
    }

    .loader div:nth-child(1) {
        animation-delay: 0s;
        top: 17.5px;
        left: 33px;
    }

    .loader div:nth-child(2) {
        animation-delay: -0.1s;
        top: 11px;
        left: 31px;
    }

    .loader div:nth-child(3) {
        animation-delay: -0.2s;
        top: 5.5px;
        left: 26px;
    }

    .loader div:nth-child(4) {
        animation-delay: -0.3s;
        top: 3.5px;
        left: 11.5px;
    }

    .loader div:nth-child(5) {
        animation-delay: -0.4s;
        top: 5.5px;
        left: 11px;
    }

    .loader div:nth-child(6) {
        animation-delay: -0.5s;
        top: 11px;
        left: 5.5px;
    }

    .loader div:nth-child(7) {
        animation-delay: -0.6s;
        top: 18.5px;
        left: 3.5px;
    }

    .loader div:nth-child(8) {
        animation-delay: -0.7s;
        top: 26px;
        left: 5.5px;
    }

    .loader div:nth-child(9) {
        animation-delay: -0.8s;
        top: 31px;
        left: 11px;
    }

    .loader div:nth-child(10) {
        animation-delay: -0.9s;
        top: 33px;
        left: 18.5px;
    }

    .loader div:nth-child(11) {
        animation-delay: -1s;
        top: 31px;
        left: 26px;
    }

    .loader div:nth-child(12) {
        animation-delay: -1.1s;
        top: 26px;
        left: 31px;
    }
}