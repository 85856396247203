.container {
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 18.75rem;
    padding: 1.5rem;
    border-radius: 12px;
    background: var(--white-color);
}

.title {
    margin: 0;
    color: var(--primary-typography);
    font-size: 1.75rem;
}

.subtitle {
    margin: 0;
    color: var(--primary-typography);
    font-size: 1.25rem;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.button {
    position: relative;
    display: flex;
    padding: 0 .5rem;
    height: 2.5rem;
    min-width: 5rem;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    font-size: 1.25rem;
    font-weight: 600;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 12px;
    background: var(--color-primary);
    cursor: pointer;
}
.buttonArrowIcon {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../../../images/arrowWhiteSmall.svg");
    background-size: cover;
}

.autocomplete {
    top: 3rem;
    left: 0;
    color: var(--primary-typography);
    font-size: 1.25rem;
    font-weight: 400;
    width: 110%;
}
.autocomplete > div {
    width: 100%;
}
.autocomplete > div:hover {
    background: rgba(126, 16, 110, 0.12);
}

.rangeWrapper {
     display: flex;
     width: 100%;
     justify-content: center;
 }

@media only screen and (max-width: 900px){
    .container {
        width: 25rem;
    }
}


@media only screen and (max-width: 520px) {
    .container {
        width: 100%;
    }
}