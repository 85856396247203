.button {
    font-family: 'Source Sans 3', sans-serif;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.button:active {
    transform: scale(0.98);
}

.primaryBlue,
.primaryTransparent {
    font-size: 1.25rem;
    font-weight: 600;
    width: 100%;
    height: 2.5rem;
    line-height: 120%;
}

.primaryBlue {
    color: var(--white-color);
    border-radius: 24px;
    box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.30);
    border: none;
    background: var(--light-blue);
}

.primaryTransparent {
    color: var(--white-color);
    border-radius: 24px;
    box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.32);
    border: 1px solid var(--white-color);
    background: rgba(255, 255, 255, 0.32);
}

.isDisabledButton {
    cursor: not-allowed !important;
    background: var(--light-grey);
    color: var(--dark-grey);
}

@media only screen and (max-width: 520px) {
    .button {
        width: auto;
        font-size: 1rem;
        padding: 0 1.25rem;
    }
}