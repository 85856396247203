.buy-plan *,
.buy-plan *::after,
.buy-plan *::before {
  box-sizing: border-box;
}

.header-buy-plan > h1 {
  font-weight: 600;
  font-size: 2.1vw;
  color: white;
  padding-top: 4.2vw;
  background-color: var(--color-primary);
  margin: 0;
  overflow: hidden;
  text-align: center;
}
.buy-plan-mobile-button {
  display: none;
}
.header-buy-plan {
  overflow: hidden;
}
.wave {
  width: 100%;
  position: relative;
  top: -6vw;
  z-index: -1;
}
.buy-plan {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 4.75rem;
  background-color: #F1F1F1;
  font-family: "Source Sans 3";
}
.buy-plan-container {
  display: flex;
  justify-content: space-between;
}
.buy-plan-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 0 60px;
}

.plan-details-wrapper-box,
.plan-details-additional-info,
.buy-plan-form {
  padding: 40px;
  background: white;
  border-radius: 10px;
  width: 100%;
}

.account-email {
  border: 1px solid var(--border-color);
  padding: 1.3vw 1.2vw;
  border-radius: 0.7vw;
  margin: 0;
  width: 50%;
  font-size: 14px;
  font-weight: 500;
}

.buy-plan-body > h1 {
  margin: 3.3vw 0 0 0;
  font-size: 1.6vw;
}
.buy-plan-body > p {
  font-weight: 500;
  font-size: 1.2vw;
  margin: 0.6vw 0 3vw;
  color: var(--color-primary);
}
.buy-plan-body > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.buy-plan-form {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-left: 45px;
  max-width: 55%;
}
.account-container {
  margin-bottom: 2rem;
}
.account-container > h5 {
  margin: 0 0 16px;
  font-size: 1rem;
  display: flex;
}
.account-container > h5 > img {
  margin-right: 10px;
}
.plan-details,
.plan-details-wrapper {
  display: flex;
  flex-direction: column;
}

.plan-details {
  gap: 12px;
}
.annual-option {
  color: var(--color-primary);
  background: rgba(233, 209, 0, 0.21);
  width: fit-content;
}
.plan-details-wrapper-img {
  max-width: 127px;
}
.plan-details-additional-info {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 30px 0 0;
  width: 100%;
  font-family: "Source Sans 3";
}
.plan-details-additional-info > li {
  display: flex;
  justify-content: space-between;
}

.plan-details-additional-info > li:not(:first-child) {
  margin-top: 1vw;
}

.plan-discount-label {
  width: 100%;
}

.plan-discount-label.apply-button {
  display: flex;
}

.apply-coupon-text, .apply-coupon-text-inactive {
  padding: 1.3vw 0.5vw 1.3vw 0;
  border-top: 1px solid var(--primary-typography);
  border-bottom: 1px solid var(--primary-typography);
  color: var(--color-primary);
  font-weight: 600;
  font-size: clamp(1rem, 1.1vw + 0.1rem, 1.5rem);
  cursor: pointer;
}
.apply-coupon-text-inactive {
  color: var(--dark-grey)
}
.plan-details-additional-info > .apply-coupon-invalid {
  color: red;
}

.plan-discount-input {
  padding: 1.3vw 0;
  border: none;
  border-top: 1px solid var(--primary-typography);
  border-bottom: 1px solid var(--primary-typography);
  border-radius: 0;
  outline: none;
  width: 100%;
  font-weight: 600;
  font-size: clamp(1rem, 1.1vw + 0.1rem, 1.5rem);
  line-height: 120%;
  font-family: "Source Sans 3";
}

.plan-discount-input::placeholder {
  color: #3B6AC6;
}

.plan-details-additional-info > li > h5 {
  font-weight: 600;
  font-size: clamp(1rem, 1.1vw + 0.1rem, 1.5rem);
  line-height: 28px;
  color: #464646;
  margin: 0;
}
.plan-details-additional-info > li > h5.last-h5 {
  color: var(--color-primary);
}
.annual-payments {
  color: var(--headings-blue);
  font-weight: 600;
  padding-top: 20px;
}
.plan-details-additional-info > p {
  font-weight: 400;
  font-size: 0.9rem;
  color: #c2c2c2;
  margin: 5px 0 0;
  width: 65%;
}
.plan-details-price-wrapper {
  margin: 2.15vw 0;
}
.plan-details-price-wrapper > p {
 font-size: clamp(1rem, 1.2vw + 0.1rem, 1.7rem);
}
.plan-details-title-price {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.5rem, 2vw + 0.1rem, 2.3rem);
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #464646;
  margin: 0;
  font-family: 'Source Sans 3';
}
.plan-details-ceiling, .plan-details-ceiling-with-bg {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-primary);
  margin: 0;
}
.plan-details-ceiling-with-bg {
  background: rgba(233, 209, 0, 0.21);
  color: #030054;
  width: fit-content;
  line-height: 150%;
  font-family: 'Source Sans 3';
  margin-top: 5px;
}
.plan-details-price-wrapper > .plan-details-ceiling-yearly {
  color: #A7A7A7;
  margin: 5px 0 -20px 0;
  font-size: 18px;
  font-family: 'Source Sans 3';
  font-weight: 600;
}
.plan-details-wrapper-switch-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  max-width: 263px;
  padding: 0;
}
.plan-details-cont {
  width: 100%;
}
.plan-details-title {
  margin: 1.5rem 0 0.5rem 0;
}
.plan-details-wrapper-switch-btn {
  width: 56px;
  height: 30px;
  margin: 0;
}
.plan-details-wrapper-switch-btn > .slider-round {
  padding: 0;
  height: 30px;
  width: 55px;
  border: 1px solid var(--color-primary);
  display: flex;
  align-items: center;
}
.plan-details-wrapper-switch-btn > .slider-round:before {
  background: var(--color-primary);
  width: 21px;
  height: 21px;
  border-radius: 50%;
  left: 6px;
  bottom: auto;
}
.plan-details-wrapper-switch-btn input:checked + .slider-round:before {
  transform: translateX(100%);
  background-color: var(--color-primary);
}
.plan-details-wrapper-switch-option {
  display: flex;
  align-items: center;
  justify-content: center;
}
.plan-details-wrapper-switch-option input[type='radio']:checked,
.plan-details-wrapper-switch-option input[type='radio'] {
  margin: 0 10px 0 0;
  border-width: 2px;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  flex: 0 0 22px;
  border-color: var(--color-primary);
}
.plan-details-wrapper-switch-option input[type='radio']::before {
  width: 12px;
  height: 12px;
  background-color: var(--color-primary);
}
.plan-details-wrapper-switch-text {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1rem, 1vw + 0.1rem, 1.5rem);
  color: #464646;
  margin: 0;
  font-family: 'Source Sans 3';
}
.plan-details-wrapper-switch-text-active {
  color: var(--color-primary);
}
.plan-details-wrapper {
  align-items: flex-start;
  width: 43%;
}
.plan-details-wrapper-title {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.5rem, 1.65vw + 0.1rem, 2rem);
  text-align: center;
  color: #a7a7a7;
  margin: 2vw 0 1.6vw;
  font-family: 'Source Serif 3';
  text-align: left;
}
.blue {
  color: var(--headings-blue);
}
.purple {
  color: var(--color-primary);
}
.plan-details > div {
  display: flex;
  align-items: center;
}

.plan-details > div > img, .checkmark-icon {
  margin-right: 10px;
  flex: 0 0 1.3vw;
  max-width: 20px;
  width: 1.3vw;
  min-width: 15px;
  object-fit: contain;
}
.plan-details > div > p {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  color: var(--headings-blue);
  font-family: 'Source Sans 3';
}
.plan-details-wrapper > button {
  margin: 5vw 10%;
  padding: 1.3vw 0;
  border: 1px solid var(--color-primary);
  background: white;
  color: var(--color-primary);
  border-radius: 40px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.1vw;
  cursor: pointer;
  align-self: flex-end;
  width: 20vw;
  min-width: 165px;
  white-space: nowrap;
}
.plan-details-ceiling-mobile {
  display: none;
}

.email-input-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 1.75rem;
}

.switcher-pagination {
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  width: 220px;
  height: 30px;
  background: rgba(241, 241, 241, 0.4);
  border: 1px solid rgba(126, 16, 110, 0.4);
  border-radius: 12px;
}

.switcher-pagination-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #464646;
  padding: 0;
  margin: 0;
}

.switcher-pagination-text-second {
  justify-content: center;
  border-radius: 0 12px 12px 0;
}

.switcher-pagination-text.active {
  color: white;
  background-color: rgba(126, 16, 110, 1);
}

.stripe-form-type-payment-cont > h5 {
  margin: 0 0 8px 0;
}

@media only screen and (max-width: 1024px) {
  .header-buy-plan > h1 {
    font-size: 2.1vw;
    padding-top: 4.2vw;
    margin: 0;
  }
  .buy-plan {
    padding: 55px;
  }
  .buy-plan-body {
    padding: 0 45px;
  }

  .buy-plan-form {
    padding: 30px 60px;
  }

  .buy-plan-body > h1 {
    margin: 3.3vw 0 0 0;
    font-size: 1.6vw;
  }
  .buy-plan-body > p {
    font-size: 1.2vw;
    margin: 0.6vw 0 3vw;
  }
  .buy-plan-body > div {
    width: 100%;
  }
  .buy-plan-form {
    flex: 1 0 auto;
    padding-left: 30px;
  }

  .plan-details {
    gap: 12px;
  }

  .plan-details-wrapper-img {
    max-width: 127px;
  }
  .plan-details-additional-info {
    margin: 3.2vw 0 0;
    width: 100%;
  }

  .plan-details-additional-info > li:not(:first-child) {
    margin-top: 1vw;
  }

  .plan-discount-label {
    width: 100%;
  }

  .plan-details-ceiling {
    font-size: 18px;
    margin: 0 0 15px;
  }
  .plan-details-wrapper-switch-cont {
    gap: 16px;
    max-width: 200px;
    padding: 10px 0 0 0;
  }
  .plan-details-wrapper-switch-btn {
    width: 44px;
    height: 24px;
  }
  .plan-details-wrapper-switch-btn > .slider-round {
    height: 24px;
  }
  .plan-details-wrapper-switch-btn > .slider-round:before {
    width: 18px;
    left: 3px;
    height: 18px;
  }
  .plan-details-wrapper {
    width: 45%;
    padding-right: 30px;
  }
  .plan-details-wrapper-title {
    margin: 10px 0 15px;
  }
}
@media only screen and (max-width: 900px) {
  .buy-plan {
    padding: 2rem 0;
  }

  .buy-plan-body {
    padding: 20px;
  }

  .buy-plan-body > h1 {
    font-size: 14px;
  }

  .buy-plan-body > p,
  .plan-details-wrapper > button {
    font-size: 10px;
  }

  .stripe-form > .privacy-checkbox {
    font-size: clamp(0.8rem, 1.7vw + 0.1rem, 1.2rem);
  }

  .plan-details-wrapper {
    width: 100%;
    padding: 3.8vw 30px 3.8vw 0;
    border-right: none;
    border-bottom: 1px solid #C2C2C2;
  }

  .buy-plan-body > div {
    flex-direction: column;
  }

  .plan-details-wrapper-img {
    display: none;
  }

  .plan-details-wrapper-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
  }

  .plan-details-wrapper-cont {
    padding-right: 10px;
  }

  .plan-details-cont {
    margin: 20px 0;
  }

  .plan-details > div {
    width: 100%;
  }

  .plan-details > div > p > b {
    font-weight: 400;
  }

  .plan-details-wrapper-switch-cont {
    max-width: 257px;
    width: 90%;
    padding: 14px 0;
  }

  .plan-details-wrapper-switch-btn {
    width: 56px;
    height: 30px;
  }

  .plan-details-wrapper-switch-btn > .slider-round {
    height: 30px;
  }

  .plan-details-wrapper-switch-btn > .slider-round:before {
    width: 21px;
    height: 21px;
    left: 6px;
  }

  .plan-details-ceiling {
    font-size: 2.1vw;
  }
  .plan-details > div > p {
    margin: 5px 0;
  }

  .buy-plan-form {
    flex: initial;
    padding-top: 4.4vw;
    margin: 3.8vw 0;
  }

  .buy-plan-form,
  .stripe-form {
    width: 100%;
    max-width: 100%;
  }

  .line-between-form-and-details {
    width: 100%;
    height: 1px;
    background: #c2c2c2;
    margin: 40px 0;
  }
  .stripe-form > .card-radios > .input[type='radio']:checked {
    height: 1.9vw;
  }
}
@media only screen and (max-width: 700px) {
  .buy-plan {
    padding: 1rem 0;
    align-items: center;
  }
  .plan-details-wrapper {
    padding: 0;
  }
  .plan-details-additional-info, .buy-plan-form, .plan-details-wrapper-box {
    margin: 0;
    border-radius: 0;
  }
  .buy-plan-container {
    flex-direction: column;
  }
  .plan-details-wrapper-box {
    align-items: center;
  }
  .plan-details-cont {
    width: fit-content;
  }
}
@media only screen and (max-width: 520px) {
  .plan-details > div > p > b {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .plan-details-wrapper-box {
    flex-direction: column;
    padding-bottom: 0;
    border: none;
  }
  .plan-details > div > img, .checkmark-icon {
    flex: 0 0 18px;
  }
  .plan-details-wrapper-cont, .plan-details-cont {
    width: 100%;
  }
  .plan-details-cont {
    margin-top: 0;
    padding: 0;
    border: none;
  }

  .plan-details-wrapper-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .line-between-form-and-details {
    display: none;
  }
  .plan-details-wrapper-switch-cont {
    max-width: 260px;
    order: 3;
  }
  .plan-details-wrapper-img {
    order: 1;
  }

  .plan-details-wrapper-title-wrapper {
    order: 2;
    margin-bottom: 20px;
    display: flex;
  }

  .plan-details-wrapper-switch-cont {
    width: 60%;
  }

  .plan-details-ceiling {
    order: 4;
  }

  .plan-details-price-wrapper {
    order: 5;
    width: 97%;
  }

  .plan-details-wrapper-title {
    margin: 15px 0 0;
  }

  .buy-plan-form {
    padding: 40px;
    padding-top: 5px;
  }

  .plan-details-wrapper {
    border: none;
  }

  .plan-details-title-price {
    margin-top: 0;
  }
  .plan-details-ceiling {
    display: none;
  }
  .plan-details-ceiling-mobile {
    display: block;
    font-weight: 600;
    font-size: 3.8vw;
    color: #7e106e;
    margin: 0;
  }

  .plan-details-wrapper-img {
    display: none;
  }

  .stripe-form-wrapper input[type='radio'], .stripe-form-wrapper input[type='radio']:checked {
    height: 22px;
    width: 22px;
  }
  .header-buy-plan > h1 {
    font-size: 5vw;
    padding: 4% 0;
    font-weight: 500;
  }
  .buy-plan-body {
    margin-bottom: 5%;
    padding: 30px 20px;
  }
  .buy-plan-form > p {
    padding-bottom: 0;
    font-size: 2.5vw;
  }
  .buy-plan-form > div {
    margin-bottom: 3%;
    font-size: 3vw;
  }
  .buy-plan-form > div > a {
    color: #030054;
  }
  .buy-plan-form > input {
    font-size: 2.5vw;
  }
  .buy-plan-mobile-button {
    display: block;
    padding: 3%;
    width: 75%;
    border: 1px solid var(--color-primary);
    background: white;
    color: var(--color-primary);
    border-radius: 40px;
    font-weight: 600;
    cursor: pointer;
    align-self: center;
    min-width: 165px;
    white-space: nowrap;
    font-size: 4vw;
  }
  .stripe-form-wrapper {
    align-items: flex-start;
  }
  .card-number-input,
  .card-date-input,
  .card-cvc-input,
  .card-holder {
    border-radius: 10px;
  }
  .stripe-form-type-payment-item {
    padding: 8px 10px;
  }
  .stripe-form-type-payment-item > p {
    font-size: 3vw;
  }

  .card-date-cvc,
  .card-user-name {
    justify-content: flex-start;
    gap: 16px;
    margin-top: 12px;
  }

  .stripe-form > label {
    font-size: 16px;
  }
  .plan-details-wrapper-switch-btn > .slider-round {
    border-radius: 5vw;
  }
  .buy-plan-body > div {
    width: 100%;
  }
  .buy-plan-body > p {
    font-size: 3vw;
    font-weight: 600;
  }
  .stripe-form > .privacy-checkbox {
    align-items: flex-start;
  }
  .stripe-form-wrapper > button,
  .buy-plan-mobile-button {
    font-size: 4vw;
    padding: 5%;
    width: 100%;

    margin: 0 auto;
  }

  .buy-plan-mobile-button {
    margin: 1.8vw 0 0;
  }

  .buy-plan-form .card-radios {
    flex-direction: column;
    margin-bottom: 0;
    gap: 12px;
  }
  .buy-plan-body > h1 {
    font-size: 4vw;
  }
  .card-radios > div > p {
    font-size: 18px;
    margin: 0;
  }

  .stripe-form-title {
    margin-bottom: 10px;
  }
}

.buy-plan-loader {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-iphvfbm6rl-o {
  0%    {opacity: 1; transform: translate(0,0)}
  49.99% {opacity: 1; transform: translate(30px,0)}
  50%    {opacity: 0; transform: translate(30px,0)}
  100%    {opacity: 0; transform: translate(0,0)}
}
@keyframes ldio-iphvfbm6rl {
  0% {transform: translate(0,0)}
  50% {transform: translate(30px,0)}
  100% {transform: translate(0,0)}
}
.buy-plan-loader-cont-other div {
  position: absolute;
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
}
.buy-plan-loader-cont-other div:nth-child(1) {
  background: #7e106e;
  animation: ldio-iphvfbm6rl 1s linear infinite;
  animation-delay: -0.5s;
}
.buy-plan-loader-cont-other div:nth-child(2) {
  background: #030054;
  animation: ldio-iphvfbm6rl 1s linear infinite;
  animation-delay: 0s;
}
.buy-plan-loader-cont-other div:nth-child(3) {
  background: #7e106e;
  animation: ldio-iphvfbm6rl-o 1s linear infinite;
  animation-delay: -0.5s;
}
.buy-plan-loader-cont {
  width: 5vw;
  height: 2vw;
  display: inline-block;
  overflow: hidden;
  background: #fff;
}
.buy-plan-loader-cont-other {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.buy-plan-loader-cont-other div {
  box-sizing: content-box;
}

@media screen and (max-width: 920px) {
  .buy-plan-loader-cont {
    width: 6vw;
  }
}
@media screen and (max-width: 720px) {
  .buy-plan-loader-cont {
    width: 7vw;
    height: 3vw;
  }

  .buy-plan-loader-cont-other div {
    width: 2.5vw;
    height: 2.5vw;
  }
  .stripe-form-type-payment-list {
    gap: 10px;
  }
}
@media screen and (max-width: 520px){
  .buy-plan-loader-cont {
    width: 15vw;
    height: 5vw;
  }

  .buy-plan-loader-cont-other div {
    width: 5vw;
    height: 5vw;
  }
  .account-email {
    padding: 20px 18px;
    border-radius: 10px;
    width: 100%;
  }
  .plan-details-price-wrapper > .plan-details-ceiling-yearly {
    margin-bottom: 0;
  }
  .email-input-container {
    margin-left: 0;
  }
}
