.trending-college-block {
  border-radius: 10px;
  margin: 13px;
  width: 30%;
  min-width: 255px;
}
.college-bookmark {
  font-size: 14px;
  background-color: #403E59;
  color: #ffffff;
  position: absolute;
  padding: 8px;
  border-radius: 4px;
  width: max-content;
  z-index: 100;
  bottom: calc(100% + 14px);
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: hidden;
}

.college-bookmark.active {
  opacity: 1;
  visibility: visible;
}

.college-bookmark::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #403E59;
  transform: rotate(45deg);
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

.trending-college-photo {
  position: relative;
}
.trending-college-photo > img {
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 175px;
}
.save-university-icon {
  position: absolute;
  background: white;
  width: 18px;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  top: 10px;
  justify-content: center;
  right: 10px;
  cursor: pointer;
}
.save-university-icon > img {
  height: 17px;
}
.trending-college-photo > .autocomplete {
  width: 100%;
  top: 40px;
}
.trending-college-description {
  background-color: #faf6f6;
  margin-top: -5px;
  padding: 13px 22px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 60px;
}
.trending-college-description > p > img {
  height: 15px;
  margin-right: 3px;
}
.trending-college-description > h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.8;
  color: var(--primary-typography);
  margin: 0 0 0.4vw 0;
  height: 40px;
}
.trending-college-description > h3.trending-college-description-hidden-title::before {
  content: attr(data-tip);
  text-align: center;
  background-color: #faf6f6;
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 5px);
  padding: 5px 10px;
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.trending-college-description > h3.trending-college-description-hidden-title::after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 15px;
  background-color: #faf6f6;
  /*transform: rotate(45deg);*/
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  clip-path: polygon(100% 45%, 0 45%, 50% 100%);
}

.trending-college-description > h3.trending-college-description-hidden-title:hover::before,
.trending-college-description > h3.trending-college-description-hidden-title:hover::after {
  opacity: 1;
  visibility: visible;
}


.trending-college-description > p {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: var(--color-primary);
  opacity: 0.8;
  margin: 0;
  display: flex;
}
.trending-college-description > div {
  font-size: 14px;
  padding-top: 2%;
  display: flex;
  justify-content: space-between;
}
.trending-college-description-b {
  white-space: nowrap;
}
@media screen and (max-width: 520px) {
  .trending-college-description > h3 {
    margin: 0;
  }

  .college-bookmark {
    right: -10px;
  }

  .college-bookmark::before {
    bottom: -6px;
    left: auto;
    right: 5vw;
  }
}
@media screen and (min-width: 1600px) {
  .trending-college-description > h3 {
    margin-bottom: 1.5vw;
    font-size: 1.1vw;
    line-height: 31px;
  }

  .recommended-colleges-wrapper > .colleges-by-categories > div .trending-college-photo > img {
    height: 12vw;
  }

  .trending-college-description > p {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1vw;
  }

  .trending-college-description > p > img {
    height: 1.5vw;
  }

  .trending-college-description > div {
    padding: 4% 0;
    font-size: 1vw;
  }

  .trending-college-photo > .autocomplete > div {
    font-size: 1.2vw;
    margin: 0 auto;
    padding-left: 0;
    width: 60%;
  }

  .save-university-icon {
    width: 25px;
  }

  .save-university-icon > img {
    height: 25px;
  }
}
@media only screen and (min-width: 2000px) {
  .recommended-colleges-wrapper .trending-college-photo > img {
    max-height: 350px;
    height: 11vw;
    max-width: 500px;
  }
  .trending-college-block {
    max-width: 500px;
  }
  .recommended-colleges-wrapper .trending-college-description > h3 {
    font-size: 0.8vw;
    line-height: 0.8vw;
    height: 2vw;
  }
  .trending-college-description > p {
    font-size: 0.8vw;
    line-height: 0.8vw;
  }
  .trending-college-description > p > img {
    height: 0.8vw;
  }
  .trending-college-description {
    min-height: 3vw;
  }
  .save-university-icon {
    width: 1vw;
    padding: 0.5vw;
  }
  .save-university-icon > img {
    height: 1vw;
  }
}
