.container {
    font-family: 'Source Sans 3', sans-serif;
    line-height: 120%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 17rem;
    background-color: var(--light-purple);
    border-radius: 12px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    box-sizing: border-box;
}
.container * {
    box-sizing: border-box;
}

.picture {
    height: 9rem;
    width: 100%;
}

.plusIcon {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    margin: 0.75rem;
    right: 0;
    background-image: url("../../../images/plusIconSmallNotTransparent.svg");
    background-size: cover;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}
.plusIcon.selected {
    background-image: url("../../../images/minusForForm.svg");
}
.plusIcon:active {
    transform: scale(0.95);
}

.autocomplete {
    padding: .5rem 0;
    max-height: 13rem;
    top: 1.5rem;
    right: 0;
    width: 11.25rem;
    overflow-y: scroll;
}
.autocomplete > div {
    gap: .5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
}
.autocomplete > div > img {
    position: static;
}
.autocomplete > div:hover {
    background: rgba(126, 16, 110, 0.12);
}

.detailsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5rem;
    padding: 1rem;
    cursor: pointer;
}

.name {
    margin: 0;
    color: var(--primary-typography);
    font-size: 1rem;
}

.locationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
}
.locationIcon {
    width: 0.625rem;
    height: 0.75rem;
}
.locationText {
    margin: 0;
    color: var(--color-primary);;
    font-weight: 600;
    font-size: 0.875rem;
}

.mainInfo__Container,
.additionalInfo__Container,
.info__Container {
    display: flex;
    gap: .5rem;
}

.mainInfo__Container {
    flex-direction: column;
}

.info__Container {
    flex-direction: column;
    font-size: 0.875rem;
}

.additionalInfo__Container {
    flex-direction: row;
    justify-content: space-between;
}

.hiddenInfo {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    background-color: var(--primary-typography);
    color: var(--white-color);
    pointer-events: none;
    position: absolute;
    bottom: 48px;
    padding: 0.5rem;
    left: 1rem;
    right: 1rem;
    z-index: 999;
    border-radius: 4px;
    transition: 0.3s ease-in-out all;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.hiddenInfo__Secondary {
    font-size: 0.75rem;
    margin: 0;
}

.additionalInfo__Value {
    font-weight: 900;
}
.additionalInfo__Container__Hidden {
}

.additionalInfo__Container__Hidden:after {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 50%;
    bottom: 42px;
    width: 15px;
    height: 15px;
    background-color: var(--primary-typography);
    transition: 0.3s ease-in-out all;
    opacity: 0;
    visibility: hidden;
    clip-path: polygon(100% 45%, 0 45%, 50% 100%);
}
.additionalInfo__Container__Hidden:hover::after,
.additionalInfo__Container__Hidden:hover .hiddenInfo {
    opacity: 1;
    visibility: visible;
}

/* Lock card */
.containerLockCard {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    max-width: 33.75rem;
    padding: 2.5rem 5.5rem;
    background: var(--white-color);
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08); */
    font-family: 'Source Sans 3', sans-serif;
    line-height: 120%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 15rem;
    background-color: var(--light-purple);
    border-radius: 12px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    box-sizing: border-box;
}

.lockIcon {
    width: 1.5rem;
    height: 2rem;
}

.titleLockCard {
    font-size: 1.5rem;
    margin: 0 0 .2rem 0;
}

.subtitleLockCard {
    margin: 0.5rem 0;
    color: var(--headings-blue);
    font-size: 1.25rem;
}

.navButtonLockCard {
    background: var(--color-primary);
    border: none;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1.25rem;
    outline: none;
    padding: 0.375rem 3.5rem 0.375rem 3.375rem;
    text-align: center;
    text-decoration: none;
}


@media only screen and (max-width: 520px) {
    .containerLockCard  {
        padding: 1.5rem 3.5rem;
    }
}