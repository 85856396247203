.footer {
    position: relative;
    z-index: 0;
    background-color: var(--headings-blue);
}

.top-footer-block-landing {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px 50px 0px;
    position: relative;
    max-width: 1240px;
    width: 100%;
}

.top-footer-block {
    display: flex;
    justify-content: space-between;
    padding: 1.8vw 11.4vw 6.5vw 6.6vw;
}

.footer-block-title>img {
    max-width: 280px;
    width: 100%;
    max-height: 85.03px;
    height: 100%;
    margin-left: -10px;
}

.top-footer-block>div {
    text-decoration: none;
    color: white;
}

.top-footer-block-landing>div:nth-child(2) {
    width: 60%;
}

/* .first-footer-block>h4,
.second-footer-block>h4,
.second-footer-block-landing>h4,
.third-footer-block>h4,
.third-footer-block-landing>h4,
.fourth-footer-block>div>h4,
.fourth-footer-block>h4,
.fourth-footer-block-landing>div>h4,
.fourth-footer-block-landing>h4 {
    font-weight: 600;
    font-size: 1.2rem;
    color: #ffffff;
    margin: 1.3vw 0 1rem 0;
    cursor: pointer;
    position: relative;
    width: fit-content;
} */

.first-footer-block>h4>a,
.second-footer-block>h4>a,
.second-footer-block-landing>h4>a,
.third-footer-block>h4>a,
.third-footer-block-landing>h4>a,
.fourth-footer-block>h4>a,
.fourth-footer-block>div>h4>a .fourth-footer-block-landing>h4>a,
.fourth-footer-block-landing>div>h4>a {
    text-decoration: none;
    color: white;
}

.first-footer-block>p>a>span,
.second-footer-block>p>a>span,
.second-footer-block-landing>p>a>span,
.third-footer-block>p>a>span,
.third-footer-block-landing>p>a>span,
.fourth-footer-block>p>a>span,
.fourth-footer-block>div>p>a>span .fourth-footer-block-landing>p>a>span,
.fourth-footer-block-landing>div>p>a>span {
    text-decoration: none;
    color: white;
    opacity: 0.8;
}

.first-footer-block>h4:after,
.second-footer-block>h4:after,
.third-footer-block>h4:after,
.third-footer-block-landing>h4:after,
.fourth-footer-block>h4:after,
.fourth-footer-block>div>h4:after,
.fourth-footer-block-landing>div>h4:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.first-footer-block>h4:hover:after,
.second-footer-block>h4:hover:after,
.second-footer-block-landing>h4:hover:after,
.third-footer-block>h4:hover:after,
.third-footer-block-landing>h4:hover:after,
.fourth-footer-block>h4:hover:after,
.fourth-footer-block>div>h4:hover:after,
.fourth-footer-block-landing>div>h4:hover:after,
.fourth-footer-block-landing>div>h4:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.top-footer-block>div>p:after,
.top-footer-block>div>div>p:after,
.top-footer-block-landing>div>p:after,
.top-footer-block-landing>div>div>p:after,
.top-footer-block-landing>div>div>div>p:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 5px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.top-footer-block>div>p:hover:after,
.top-footer-block>div>div>p:hover:after,
.top-footer-block-landing>div>p:hover:after,
.top-footer-block-landing>div>div>p:hover:after,
.top-footer-block-landing>div>div>div>p:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.top-footer-block>div>.destination-2021:after,
.top-footer-block-landing>div>div>.destination-2021:after {
    transform: none;
    position: initial;
}

.top-footer-block>div>h3,
.top-footer-block>div>p {
    margin-bottom: 2.2vw;
    cursor: pointer;
    position: relative;
    width: fit-content;
    font-weight: 700;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-size: 1.1vw;
    line-height: 1.1vw;
    font-family: "Inter" !important;
}

.top-footer-block>div>p {
    font-size: 0.93vw;
    line-height: 1.1vw;
    margin: 0 0 1.1vw;
}

.first-footer-block>img {
    width: 16vw;
    cursor: pointer;
    margin-left: -0.7vw;
}

.soc-network {
    width: auto;
    height: 1.5rem;
    cursor: pointer;
}

.soc-network:hover {
    opacity: 0.65;
}

.footer-block-title {
    display: flex;
    flex-direction: column;
}

.top-footer-block>div .destination-2021 {
    font-weight: 700;
    font-size: 0.9vw;
    line-height: 1vw;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 2.2vw;
    font-family: "Inter" !important;
    white-space: nowrap;
}

.become-a-member-button, .log-in-button, .log-in-button-landing {
    background: var(--color-primary);
    border-radius: 80px;
    text-transform: uppercase;
    color: white;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    padding: 1rem;
    font-family: "Inter" !important;
    margin: 1rem 0;
    width: fit-content;
    line-height: 1;
}

.log-in-button, .log-in-button-landing {
    background: #00b4d8;
    color: white;
    margin: 0;
}

.become-a-member-button:hover {
    background: #00b4d8;
}

.first-footer-block-landing {
    display: flex;
    flex-direction: column;
}

.disable-hover {
    pointer-events: none;
}

.top-footer-block>.first-footer-block>p {
    font-weight: 600;
    font-size: 1.1vw;
    line-height: 1.6vw;
    margin: 0;
    text-transform: none;
}

.top-footer-block>.first-footer-block>p:last-child {
    font-size: 0.93vw;
    line-height: 1.3vw;
    margin-top: 1.7vw;
}

.fourth-footer-block .soc-net-icons {
    margin: 0 0 1.3vw 0;
}

.second-footer-block,
.third-footer-block,
.fourth-footer-block {
    margin-top: 1.9vw;
}

.footer-block-title>div {
    font-style: normal;
    font-size: 3vw;
    margin-top: 8%;
    height: 100%;
    color: #ffffff;
    white-space: nowrap;
}

/* New styles*/
.destination-date {
    color: white;
    font-size: 1rem;
    width: 100%;
    margin-top: 30px;
    font-family: "Inter" !important;
    font-weight: 500;
}

.landing-version {
    color: white;
    opacity: 0.8;
    font-family: "Inter" !important;
    font-size: 1rem;
    position: absolute;
    bottom: 2rem;
}

.footer-information-container {
    display: none;
    margin: 0;
}

.footer-description-mobile, .footer-rights-mobile {
    white-space: normal;
    color: white;
    font-size: 1rem;
    width: 100%;
    margin: 0 0 1rem 0;
    font-family: "Inter" !important;
}

.footer-rights-mobile {
    margin: 0 0 2rem 0;
}

.container-other-blocks-mobile {
    width: 100%;
    display: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    gap: 0.75em;
    row-gap: 2rem
}

.title-others-blocks-mobile {
    font-size: 1rem;
    color: #fff;
    font-family: "Inter" !important;
}

.other-blocks-link {
    color: white;
    font-size: 12px;
    font-family: "Inter" !important;
}

.footer>.top-footer-block-landing h4 {
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    margin: 0 0 1rem 0;
    position: relative;
    width: fit-content;
}

.second-title-block {
    margin-top: 30px;
}

.soc-net-icons {
    width: 100%;
}

.soc-net-icons>a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    width: calc(22px + 2*.5em);
    height: calc(22px + 2*.5em);
}

.container-other-block-landing {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 25px;
    justify-content: space-around;
    font-family: "Inter" !important;
}

.top-footer-block-landing .sub-title {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin: 0 0 1rem 0;
    width: 100%;
}

.top-footer-block>div>p,
.top-footer-block>div>div>p,
.top-footer-block-landing>div>p,
.top-footer-block-landing>div>div>p,
.fourth-footer-block-landing>div>p,
h3 {
    color: white;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.45em;
    height: 26.09px;
    cursor: pointer;
    position: relative;
    width: fit-content;
    padding-bottom: calc(1px / 2);
    margin: 0;
    font-family: "Inter" !important;
    word-break: break-all;
}

.end-list {
    margin-bottom: 20px !important;
}

.strong-class {
    font-weight: 700;
    opacity: 1 !important;
    color: #fff;
}


.policy-disclaimer-container-main {
    width: 100%;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #707070;
    padding: 40px 0px 40px 0px;
}

.policy-disclaimer-container {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 1240px;
    width: 100%;
}

.policy-disclaimer {
    font-family: 'Source Sans Pro' !important;
    font-size: 12px;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.45rem;
}

.italic {
    font-style: italic;
}

.first-footer-block-landing {
    width: 30%;
}

.second-footer-block-landing, .third-footer-block-landing, .fourth-footer-block-landing {
    width: 23.3%;
}

/*End new styles*/

@media only screen and (max-width: 1250px) and (min-width: 767px) {
    .top-footer-block {
        flex-wrap: wrap;
    }

    .top-footer-block-landing {
        padding: 50px 10px 50px 10px;
    }

    .policy-disclaimer-container-main {
        padding: 40px 10px 40px 10px;
    }

    .top-footer-block>.first-footer-block {
        display: flex;
        flex-direction: column;
        gap: 3.3vw;
        width: 100%;
    }

    .top-footer-block>.first-footer-block>p {
        font-size: 2.1vw;
        line-height: 3.2vw;
    }

    .top-footer-block>.first-footer-block>p:last-child {
        font-size: 1.8vw;
    }

    .top-footer-block>div>h3 {
        font-size: 2.15vw;
        line-height: 2.1vw;
        margin-bottom: 4.3vw;
    }

    .top-footer-block>div>p {
        font-size: 1.8vw;
        line-height: 2.1vw;
        margin: 0 0 2.1vw;
    }

    .first-footer-block {
        margin: 7.5vw 0 3.3vw;
    }

    .first-footer-block>img {
        width: 32.9vw;
    }

    .become-a-member-button,
    .log-in-button-landing {
        width: max-content;
        margin-left: 0;
    }

    .top-footer-block>div>p,
    .top-footer-block>div>div>p,
    .top-footer-block-landing>div>p,
    .top-footer-block-landing>div>div>p,
    .fourth-footer-block-landing>div>p,
    h3 {

        height: 24.09px;
        margin-bottom: .2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .destination-date {
        font-size: 14px;
    }
}

@media only screen and (max-width:766px) {
    .footer {
        padding-bottom: 15px;
    }

    .footer-block-title>img {
        width: 46.2vw;
    }

    .top-footer-block,
    .top-footer-block-landing {
        flex-wrap: wrap;
        position: relative;
        justify-content: flex-start;
        border: none;
    }

    .top-footer-block-landing>div:nth-child(2) {
        width: 100%;
    }

    .top-footer-block {
        padding: 5.1vw;
        padding-bottom: 9vw;
    }

    .top-footer-block>div {
        width: 100%;
    }

    .top-footer-block>.second-footer-block {
        width: 59%;
    }

    .top-footer-block>.third-footer-block {
        width: 41%;
    }

    .first-footer-block>img {
        width: 46.2vw;
        margin-left: -1.8vw;
    }

    .top-footer-block>.first-footer-block>p {
        font-family: "Inter" !important;
        font-weight: 400;
        font-size: 3.1vw;
        margin-top: 4.4vw;
        line-height: 4.1vw;
    }

    .top-footer-block>.first-footer-block>p:last-child {
        font-family: "Inter" !important;
        font-weight: 400;
        font-size: 3.1vw;
        margin-top: 5.9vw;
        line-height: 4.1vw;
    }

    .top-footer-block>div>h3 {
        margin-bottom: 8.5vw;
    }

    .top-footer-block>div>p {
        margin-bottom: 4.1vw;
    }

    .top-footer-block>div>h3,
    .top-footer-block>div>p {
        font-weight: 600;
        font-size: 3.6vw;
        line-height: 5.1vw;
    }

    .second-footer-block,
    .third-footer-block,
    .fourth-footer-block {
        margin-top: 13.6vw;
    }



    .first-footer-block-landing {
        width: 100%;
        margin-bottom: 2vw;
    }

    .fourth-footer-block>div>p {
        margin: 0;
        text-decoration: none;
    }

    .top-footer-block-landing .first-footer-block>p {
        font-size: 2.5vw;
        font-family: "Inter" !important;
        font-style: normal;
    }

    .first-footer-block>h4>a,
    .second-footer-block>h4>a,
    .third-footer-block>h4>a {
        font-size: 3vw;
    }

    .first-footer-block>h4,
    .second-footer-block>h4,
    .second-footer-block-landing>h4,
    .third-footer-block>h4,
    .third-footer-block-landing>h4,
    .fourth-footer-block-landing>h4,
    .fourth-footer-block-landing>div>h4 {
        font-size: 3.6vw;
        margin-top: 5.1vw;
    }

    .top-footer-block-landing .fourth-footer-block-landing {
        display: flex;
        flex-direction: column;
    }

    .second-footer-block-landing,
    .third-footer-block-landing,
    .fourth-footer-block-landing>div {
        width: 50%;
    }

    .second-title-block {
        margin-top: 4.6vw;
    }

    .footer-block-title>div {
        font-size: 4vw;
    }



    .third-footer-block-landing,
    .fourth-footer-block-landing>div:nth-child(2) {
        width: 44%;
    }

    .top-footer-block>.fourth-footer-block {
        margin-top: 9vw;
    }

    .fourth-footer-block .soc-net-icons {
        margin: -3.8vw 0 4.6vw 0;
    }

    .landing-version {
        margin: 0;
        font-size: medium;
    }

    .destination-date {
        margin: 1rem 0;
        font-size: medium;
    }

    .container-other-blocks-mobile {
        display: block;
    }

    .container-other-block-landing, .second-title-block {
        display: none;
    }

    .footer-information-container {
        display: block;
    }

    .soc-net-icons-mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
    }

    .soc-network-mobile {
        height: 1.25rem;
        width: auto;
    }

    .footer-mobile-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .footer-mobile-menu a {
        color: unset;
        font-size: 18px;
        font-family: "Inter" !important;
    }

    .footer-mobile-menu h4:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 5px;
        left: 0;
        background-color: white;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .footer-mobile-menu h4:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .policy-disclaimer-container-main {
        padding: 40px 20px 40px 20px;
    }

    .soc-net-icons {
        display: flex;
        justify-content: center;
    }

    .footer>.top-footer-block-landing h4 {
        margin-bottom: 20px;
    }

    .destination-date {
        text-align: center;
    }

    .footer-mobile-menu h4:after {
        bottom: 0;
    }

    .policy-disclaimer {
        text-align: center;
    }
}