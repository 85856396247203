.share-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f1f1f1;
  padding: 46px 0 40px;
}
.share-list__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1374px;
  padding: 48px 0 40px;
  background: #ffffff;
  border-radius: 12px;
}

.share-list__container__btn-save {
  align-self: center;
  width: 180px;
  height: 48px;

  color: var(--white-color);
  text-align: center;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  border: 0;
  border-radius: 32px;
  background: var(--headings-blue);
  cursor: pointer;
}

.share-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px 51px;
}

.share-list__name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #464646;
}
.share-list__name__blue {
  color: var(--headings-blue);
}
.share-list__colleges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 40px;
  gap: 20px;
}
.share-list__college {
  width: 240px;
  border-radius: 10px;
  margin-bottom: 40px;
  overflow: hidden;
  box-sizing: border-box;
}
.share-list__college__img-cont {
  width: 250px;
  height: 198px;
}
.share-list__college__img {
  height: 100%;
  width: 100%;
}
.share-list__college__info {
  padding: 11px 16px 8px;
  background: #f6f6f6;
  min-height: 75px;
}
.share-list__college__name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #464646;
  opacity: 0.8;
  margin: 0 0 5px;
}
.share-list__college__description__item {
  display: flex;
  align-items: center;
}
.share-list__college__description__item:nth-child(2) {
  margin: 5px 0 0;
}
.share-list__college__description__item > span:first-child {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #464646;
  opacity: 0.8;
}
.share-list__college__description__item > span:last-child {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #464646;
}
.share-list__delete__title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 4.034vw;
}
.share-list__delete__img__cont {
  text-align: center;
  height: 27.11vw;
  margin-bottom: 3.27vw;
}
.share-list__delete__img__cont img {
  height: 100%;
}

.share-list__college__description__item--second {
  justify-content: space-between;
}

.share-list__college__description__item > span.share-list__college__description__item__location-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #7e106e;
  opacity: 0.8;

  margin-left: 3px;
}

.share-list__checkout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 40px 0;
  gap: 20px;

  position: relative;
}

.share-list__checkout.first {
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #f1f1f1;
  padding: 40px 0;
  margin: 0 40px;
}

.share-list__checkout__blur {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  background: rgba(64, 64, 64, 0.50);
  backdrop-filter: blur(2px);
  z-index: 2;
}

.share-list__checkout__blur__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 12px;
  background: var(--white-color);


  width: 80%;
  height: 190px;
}

.share-list__checkout__blur__center__p {
  color: #464646;
  text-align: center;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.share-list__checkout__blur__center__cont-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 16px;
}

.share-list__checkout__blur__center__btn {
  width: 180px;
  padding: 9px 0;

  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: var(--white-color);
  text-align: center;
  border: 0;
  border-radius: 32px;
  background: var(--headings-blue);
  cursor: pointer;
}

.share-list__checkout__blur__center__btn.second {
  background-color: var(--color-primary);
}

.share-list__checkout__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #030054;
  white-space: pre-wrap;
  line-height: 29px;
}

.share-list__checkout__button {
  margin-top: 27px;
  padding: 10px 20px;
  background: var(--color-primary);
  border-radius: 30px;


  font-family: 'Montserrat',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@media only screen and (max-width: 744px) {
  .share-list {
    padding-top: 0;
  }

  .share-list__checkout__blur__center__p {
    font-size: 24px;
  }

  .share-list__checkout__blur__center__btn {
    font-size: 22px;
  }
}

@media only screen and (max-width: 550px) {
  .share-list__name {
    font-size: 20px;
  }

  .share-list__checkout__blur__center {
    gap: 30px;
  }
}

@media only screen and (max-width: 475px) {
  .share-list__header {
    flex-direction: column;
    margin-bottom: 25px;
  }

  .share-list__checkout__title {
    font-size: 20px;
  }

  .share-list__checkout__blur__center__p {
    font-size: 20px;
  }

  .share-list__checkout__blur__center__cont-btns {
    flex-direction: column;
    gap: 5px;
  }

  .share-list__checkout__blur__center {
    height: 205px;
    gap: 20px;
  }
}

@media only screen and (max-width: 391px) {
  .share-list__colleges {
    gap: 30px;
  }

  .share-list__college__img-cont,
  .share-list__college {
    width: 100%;
  }

  .share-list__college__name {
    color: var(--headings-blue);
  }

  .share-list__name {
    text-align: unset;
    margin: 0 0 20px;
    line-height: 29px;
  }
}