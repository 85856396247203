.my-list {
  background: rgba(241, 241, 241, 1);
}

.w-custom-1 {
  width: 20%;
}

.w-custom-2 {
  width: 30%;
}

.my-list-navigation {
  font-family: "Source Sans 3", sans-serif;
  font-size: 1.7vw;
  display: flex;
  justify-content: space-evenly;
  width: 88.4vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.my-list-navigation>a,
.my-list-navigation>span {
  font-weight: 600;
  color: var(--headings-blue);
  padding: 4.1vw 0 0.7vw;
  text-decoration: none;
}

.my-list-college>div {
  padding: 0.9vw 1.3vw;
  display: flex;
  justify-content: space-between;
}

.my-list-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4.6vw;
  border-bottom-left-radius: 1.1vw;
  border-bottom-right-radius: 1.1vw;
  background-color: white;
}

.list-colleges-wrapper {
  background-color: white;
  margin: 0 4.6vw;
  padding: 2rem;
  border-radius: 1rem;
}

.list-colleges-wrapper .input-select {
  display: none;
}

.input-select-list {
  position: relative;
  width: 38.76vw;
  margin: 3.97vw 0;
}

.input-select-list>img {
  position: absolute;
  width: 1vw;
  right: 4%;
  top: 45%;
  cursor: pointer;
}

.input-select-list>.autocomplete, .sort-input>.autocomplete {
  width: 100%;
  padding: 0 !important;
  border: 1px solid #cdcdcd !important;
  border-radius: 0 !important;
  top: 6rem;
}

.input-select-list>.autocomplete>.options:hover, .sort-input>.autocomplete>.options:hover {
  background: rgba(126, 16, 110, 0.40);
}

.sort-input>.autocomplete {
  top: 6rem;
}


.input-select-list>.autocomplete>div,
.sort-input>.autocomplete>div {
  font-size: 1.2vw;
}

.input-select-list>.autocomplete>div>img {
  height: 1.2vw;
}

.input-select-list>.custom-input {
  border: 1px solid var(--border-color);
  border-radius: 50px;
  padding: 1.65vw 2.12vw;
  font-style: normal;
  font-weight: 600;
  font-size: 1.19vw;
  color: #464646b2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.custom-input>span {
  border-radius: 59px;
  color: var(--color-primary);
  margin-left: 10px;
  background: #7e106d23;
  font-weight: 500;
  font-size: 1.06vw;
  padding: 2px 8px;
}

.my-list-navigation>.selected-nav {
  position: relative;
  color: var(--color-primary);
  border-bottom: 0.3vw solid var(--color-primary);
  border-radius: 0.3vw;
  width: -moz-fit-content;
  width: fit-content;
}

.hr-my-list {
  background: rgba(241, 241, 241, 1);
  height: 0.86vw;
  width: 100%;
  margin-bottom: 3.9vw;
}

.my-list-body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-header-buttons {
  display: flex;
}

.list-header-buttons>a,
.list-header-buttons>button {
  height: 40px;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  text-decoration: none;
  width: 240px;
  box-sizing: border-box;
}

.list-header-buttons>button:first-child {
  margin-right: 1.3vw;
  padding: 0.58vw 1.7vw;
}

.list-header-buttons>a>img,
.list-header-buttons>button>img {
  margin-right: 10px;
  height: 1.1vw;
}

.list-header-buttons>button:first-child>img {
  height: 1.3vw;
}

.list-name {
  display: flex;
  gap: 8px;
  font-weight: 600;
  color: var(--headings-blue);
  font-size: 24px;
  margin-bottom: 0.66vw;
  text-wrap: wrap;
}

.edit-list-button {
  position: relative;
}

.share-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}

.share-icon::before {
  content: attr(data-tip);
  text-align: center;
  background-color: #403e59;
  color: var(--white-color);
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 5px);
  padding: 5px 10px;
  width: auto;
  border-radius: 10px;
  font-size: 16px;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.share-icon::after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 15px;
  background-color: #403e59;
  transition: 0.2s ease-in-out all;
  opacity: 0;
  visibility: visible;
  clip-path: polygon(100% 45%, 0 45%, 50% 100%);
}

.share-icon:hover::before,
.share-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

.share-icon--open:hover::after,
.share-icon--open:hover::before {
  opacity: 0;
  visibility: hidden;
}

.list-name>.share-icon>img {
  width: 21px;
  height: 21px;
  margin: 0 0 2px 2px;
}

.dropdown-menu {
  visibility: hidden;
  padding: 8px 0;
  box-sizing: border-box;
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px -8px rgba(47, 45, 70, 0.06), 0 8px 32px rgba(47, 45, 70, 0.14);
  border-radius: 10px;
  position: absolute;
  width: max-content;
  z-index: 900;
  left: 20px;
  top: 20px;
  transition: 0.3s ease-in-out all;
}

.dropdown-menu.active {
  visibility: visible;
}

.dropdown-menu>* {
  box-sizing: border-box;
}

.dropdown-menu>.dropdown-menu__option {
  padding: 8px 11px;
  margin: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--primary-typography);
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

.dropdown-menu>.dropdown-menu__option:hover {
  background: rgba(126, 16, 110, 0.1);
}

.my-list-body-header__secondary-header>.share-icon>.share-icon-img {
  height: 1rem;
  width: 1rem;
}

.my-list-body-header__secondary-header>.unsave-icon-img {
  height: 1.5rem;
  width: auto;
}

.my-list-body-header__secondary-header {
  display: flex;
  gap: 8px;
  font-weight: 600;
  color: var(--color-primary);
  font-size: 16px;
}

.list-details {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
  width: 45%;
}

.list-details>div {
  display: flex;
  align-items: center;
}

.list-details>div img {
  cursor: pointer;
}

.my-list-checkboxes {
  display: flex;
  justify-content: space-between;
  border: 2px solid #c2c2c2;
  border-radius: .5rem;
  padding-left: 0;
  margin-right: 2rem;
  height: 50px;
}

.my-list-checkboxes>.my-list-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 0;
  position: relative;
}

.my-list-checkboxes .my-list-checkbox-container .checkmark-title {
  color: #7e1063;
  border-color: #7e1063;
  font-size: 1rem;
  white-space: nowrap;
}

.my-list-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.my-list-checkmark {
  border: 2px solid var(--primary-typography);
  border-radius: 1rem;
  height: 1.25rem;
  width: 1.25rem;
  background: none;
  margin-right: .5rem;
  position: relative;
}

.my-list-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.my-list-checkbox-container input:checked~.my-list-checkmark:after {
  display: block;
}

.my-list-checkbox-container .my-list-checkmark:after {
  left: 30%;
  width: 8px;
  height: 12px;
  border: solid var(--color-primary);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sort-input {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.sort-input>img {
  margin-left: 0.66vw;
  cursor: pointer;
}

.sort-input>input {
  border: 1px solid #d9d9d9;
  border-radius: .5rem;
  padding: 0 1rem;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #464646b2;
  width: 100%;
  height: 50px;
}

.sort-input>#sort {
  position: absolute;
  width: 1rem;
  right: 4rem;
}

.sorting-block {
  width: 30%;
}

.sorting-block>div>.autocomplete {
  width: 92%;
  z-index: 1000;
}

.sort-header>img {
  height: 2.5vw;
  cursor: pointer;
}

.sort-header>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.06vw;
  margin-top: 0;
}

.selected-list {
  display: flex;
  justify-content: flex-start;
  gap: 1.8vw;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

.selected-list.not-allowed-list {
  position: relative;
}

.selected-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.selected-list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.selected-list::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}

.selected-list-without-rec {
  justify-content: center;
}

.my-list-line {
  height: 5px;
  background: #7e106e;
  opacity: 0.2;
  margin: 2vw 0;
}

.button-next-college {
  opacity: 1;
  background: rgba(0, 0, 0, 0.278);
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 100.5%;
  width: 10%;
  position: absolute;
  right: 0;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  z-index: 0;
  padding: 15vh 0;
  box-sizing: border-box;
}

.button-next-college-additional {
  height: 185%;
}

.button-next-college>img {
  position: sticky;
  top: 35%;
}

.button-next-college.left {
  left: 0;
}

.button-next-college>img {
  width: 30%;
}

.delete-list {
  display: flex;
  justify-content: space-between;
  padding: 1.65vw 0 2.38vw;
  width: 100%;
}

.delete-list>span {
  padding: 0;
  font-weight: 600;
  font-size: 1.322vw;
  border-bottom: 0.3vw solid var(--color-primary);
  border-radius: 3px;
  line-height: 3vw;
  color: var(--color-primary);
  cursor: pointer;
}

.button-next-college>.left-arrow {
  transform: rotate(180deg);
}

.no-colleges-block-my-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  justify-content: center;
}

.pagination-navigation {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
  align-items: center;
}

.delete-list>.pagination-navigation {
  padding: 0;
  justify-content: flex-end;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pagination-navigation>input {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0.5vw;
  margin-right: 1vw;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.06vw;
  text-align: center;
  color: var(--headings-blue);
  width: 4vw;
}

.pagination-navigation>button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-navigation>button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-navigation>button {
  border: 1px solid #d9d9d9;
  padding: 0.4vw 1vw;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1.06vw;
  text-align: center;
  color: #cccbcb;
  background: white;
  cursor: pointer;
}

.pagination-navigation>span {
  padding-right: 1vw;
}

.switch {
  position: relative;
  display: inline-block;
  margin-left: 0.52vw;
  width: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-round {
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: 1px solid var(--primary-typography);
  transition: 0.4s;
  font-size: inherit;
  height: 26px;
  width: 50px;
}

.slider-round:before {
  position: absolute;
  content: '';
  height: 80%;
  width: 40%;
  left: 3px;
  bottom: 10%;
  background-color: var(--primary-typography);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider-round {
  border-color: var(--color-primary);
}

input:focus+.slider-round {
  box-shadow: 0 0 1px var(--color-primary);
}

input:checked+.slider-round:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
  background-color: var(--color-primary);
}

.slider-round {
  border-radius: 3vw;
}

.slider-round:before {
  border-radius: 50%;
}

.no-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 3.8vw 10px;
  border-radius: 1.1vw;
}

.no-list-container-table {
  margin: 20px 0;
}

.no-list-container>h6 {
  font-weight: 600;
  font-size: 1.25vw;
  color: #030054;
  margin: 0 0 12px;
}

.no-list-container>img {
  width: 160px;
  height: 150px;
  margin-bottom: 20px;
}

.no-list-container>a,
.no-list-container>button {
  width: 200px;
  padding: 10px 30px;

  color: white;
  font-weight: 600;
  font-size: 1.2vw;
  font-family: 'Source Sans 3', sans-serif;
  border: 1px solid #7e106e;
  border-radius: 20px;
  background: #7e106e;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
}

.primary-button-list-first {
  background: #a7a7a7;
}

.add-notes-btn {
  margin-right: 15px;
}

.pagination-navigation.navigation-start {
  justify-content: flex-start;
  align-items: flex-end;
}

.switcher-pagination-my-list {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 240px;
  height: 41px;
  background: #f1f1f1;
  border: 2px solid rgba(126, 16, 110, 0.5);
  border-radius: 0.5rem;
}

.fake-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 41px;
}

.switcher-pagination-my-list-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #464646;
  padding: 0;
  margin: 0;
}

.switcher-pagination-my-list-text-second {
  justify-content: center;
  border-radius: 0 8px 8px 0;
}

.switcher-pagination-my-list-text.active {
  color: var(--color-primary);
  background-color: rgba(126, 16, 110, 0.2);
}

.table-pagination-header,
.table-pagination-row {
  justify-content: flex-start;
  min-height: 70px;
  cursor: pointer;
  position: relative;

  display: flex;
  background: var(--white-color);
  width: max-content;
}

.table-pagination-row {
  padding: 0;
}

.table-pagination-row.checked {
  /*background: #7E106E31;*/
}

.table-pagination-row>div.table-pagination-row-scholarships-container,
.table-pagination-row>div.table-pagination-row-admissions-container,
.table-pagination-row>div.table-pagination-row-profile-container,
.table-pagination-row>div.table-pagination-row-main-container,
.table-pagination-header>div:last-child,
.table-pagination-row>div:last-child>div:last-child,
.table-pagination-row>div:last-child {
  border-right: 0;
}

.table-pagination-header-cont-finance-content-main {
  padding-right: 25px;
}

.table-pagination-row-odd {
  background: #e4e4e4;
}

.table-pagination-header>div,
.table-pagination-row>div {
  min-width: 100px;
}

.table-pagination-row:hover {
  transition: all 0.8s;
}

.table-pagination {
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 140px;
  max-height: 785px;
}

.class {
  display: flex;
  flex-direction: row;
  gap: 25px;
  background: black;
}

.table-pagination::-webkit-scrollbar {
  width: 0.7vw;
  height: 0.7vw;
  cursor: pointer;
}

.table-pagination::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.7vw;
}

.table-pagination::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 0.7vw;
  cursor: pointer;
}

.table-pagination-row>div>a {
  display: flex;
  flex-direction: column;
}

.table-pagination-row>div>a>img {
  width: 15px;
}

.table-pagination-row>div>a>img.table-pagination-row-container-name-bookmark,
.table-pagination-row>div>a>img.table-pagination-row-container-name-notes {
  width: 24px;
  height: 24px;
}

.table-pagination-header-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-pagination-header-cont__first {
  justify-content: space-between;
}

.table-pagination-header-cont-name {
  margin-left: 0;
  width: 260px;
}

.header-grid-text-align-center {
  text-align: center;
}

.header-grid-text-align-start {
  text-align: start;
}

.table-pagination-header-text {
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  text-align: start;
  transition: color 0.8s;
  padding-left: 5px;
}

.table-pagination-header-text__first {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 90%;
}

.table-pagination-header-checkbox-cont {
  gap: 4px;
}

.table-pagination-header-text__checkbox {
  width: 20px !important;
}

.table-pagination-header-text__checkbox__input {
  margin-right: 0 !important;
}

.table-pagination-header-cont__arrow {
  display: block;
  width: 12px;
  height: 9px;
  margin: 0;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../images/arrowInActive.svg");
  transform: rotate(180deg);

  position: relative;
}

.table-pagination-header-cont__arrow:hover {
  background-image: url("../../images/arrowActive.svg");
}

.table-pagination-header-cont__arrow.opened {
  background-image: url("../../images/arrowActive.svg");
  transform: rotate(0deg);
}

.table-pagination-header-cont__arrow__autocomplete {
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 9999;
  width: 80px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 27px -5px rgba(0, 0, 0, 0.32);

  padding: 12px 0;
}

.table-pagination-header-cont__arrow__autocomplete>p {
  margin: 0;
  padding: 4px 12px;
  text-align: start;
  color: #464646;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

}

.table-pagination-header-cont__arrow__autocomplete>p:hover {
  background: rgba(126, 16, 110, 0.12);
}

.table-pagination-header-cont__dots {
  display: block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../images/threeDotsInActive.svg");

  position: relative;
}

.table-pagination-header-cont__dots:hover,
.table-pagination-header-cont__dots.opened {
  background-image: url("../../images/threeDotsActive.svg");
}

.table-pagination-header-cont__dots__autocomplete {
  position: absolute;
  top: 33px;
  left: 75px;
  z-index: 9999;
  width: 250px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 1px 27px -5px rgba(0, 0, 0, 0.32);
  padding: 16px 0;

  max-height: 160px;
  overflow-y: auto;
}

.table-pagination-header-cont__dots__autocomplete>span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  text-align: start;
}

.table-pagination-header-cont__dots__autocomplete>span:hover {
  background: rgba(126, 16, 110, 0.12);
}

.table-pagination-header-cont__dots__autocomplete>span>img {
  width: 20px;
  height: 20px;
}

.table-pagination-header-cont__dots__autocomplete>span>p {
  color: #464646;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
}

.table-pagination-header-text:hover {
  color: #00b4d8;
}

.table-pagination-header-cont-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  padding-right: 3px;
}

.table-pagination-header-img-violet,
.table-pagination-header-img-grey {
  display: block;
  background-image: url('../../images/arrowPaginationTableLists.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 10px;
  transition: all 0.8s;
}

.table-pagination-header-img-grey:hover,
.table-pagination-header-img-violet:hover {
  background-image: url('../../images/arrowPaginationTableListsBlue.svg');
}

.table-pagination-header-img-grey.selected,
.table-pagination-header-img-violet.selected {
  background-image: url('../../images/arrowPaginationTableListsBlue.svg');
}

.table-pagination-header-img-rotated {
  transform: rotate(180deg);
  margin-left: -1.5px;
}

.list-colleges-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 1.1vw;
  box-sizing: border-box;
  position: relative;
  max-width: 1375px;
  margin: 3rem auto;
}

.list-colleges-cont-with-list {
  padding-top: 55px;
  margin-top: unset;
}

.table-pagination-row-container-name {
  display: flex;
  align-items: center;
  gap: 0;
  width: 388px !important;

  justify-content: space-between;
}

.table-pagination-row-container-name.checked {
  /*background: #7E106E31;*/
}

.table-pagination-row-container-name__checkbox {
  width: 88px !important;
  min-width: unset !important;

  display: flex;
  justify-content: center !important;
  align-items: center;
}

.table-pagination-row-container-name__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80% !important;
  height: 100%;
  border-left: 1px solid #c2c2c2;
  gap: 15px;
}

.table-pagination-row-relative {
  position: relative;
  background: var(--white-color);
}

.table-pagination-row-relative-without-sub {
  filter: blur(5px);
  backdrop-filter: blur(5px);
}

.table-pagination-row-relative-autocomplete {
  left: 20px;
  width: initial !important;
  z-index: 10000000;
  top: 50px;
  padding: 0 15px;
}

.table-pagination-row-relative-autocomplete-last {
  bottom: 50px;
  top: unset;
}

.table-pagination-row-relative-autocomplete>div {
  white-space: nowrap;
}

.table-pagination-row-container-name,
.table-pagination-header-cont-name {
  position: sticky;
  left: -0.5px;
  background: var(--white-color);
  z-index: 100000;
}

.table-pagination-container-for-name-sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.table-pagination-header-cont-name:hover {
  z-index: 100;
}

.table-pagination-row-container-name {
  background: #e4e4e4;
}

.table-pagination-row-container-name-odd {
  background: var(--white-color);
}

.table-pagination-row-container-save-autocomplete {
  width: 400%;
}

.table-pagination-row-name,
.table-pagination-row-state,
.table-pagination-row-region,
.table-pagination-row-overall-admit,
.table-pagination-row-early-admission,
.table-pagination-row-need-met,
.table-pagination-row-merits-offered,
.table-pagination-row-avg-gpa,
.table-pagination-row-cost-of-attendance,
.table-pagination-row-coop,
.table-pagination-row-website,
.table-pagination-row-endowment,
.table-pagination-row-underwomen,
.table-pagination-row-undermen,
.table-pagination-row-graduation-rate,
.table-pagination-row-white-non,
.table-pagination-row-black-non,
.table-pagination-row-latino-non,
.table-pagination-row-asian-non,
.table-pagination-row-alien-non,
.table-pagination-row-avgFreshmenGpa,
.table-pagination-row-sat,
.table-pagination-row-testScoreRequirementType,
.table-pagination-row-act,
.table-pagination-row-admit-rate,
.table-pagination-row-app-fee,
.table-pagination-row-edDeadline {
  font-family: 'Nunito Sans', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #464646;
  white-space: normal;
  text-align: center;
}

.table-pagination-row-testScoreRequirementType {
  white-space: pre-wrap;
  text-align: center;
}

.table-pagination-row-name {
  white-space: normal;
  color: #00b4d8;
  transition: all 0.8s;
}

.table-pagination-row-container-name-notes-container {
  width: 100px;
}

.table-pagination-row-container-name-bookmark,
.table-pagination-row-container-name-notes {
  width: 24px;
  height: 24px;
}

.table-pagination-row-container-name-bookmark {
  padding-left: 10px;
}

.table-pagination-row-container-save-autocomplete {
  position: absolute;
  z-index: 10;
}

.table-pagination-row-container-name-drag-drop {
  width: 20px;
  height: 24px;
}

.table-pagination-row-container-type,
.table-pagination-row-container-state,
.table-pagination-row-container-region,
.table-pagination-row-container-camera,
.table-pagination-row-container-overall-admit,
.table-pagination-row-container-early-admission,
.table-pagination-row-container-need-met,
.table-pagination-row-container-merits-offered,
.table-pagination-row-container-avg-gpa,
.table-pagination-row-container-cost-of-attendance,
.table-pagination-row-container-coop,
.table-pagination-row-container-website,
.table-pagination-row-container-endowment,
.table-pagination-row-container-underwomen,
.table-pagination-row-container-undermen,
.table-pagination-row-container-graduation-rate,
.table-pagination-row-container-white-non,
.table-pagination-row-container-black-non,
.table-pagination-row-container-latino-non,
.table-pagination-row-container-asian-non,
.table-pagination-row-container-alien-non,
.table-pagination-row-container-avgFreshmenGpa,
.table-pagination-row-container-sat,
.table-pagination-row-container-testScoreRequirementType,
.table-pagination-row-container-act,
.table-pagination-row-container-admit-rate,
.table-pagination-row-container-app-fee,
.table-pagination-row-container-edDeadline,
.table-pagination-row-container-scholarships,
.table-pagination-row-container-regular {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.table-pagination-row-container-type>span {
  position: relative;
  width: 24px;
  height: 24px;
}

.table-pagination-row-container-type>span::before {
  content: attr(data-tip);
  text-align: center;
  background-color: #faf6f6;
  pointer-events: none;
  position: absolute;
  z-index: 103;
  bottom: calc(100% + 5px);
  padding: 5px 10px;
  width: auto;
  border-radius: 10px;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  color: #00b4d8;
  font-size: 16px;
  left: -20px;
}

.table-pagination-row-container-type>span::after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 15px;
  background-color: #faf6f6;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  visibility: visible;
  clip-path: polygon(100% 45%, 0 45%, 50% 100%);
  left: 7.5px;
  z-index: 103;
}

.table-pagination-row-container-type>span:hover::before,
.table-pagination-row-container-type>span:hover::after {
  opacity: 1;
  visibility: visible;
}

.table-pagination-row-name:hover {
  color: #7e1063;
}

.table-pagination-row-container-camera-img {
  width: 20px;
}

.table-pagination-row-camera-img {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/camera.svg');
  width: 30px;
  height: 30px;
  transition: all 0.8s;
}

.table-pagination-row-camera-img:hover {
  background-image: url('../../images/cameraViolet.svg');
}

.selected-nav-delete-bottom {
  margin-left: 70px;
}

.pagination-navigation-bottom {
  margin-right: 70px;
}

.my-list-page-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2.3vw 9.5vw 2.3vw 0;
}

.my-list-page-cont.list-view {
  padding: unset;
}

.my-list-pagination-table-drop-down {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-users-pagination-drop-down-input.my-list-pagination-table-drop-down-input {
  width: 100px;
}

.my-list-pagination-table-drop-down-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 9vw;
  padding: 30px 0;
}

.table-pagination-row-website-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  gap: 5px;
}

.table-pagination-row-website-link>p {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #00b4d8;
  transition: all 0.8s;
}

.table-pagination-row-website-link-img {
  width: 20px;
  height: 20px;
  background-image: url('../../images/goToWebsite.svg');
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.8s;
}

.table-pagination-row-website-link:hover>p {
  color: var(--color-primary);
}

.table-pagination-row-website-link:hover>.table-pagination-row-website-link-img {
  background-image: url('../../images/goToWebsiteViolet.svg');
}

.table-pagination-row-container-scholarships-btn {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #7e106e;
  white-space: nowrap;
  background: var(--white-color);
  border: 2px solid #c2c2c2;
  border-radius: 40px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.8s;
  text-decoration: none;
}

.table-pagination-row-container-scholarships-btn-locked {
  padding-left: 25px;
  color: black;
}

.table-pagination-row-container-scholarships-btn-locked:hover {
  border-color: black;
}

.header-grid-text-color-grey>span {
  color: var(--white-color);
  text-align: center;
  transition: all 0.8s;
}

.header-grid-text-color-grey>span:hover {
  color: #00b4d8;
}

.table-pagination-row-container-img-locked {
  position: absolute;
  left: -23px;
}

.table-pagination-row-container-img-another-locked {
  position: absolute;
  left: 39px;
}

.table-pagination-row-container-img-another-locked-1 {
  left: 37px;
}

.table-pagination-row-container-scholarships {
  position: relative;
  width: 130px;
}

.pagination-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 70, 70, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 142%;
  z-index: 1;
  top: 78px;
  height: 350px;
  width: 470px;
}

.pagination-modal-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  top: calc(49% - 80px - 2%);
  left: calc(50% - 150px - 5%);
  width: 300px;
}

.pagination-modal-content>img {
  width: 40px;
}

.pagination-modal-content>p {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #030054;
  margin: 0;
}

.pagination-modal-content>span {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #030054;
  margin: 0;
}

.pagination-modal-content>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 209px;
  height: 45px;
  background: var(--color-primary);
  border-radius: 40px;
  text-decoration: none;
  font-size: 16px;
  color: var(--white-color);
  margin-left: 5px;
  transition: all 0.8s;
  box-sizing: border-box;
}

.pagination-modal-content>a:hover {
  color: var(--color-primary);
  background: var(--white-color);
  border: 1px solid var(--color-primary);
}

.table-pagination-row-main-container,
.table-pagination-row-profile-container,
.table-pagination-row-admissions-container,
.table-pagination-row-scholarships-container,
.table-pagination-row-finance-container,
.table-pagination-header {
  display: flex;
}

.table-pagination-row>.table-pagination-row-container-name,
.table-pagination-row>div>div,
.table-pagination-header>div {
  width: 120px;
  min-width: 120px;
}

.table-pagination-row>.table-pagination-row-container-name,
.table-pagination-header>.table-pagination-header-cont-name {
  width: 300px;
  min-width: 300px;
}

.table-pagination-header>.table-pagination-header-cont-name {
  background: #030054;
}

.table-pagination-header-cont-main-content-profile,
.table-pagination-header-cont-main-content-main,
.table-pagination-row-profile-container>div,
.table-pagination-row-main-container>div,
.table-pagination-header-cont-main-content-admissions,
.table-pagination-row-admissions-container>div,
.table-pagination-header-cont-main-content-scholarships,
.table-pagination-row-scholarships-container>div,
.table-pagination-header-cont-main-content-finance,
.table-pagination-row-finance-container>div,
.table-pagination-row>div,
.table-pagination-header>div {
  border-right: 1px solid #c2c2c2;
}

.table-pagination-row-container-avgFreshmenGpa,
.table-pagination-header-cont-main-content-scholarships,
.table-pagination-header-cont-main-content-profile,
.table-pagination-header-cont-main-content-admissions {
  position: relative;
}

.table-pagination-header>.pagination-modal-1 {
  left: -2px;
  width: 720px;
}

.table-pagination-header>.pagination-modal-2 {
  left: 598px;
  width: 1441px;
}

.table-pagination-header>.pagination-modal-3 {
  left: 478px;
  width: 361px;
}

.table-pagination-row-blur {
  filter: blur(5px);
  pointer-events: none;
}

.table-pagination-row-relative-locked-modal {
  position: absolute;
  width: 100%;
  background: var(--white-color);
  border-radius: 10px;
  top: 447.5px;
  left: 0;
  right: 0;
  z-index: 15;
  height: 70px;
  box-shadow: 0 0 20px -3px rgba(237, 255, 249, 0.83);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.table-pagination-row-relative-locked-modal-img {
  width: 25px;
  height: 25px;
}

.table-pagination-row-relative-locked-modal-locked-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #030054;
  margin: 0;
  padding: 0;
}

.table-pagination-row-relative-locked-modal-more-info {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #464646;
  margin: 0;
  padding: 0;
}

.table-pagination-row-relative-locked-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 35px;
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--white-color);
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  transition: all 0.5s;
}

.table-pagination-row-relative-locked-modal-btn:hover {
  background: var(--white-color);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.table-pagination-row-relative-autocomplete>.options {
  width: 255px;
}

.table-pagination-no-result {
  margin: 15px 0;
}

.table-pagination-header-cont-main-content-main {
  background: #030054;
}

.table-pagination-header-cont-main-content-main {
  position: sticky !important;
  top: 0 !important;
}

.table-pagination-header-cont-main-content-profile {
  background: #030054;
}

.table-pagination-row-finance-container>div:last-child {
  border-right: 0;
}

.table-pagination-header-cont-name {
  background: #030054;
  border-right: 1px solid #c2c2c2;
  height: 70px;
  width: 300px;
  min-width: 300px;
  cursor: pointer;
  justify-content: space-between;
}

.table-pagination-header-cont__name-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border-left: 1px solid #c2c2c2;
  height: 100%;
}

.table-pagination-container-for-name-sticky {
  display: flex;
  background: #030054;
  width: max-content;
  z-index: 101;
}

.table-pagination-row-region {
  white-space: pre-wrap;
  text-align: center;
}

.list-colleges-wrapper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.list-colleges-wrapper-btn-download {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: var(--color-primary);
  background: var(--white-color);
  border: 1px solid var(--color-primary);
  border-radius: 30px;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  width: 240px;
  height: 40px;
}

.list-colleges-wrapper-btn-download-disabled {
  color: #a7a7a7;
  border-color: #a7a7a7;
}

.list-colleges-wrapper-btn-download::before {
  content: '';
  display: block;
  width: 25px;
  height: 20px;
  background-image: url('../../images/download-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  left: 15%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.list-colleges-wrapper-btn-download-disabled::before {
  background-image: url('../../images/download-arrow-grey.svg');
}

.table-pagination-row-container-name-limit>.table-pagination-row-relative-autocomplete {
  max-height: 130px;
}

.sort-input>img {
  width: 1rem;
}

/*New CSS*/
.my-list-checkboxes {
  width: 70%;
}

@media only screen and (max-width: 1300px) {
  .my-list-checkboxes {
    width: 100%;
    margin-bottom: 1rem;
  }

  .pagination-navigation {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .pagination-navigation {
    display: flex;
  }

  .my-list-checkboxes {
    flex-direction: column;
    width: 60%;
  }

  .fake-element {
    display: none;
  }

  .my-list-checkbox-container {
    width: 100% !important;
    padding: 1rem 0;
  }

  .sorting-block {
    width: 40%;
  }

  .slider-round:before {
    width: 42%;
  }

  .delete-list {
    margin: 0;
  }

  .no-list-container>h6 {
    font-size: 2.4vw;
  }

  .no-list-container>a,
  .no-list-container>button {
    font-size: 1.5vw;
  }

  .slider-round {
    font-size: 1vw;
    padding: 0.7vw 3vw;
  }

  input:checked+.slider-round:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }

  .my-list-checkboxes>div {
    gap: 5px;
    padding-left: 10px;
    justify-content: flex-start;
  }

  .my-list-checkboxes>div>.my-list-checkbox-container {
    padding-left: 4vw;
  }

  .my-list-checkboxes>div>.my-list-checkbox-container {
    width: 180px;
    margin-right: 0;
  }

  .sorting-block>div>.autocomplete {
    width: 86%;
  }

  .college-header-my-list>span {
    font-size: 2.5vw;
  }

  .selected-list {
    gap: 3.2vw;
  }

  .selected-list>.my-list-college {
    width: 43vw;
  }

  .list-details>.list-name {
    margin-bottom: 2.1vw;
  }

  .list-details>.list-name {
    font-size: 11px;
  }

  .sort-input>#sort {
    right: 5vw;
    top: 2vw;
  }

  .my-list {
    padding-top: 0;
  }

  .my-list>.hr-my-list {
    height: 3.2vw;
    margin: 0;
  }

  .list-colleges-wrapper {
    padding: 2.6vw 5.3vw 0.5vw;
  }

  .my-list-navigation {
    justify-content: space-between;
    padding: 0 5.8vw 1.3vw;
  }

  .my-list-navigation>a,
  .my-list-navigation>span,
  .list-details>.list-name {
    font-size: 3.2vw;
  }

  .my-list-navigation>a,
  .my-list-navigation>span {
    margin: 0 4.7vw;
  }

  .my-list-navigation>.selected-nav {
    border-bottom: 0.5vw solid var(--color-primary);
  }

  .my-list-header {
    margin: 0;
    padding: 1.9vw 0 9.4vw;
  }

  .input-select-list>.custom-input,
  .delete-list>span,
  .custom-input>span,
  .list-header-buttons>button,
  .list-details>div,
  .sort-header>p,
  .my-list-checkboxes p,
  .sort-input>input,
  .input-select-list>.autocomplete>div,
  .sort-input>.autocomplete>div {
    font-size: 2.15vw;
  }

  .list-details>div>img,
  .list-header-buttons>a>img,
  .list-header-buttons>button>img,
  .input-select-list>.autocomplete>div>img {
    height: 18px;
  }

  .list-details>.list-name img {
    height: 24px;
    width: 24px;
  }

  /* .my-list-checkmark {
    width: 13px;
    height: 13px;
    border-radius: 6px;
    top: 12px;
  } 

  .my-list-checkbox-container .my-list-checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
  }*/



  .list-colleges-wrapper {
    margin: 0;
  }

  .input-select-list {
    width: 78.7vw;
  }

  .pagination-navigation>.my-list-page {
    font-size: 2.7vw;
  }

  .pagination-navigation.navigation-start {
    justify-content: space-between;
    align-items: flex-start;
  }

  .my-list-checkboxes {
    height: 100%;
  }

  .my-list-checkboxes>div>.my-list-checkbox-container>p {
    font-size: 1.6vw;
  }

  .list-colleges-cont {
    margin: 0 1vw;
  }

  .list-header-buttons>a,
  .list-header-buttons>button {
    width: 200px;
  }

  .list-colleges-wrapper-btn-download::before {
    left: 9%;
  }

  .list-colleges-wrapper-btn-download {
    width: 200px;
  }

  .slider-round {
    top: -1px;
    height: 24px;
    padding: 0;
    width: 50px;
  }
}

@media only screen and (max-width: 745px) {
  .sorting-block .sort-input>input.sort-input-choose-sort-by {
    width: 100%;
    padding: 10px 45px 10px 20px;
    font-size: 14px;
  }

  .sort-input-my-list>#sort {
    right: 17%;
    top: 45%;
  }

  .sort-input .sort-input-my-list-autocomplete>div.options {
    font-size: 14px;
  }

  .list-header-buttons>a,
  .list-header-buttons>button,
  .list-colleges-wrapper-btn-download {
    padding: 5px;
    width: 175px;
  }

  .list-colleges-wrapper-btn-download::before {
    left: 9%;
  }

  .list-header-buttons>a>img,
  .list-header-buttons>button>img {
    margin-right: 0;
  }

  .list-header-buttons>a>img {
    height: 16px;
  }

  .my-list-checkboxes>div>.my-list-checkbox-container>p {
    font-size: 14px;
    margin: 11px 0;
    top: 0;
  }

  .my-list-checkboxes {
    height: 100%;
    padding-left: 3px;
  }

  .my-list-checkboxes>div {
    padding-left: 0;
  }
}

@media only screen and (max-width: 630px) {
  .my-list-checkboxes>div>.my-list-checkbox-container>p {
    font-size: 12px;
  }

  .slider-round {
    top: -2px;
    height: 20px;
    padding: 0;
    width: 44px;
  }

  input:checked+.slider-round:before {
    transform: translateX(20px);
  }

  .switcher-pagination-my-list {
    margin: 0;
  }
}

@media only screen and (max-width: 550px) {

  .list-header-buttons>a,
  .list-header-buttons>button {
    font-size: 1.93vw;
  }

  .admin-users-pagination-drop-down-input.my-list-pagination-table-drop-down-input {
    width: 70px;
  }

  .my-list-pagination-table-drop-down-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 9vw;
    padding: 20px 0;
  }

  .slider-round:before {
    height: 90%;
    width: 36%;
    left: 10%;
    bottom: 3%;
  }

  input:checked+.slider-round:before {
    transform: translateX(3.5vw);
  }
}

@media only screen and (max-width: 520px) {
  .list-details {
    width: 100%;
  }

  .list-colleges-wrapper-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .no-list-container {
    margin-top: 15px;
  }

  .no-list-container>h6 {
    font-size: 4vw;
  }

  .no-list-container>a,
  .no-list-container>button {
    font-size: 3vw;
  }

  .pagination-navigation {
    display: none;
  }

  .my-list-navigation {
    justify-content: space-evenly;
  }

  .button-next-college {
    opacity: 0.5;
  }

  .input-select-list>.custom-input {
    border-radius: 5px;
    font-size: 3.6vw;
    font-family: 'Nunito Sans';
    color: var(--primary-typography);
    padding: 4.1vw 4.4vw;
  }

  .list-colleges-wrapper .sort-input>img {
    display: block;
  }

  .selected-list {
    justify-content: center;
  }

  .sort-input>#sort {
    top: 5.5vw;
    right: 10vw;
    width: 3vw;
  }

  #share-tip-copied {
    font-size: 3vw;
  }

  .custom-input>span {
    font-size: 4.1vw;
    font-family: 'DM Sans';
  }

  .input-select-list>img {
    width: 2.6vw;
    right: 5.4vw;
  }

  .input-select-list {
    width: 89.5vw;
    margin: 7.9vw 0 14.4vw 0;
  }

  .input-select-list>.autocomplete {
    top: 15vw;
  }

  .input-select-list>.autocomplete>div {
    font-size: 3vw;
  }

  .sort-input .autocomplete {
    width: 48vw;
    top: 11vw;
  }

  .sort-input .autocomplete>div {
    font-size: 3.6vw;
  }

  .hr-my-list {
    height: 3.3vw;
  }

  .list-header-buttons>button,
  .list-header-buttons>button:first-child {
    font-size: 3.6vw;
    padding: 2.6vw 3.3vw;
    min-width: max-content;
  }

  .my-list-body-header {
    padding: 0;
  }

  .list-details>div {
    color: var(--primary-typography);
    font-size: 3.6vw;
  }

  .list-details>.my-list-body-header__secondary-header {
    white-space: nowrap;
  }

  .list-details>div img {
    width: 24px;
    height: 24px;
  }

  .list-details>.list-name {
    font-size: 4.1vw;
    color: var(--headings-blue);
  }

  .list-details>.list-name img {
    margin-left: 0;
  }

  .delete-list>span {
    font-size: 3.6vw;
    border-width: 0.7vw;
    line-height: 5vw;
  }

  .delete-list {
    padding-right: 3.6vw;
    padding-bottom: 10.5vw;
  }

  .my-list-checkmark {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
  }

  .list-colleges-wrapper .sorting-block .sort-input>input {
    font-size: 3.6vw;
    padding: 3.8vw 4.4vw;
  }

  .sort-input>img {
    margin-left: 1vw;
  }

  .sort-header>p {
    font-size: 4.1vw;
  }

  .sort-header>img {
    height: 3vw;
  }

  .selected-list .my-list-college>div {
    width: 67vw;
  }

  .selected-list .my-list-college>div>span {
    color: #030054;
  }

  .my-list-college .college-header-my-list {
    font-size: 3vw;
  }

  .my-list-college .college-list-photo {
    width: 67vw;
    height: 48vw;
  }

  .my-list-navigation .selected-nav {
    width: fit-content;
  }

  .my-list-navigation>a,
  .my-list-navigation>span {
    margin: 0;
    padding: 4.8vw 0 1.8vw 0;
    font-size: 4.1vw;
  }

  .my-list-navigation>.selected-nav {
    border-bottom-width: 0.5vw;
  }

  .my-list-body-header {
    margin-bottom: 5.6vw;
    align-items: flex-start;
    flex-direction: column;
  }

  .my-list-checkboxes {
    padding: 0 5.9vw;
  }

  .sorting-block {
    margin-bottom: 9.5vw;
    width: 100%;
    box-sizing: border-box;
  }

  .my-list-checkboxes>div>.my-list-checkbox-container {
    margin-right: 0;
    padding-left: 6vw;
  }

  .my-list-checkboxes p {
    font-size: 3.6vw;
    margin: 0;
  }

  .my-list-checkbox-container .my-list-checkmark:after {
    left: 1.5vw;
    top: 0.5vw;
    width: 0.8vw;
    height: 1.8vw;
  }

  .my-list-checkboxes {
    display: none;
  }

  .list-colleges-wrapper .input-select {
    display: block;
    width: 90vw;
  }

  .delete-list {
    padding-right: 11.6vw;
  }

  .switch {
    display: flex;
    margin-bottom: 13px;
  }

  .slider-round {
    padding: 0;
    height: 20px;
    width: 40px;
  }

  .slider-round:before {
    height: 90%;
    width: 48%;
    left: 6%;
    bottom: 1px;
  }

  input:checked+.slider-round:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .list-header-buttons {
    gap: 4vw;
  }

  .my-list-body-header>.list-details {
    margin-bottom: 20px;
  }

  .primary-button-list {
    background-color: white;
    border: 2px solid #7e106e;
    color: #7e106e;
  }

  .list-header-buttons>a,
  .list-header-buttons>button {
    font-size: 14px;
    background-color: #7e106e;
    color: var(--white-color);
    width: max-content;
  }

  .list-header-buttons>a>img {
    height: 16px;
  }

  .list-colleges-wrapper-btn-download {
    padding: 7px 5px;
  }

  .list-colleges-wrapper-btn-download::before {
    left: 9%;
  }

  .add-notes-btn {
    margin-right: 0;
  }

  .list-header-buttons>a.primary-button-list-first {
    background-color: #a7a7a7;
    border-color: #a7a7a7;
  }

  .list-header-buttons>button,
  .list-header-buttons>button.primary-button {
    font-size: 3vw;
  }

  .list-header-buttons>button.primary-button-list-first {
    background: #a7a7a7;
    border-color: #939292;
    color: var(--white-color);
  }

  .list-header-buttons>a>img,
  .list-header-buttons>button>img {
    margin-right: 5px;
  }

  .list-header-buttons>a>img,
  .list-header-buttons>button>img,
  .list-header-buttons>button:first-child>img {
    height: 3.9vw;
  }

  .list-colleges-wrapper .input-select.input-select-choose-information {
    width: 100%;
    margin-bottom: 1rem;
  }

  .primary-button-list-second {
    font-size: 3vw;
    padding: 2.6vw 3.3vw;
  }

  .input-select>input.input-select-choose-info {
    width: 89%;
  }

  .list-colleges-wrapper .input-select.input-select-choose-information>img {
    right: 1rem;
  }

  .share-icon {
    height: 25px;
    width: 25px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 391px) {
  .switcher-pagination-my-list-text {
    left: 71px;
  }

  .list-colleges-wrapper-btn-download,
  .list-header-buttons>a,
  .list-header-buttons>button {
    font-size: 3.5vw;
  }
}

@media only screen and (min-width: 2000px) {
  .my-list-checkmark {
    width: 1vw;
    height: 1vw;
    border-radius: 0.4vw;
  }

  .sort-input>img {
    height: 1.5vw;
  }

  .my-list-checkbox-container .my-list-checkmark:after {
    left: 0.3vw;
    width: 0.3vw;
    height: 0.6vw;
    border-width: 0 0.15vw 0.15vw 0;
  }
}

@media only screen and (min-width: 2000px) {
  .my-list-checkmark {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 0.7vw;
  }

  .sort-input>img {
    height: 1.5vw;
  }

  .my-list-checkbox-container .my-list-checkmark:after {
    left: 0.3vw;
    width: 0.6vw;
    height: 0.6vw;
    border-width: 0 0.15vw 0.15vw 0;
  }

  .sort-input>#sort {
    top: 1vw;
  }
}

@media only screen and (min-width: 3000px) {
  .list-header-buttons>button {
    border-radius: 70px;
  }

  .selected-list>.my-list-college>.my-list-college-route>h4 {
    border: 3px solid #030054;
  }
}

.fb-customerchat {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}