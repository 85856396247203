.container > * {
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.28rem 2.5rem;
    width: 100%;
    background: var(--white-color);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.title {
    margin: 0;
    font-weight: 600;
}

.buttons__Container {
    display: flex;
    border: 1px solid #c2c2c2;
    border-radius: 100px;
    width: 24rem;
    height: 3rem;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
}

.buttons__Container > .button {
    border-right: 1px solid #c2c2c2;
}
.buttons__Container > .button:last-child {
    border-right: none;
}

.button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: var(--dark-typography);
    background-color: var(--white-color);
}
.selected {
    background-color: var(--color-primary);
    color: var(--white-color);
}

.text {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
}


@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .buttons__Container {
        width: 18rem;
    }
}