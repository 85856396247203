.college-details-section {
  background: #ffffff;
  box-shadow: 0.3vw 0.3vw 2.6vw rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;
  margin: 5% 0 15px;
}
.majors-header {
  margin: 0 0 5% 0;
  padding: 10px 30px;
  border-radius: 8px;
  height: 85px;
  font-size: 2vw;
  background-color: #fff;
}

.colleges-details-wrapper-container-under {
  padding: 3% 3%;
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.colleges-details-wrapper {
  padding: 0;
}
.section-hr-line {
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
}
.college-details-section > h6 {
  margin: 2% 0 3% 5%;
  font-size: 1.6vw;
}
/*.college-details-section > div {*/
/*  padding: 2% 5% 4% 5%;*/
/*}*/
.scholarship-button {
  display: flex;
  justify-content: space-around;
}

.price-calculation {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.price-calculation > button {
  padding: 1vw 3.7vw;
  white-space: nowrap;
  font-size: 1.2vw;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: white;
  border-radius: 50px;
  font-weight: 600;
}
.college-details-section > div > p {
  display: flex;
  align-items: center;
  color: #464646;
  font-size: 1.2vw;
  position: relative;
  z-index: 1;
}
.college-details-section > div > p > img {
  padding-right: 10px;
  height: 2vw;
}
.college-details-section > div > .unlocked-field {
  color: var(--primary-typography);
}
@media only screen and (max-width: 900px) {
  .price-calculation > button {
    font-size: 12px;
  }
}
@media only screen and (max-width: 750px) {
  .price-calculation > button,
  .price-calculation > a > button {
    font-size: 1.3vw;
  }
}
@media only screen and (max-width: 520px) {
  .scholarship-button {
    flex-direction: column;
    align-items: center;
  }

  .price-calculation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.9vw;
  }

  .price-calculation > button,
  .price-calculation > a > button {
    font-size: 4vw;
    padding: 3.6vw;
  }

  .college-details-section > h6 {
    font-size: 4.6vw;
    margin: 14px 0;
  }

  .college-details-section {
    box-shadow: none;
  }

  .college-details-section > div {
    padding: 0;
  }
  .colleges-details-wrapper {
    padding: 0;
    width: 100%;
  }
  .section-hr-line {
    margin-bottom: 5%;
  }
}

.college-details-section > div > p {
  display: flex;
  align-items: center;
  color: var(--light-grey);
  font-size: 1.2vw;
}
.college-details-section-locked-schoolarships {
  padding: 9% 5% 20%;
  margin: 2% -6% -5% -6%;
  background-color: darkgray;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.college-details-section-locked-schoolarships-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.price-calculation-locked {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-calculation-locked > a > button {
  padding: 1vw 3.7vw;
  white-space: nowrap;
  font-size: 1.2vw;
  border: 1px solid var(--primary-typography);
  color: var(--primary-typography);
  background: white;
  border-radius: 50px;
  font-weight: 600;
}

.price-calculation-locked-button-first {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.price-calculation-locked > a > .price-calculation-locked-button-first-locked {
  background: #a7a7a7;
}

.price-calculation-locked-button-first > img {
  width: 1vw;
}

.college-details-section-locked-schoolarships-button > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.college-details-section-locked-schoolarships-button > p,
.college-details-section-locked-schoolarships-button > div > p {
  margin: 0;
  color: var(--primary-typography);
  font-size: 1.4vw;
}
.college-details-section-locked-schoolarships .res-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.college-details-section-locked-schoolarships .res-block > span {
  font-size: 1.2vw;
  color: #464646;
  width: 3vw;
}
.upgrade-profile-message-schoolarships {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  padding: 2% 5%;
  position: absolute;
  left: 30%;
  top: 10%;
}
.upgrade-profile-message-schoolarships > img {
  width: 3.3vw;
}
.upgrade-profile-message-schoolarships > p {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--headings-blue);
  padding: 3%;
  margin: 0;
}
.upgrade-profile-message-schoolarships > span {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 1vw;
  color: var(--headings-blue);
  margin: 0;
}
.upgrade-profile-message-schoolarships > a {
  padding: 3% 0;
  white-space: nowrap;
  width: 13.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 40px;
  font-weight: 600;
  margin: 5%;
  text-align: center;
  text-decoration: none;
}
.upgrade-profile-message-schoolarships-second {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  padding: 2% 5% 10%;
  position: absolute;
  left: 30%;
  top: 10%;
}
.upgrade-profile-message-schoolarships-second > img {
  width: 3vw;
}
.upgrade-profile-message-schoolarships-second > p {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--headings-blue);
  padding: 3%;
  margin: 0;
}
.upgrade-profile-message-schoolarships-second > span {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 1vw;
  color: var(--headings-blue);
  margin: 0;
}
.upgrade-profile-message-schoolarships-second > a {
  padding: 3% 0;
  white-space: nowrap;
  width: 13.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  border: none;
  background: var(--color-primary);
  color: white;
  border-radius: 40px;
  font-weight: 600;
  margin: 5%;
  text-decoration: none;
  text-align: center;
}
@media only screen and (min-width: 1600px) {
  .price-calculation-locked-button-first > img {
    width: 1.3vw;
  }
}
@media only screen and (max-width: 900px) {
  .college-details-section-locked-schoolarships .res-block > span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 520px) {
  .upgrade-profile-message-schoolarships {
    background-color: darkgrey;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    width: 96%;
    position: absolute;
    border-radius: 0;
    left: 0%;
    top: 54%;
  }
  .college-details-section-locked-schoolarships {
    background-color: white;
    padding: 9% 5% 50%;
    position: unset;
  }

  .college-details-section-locked-schoolarships-second {
    padding-bottom: 38%;
  }

  .upgrade-profile-message-schoolarships > img {
    width: 7.5vw;
  }

  .upgrade-profile-message-schoolarships > p {
    font-weight: 600;
    font-size: 4.6vw;
    color: white;
    padding: 3%;
    margin: 0;
  }

  .upgrade-profile-message-schoolarships > span {
    position: relative;
    padding: 0;
    font-weight: 600;
    white-space: normal;
    width: 80%;
    text-align: center;
    font-size: 3.6vw;
    color: white;
    margin: 0;
  }
  .upgrade-profile-message-schoolarships > a {
    padding: 4% 0;
    white-space: nowrap;
    width: 54vw;
    font-size: 4.6vw;
    cursor: pointer;
    border: none;
    background: white;
    color: var(--color-primary);
    border-radius: 40px;
    font-weight: 600;
    margin: 5%;
  }

  .upgrade-profile-message-schoolarships-second {
    background-color: darkgray;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    border-radius: 5%;
    padding: 2%;
    position: absolute;
    left: 0%;
    border-radius: 0;
    top: 80%;
  }

  .upgrade-profile-message-schoolarships-second > img {
    width: 7.5vw;
  }

  .upgrade-profile-message-schoolarships-second > p {
    font-weight: 600;
    font-size: 4.6vw;
    color: white;
    padding: 3%;
    margin: 0;
  }

  .upgrade-profile-message-schoolarships-second > span {
    position: relative;
    padding: 0;
    font-weight: 600;
    width: 80%;
    white-space: normal;
    text-align: center;
    font-size: 3.6vw;
    color: white;
    margin: 0;
  }
  .upgrade-profile-message-schoolarships-second > a {
    padding: 4% 0;
    white-space: nowrap;
    width: 54vw;
    font-size: 4.6vw;
    cursor: pointer;
    border: none;
    background: white;
    color: var(--color-primary);
    border-radius: 40px;
    font-weight: 600;
    margin: 5%;
  }

  .college-details-section-locked-schoolarships .res-block {
    margin-right: 16px;
  }

  .college-details-unlocked-field-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 4px;
  }

  .price-calculation-locked {
    flex-direction: column;
    gap: 10px;
  }

  .price-calculation-locked > a > button {
    font-size: 2.8vw;
    padding: 1vw 15vw;
  }

  .colleges-details-wrapper-container-under {
    padding: 0;
    background-color: #fff;
  }
}
