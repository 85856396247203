.modal-advanced-search {
  background-color: white;
  border-radius: 20px;
  width: 50vw;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8vw 0;
  position: absolute;
  top: -25vw;
  left: -43vw;
}
.modal-advanced-search > h1 {
  font-weight: 600;
  font-size: 4.6vw;
  color: var(--headings-blue);
  margin: 3.6vw 0 0.5vw 0;
}
.modal-advanced-search > p {
  font-weight: 600;
  font-size: 3.6vw;
  color: var(--headings-blue);
  margin: 0;
}
.modal-advanced-search > img {
  width: 7.5vw;
}
.advanced-search-modal-button {
  padding: 3.3vw 0;
  width: 53.6vw;
  font-weight: 600;
  background: var(--color-primary);
  border: none;
  border-radius: 40px;
  color: white;
  white-space: nowrap;
  font-family: 'Nunito Sans';
  cursor: pointer;
  font-size: 4.6vw;
  margin-top: 2.6vw;
}
@media only screen and (max-width: 520px) {
  .modal-advanced-search {
    width: 85vw;
  }
}
