.add-new-list-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;

    padding: 40px;
    width: 600px;
    position: relative;
    box-sizing: border-box;

    border-radius: 32px;
    background: var(--white-color);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
}

.add-new-list-modal__cross {
    position: absolute;
    top: 28px;
    right: 28px;

    display: block;
    width: 12px;
    height: 12px;

    background-image: url("../../images/cross.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.add-new-list-modal__title {
    color: var(--headings-blue);
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.add-new-list-modal__input {
    padding: 16px 32px;
    width: 80%;
    border-radius: 32px;
    border: 1px solid #c2c2c2;
    background: var(--white-color);
}

.add-new-list-modal__input,
.add-new-list-modal__input::placeholder {
    color: #403e59;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    opacity: 0.6;
}

.add-new-list-modal__input {
    opacity: 1;
}

.add-new-list-modal__cont-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin: 16px 0;
}

.add-new-list-modal__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--headings-blue);
    text-align: center;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    background: transparent;
    border-radius: 32px;
    border: 1px solid #a7a7a7;

    height: 48px;
    width: 180px;
    cursor: pointer;
}

.second {
    border: 0;
    color: var(--white-color);
    background: var(--color-primary);
}

@media only screen and (max-width: 844px) {
    .add-new-list-modal {
        width: 540px;
    }
}

@media only screen and (max-width: 560px) {
    .add-new-list-modal {
        width: 390px;
        margin: 0 auto;
        padding: 20px 30px;
        gap: 30px;
    }

    .add-new-list-modal__input,
    .add-new-list-modal__input::placeholder,
    .add-new-list-modal__btn {
        font-size: 20px;
    }

    .add-new-list-modal__btn {
        width: 140px;
    }
}

@media only screen and (max-width: 391px) {
    .add-new-list-modal {
        width: 95%;
        padding: 15px 25px;
        gap: 20px;
    }

    .add-new-list-modal__input,
    .add-new-list-modal__input::placeholder,
    .add-new-list-modal__btn {
        font-size: 18px;
    }

    .add-new-list-modal__btn {
        width: 120px;
    }
}
