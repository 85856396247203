.container,
.bottomButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    gap: 0.75rem;
    height: fit-content;
    padding-bottom: 1rem;
    top: 0;
}
.container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}
.container::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 10px;
    cursor: pointer;
}

.bottomButtonsContainer {
    gap: 0.75rem;
    width: 9.25rem;
}
.bottomButtons__ClearBtn {
    font-weight: 600;
    margin: 0;
    border-bottom: 0.0625rem solid var(--primary-typography);
    cursor: pointer;
}



@media only screen and (max-width: 900px) {
    .container {
        position: fixed;
        top: 0;
        margin: 5rem 0 0 0;
        padding: 3rem 1rem 2.5rem 1rem;
        z-index: 12;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 92%;
        width: 80vw;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background: var(--light-grey-smoke);
    }

    .closeIcon {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1.5rem;
        width: 0.75rem;
        height: 0.75rem;
        background-image: url("../../../../images/greyCross.svg");
        background-size: cover;
        cursor: pointer;
    }

    .title {
        margin: 0;
        color: var(--headings-blue);
        font-family: 'Source Sans 3', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
}

@media only screen and (max-width: 520px) {
    .container {
        width: 95vw;
    }
}