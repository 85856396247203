.admin-modal {
    background-color: white;
    width: 50vw;
    max-width: 570px;
    box-sizing: border-box;
    max-height: 700px;
}

.admin-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    background-color: var(--headings-blue);
    box-sizing: border-box;
}

.admin-modal__header__name {
    font-size: 24px;
    color: #ffffff;
    padding: 0;
    margin: 0;
}

.admin-modal__header__cont-cross {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
}
.admin-modal__header__cross {
    width: 15px;
    height: 15px;
}

.admin-modal__main {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.admin-modal__main__text-info,
.admin-modal__main__value {
    align-self: flex-start;
    color: var(--headings-blue);
    font-weight: bold;
    margin: 0;
    padding: 0;
    height: 20px;
}

.admin-modal__main__text-info,
.admin-modal__main__text-info-404 {
    color: #ff0000;
    font-weight: bold;
    font-size: 12px;
}

.admin-modal__main__text-info-0 {
    visibility: hidden;
}

.admin-modal__main__text-info-200 {
    color: #3ead27;
}

.admin-modal__main__text-info-800 {
    color: #00d924;
}

.admin-modal__main__text-info-900 {
    color: #ffa500;
}

.admin-modal__main__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

.admin-modal__main__form__cont-upload-file {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.admin-modal__main__form__content-upload-file {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 127px;
    position: relative;
}

.admin-modal__main__form__label {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
}

.admin-modal__main__form__input {
    display: none;
}

.admin-modal__main__form__textarea {
    font-weight: bold;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 14px;
    color: var(--primary-typography);
    max-width: 100%;
    min-width: 100%;
    max-height: 400px;
    min-height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-color: var(--border-color);
}

.admin-modal__main__form__textarea:focus,
.admin-modal__main__form__textarea:active {
    border-color: var(--dark-grey);
    outline: none;
}

.admin-modal__main__form__btn {
    align-self: flex-start;
}

.admin-modal__main__form__content-upload-file__tooltip {
    text-align: start;
    background-color: #faf6f6;
    pointer-events: none;
    position: absolute;
    bottom: calc(100% + 10px);
    padding: 5px 10px;
    width: 200%;
    border-radius: 10px;
    font-size: 16px;
    transition: 0.3s ease-in-out all;
    opacity: 0;
    visibility: visible;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.admin-modal__main__form__content-upload-file__tooltip::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color:  #faf6f6;
    transform: rotate(45deg);
    position: absolute;
    bottom: -6px;
    left: 15px;
    margin: auto;
}
.admin-modal__main__form__content-upload-file:hover .admin-modal__main__form__content-upload-file__tooltip,
.admin-modal__main__form__content-upload-file:hover .admin-modal__main__form__content-upload-file__tooltip::before {
    opacity: 1;
    visibility: visible;
}
