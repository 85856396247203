.header-create-account>h1 {
  font-weight: 600;
  font-size: 2rem;
  color: white;
  padding-top: 3.6vw;
  background-color: var(--color-primary);
  margin: 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;
}

.header-create-account {
  overflow: hidden;
  margin-bottom: 0;
  background: #e5e5e5;
}

.header-create-account .wave {
  width: 100%;
  position: relative;
  top: -6rem;
  z-index: 0;
}

.create-account-body {
  display: flex;
  justify-content: center;
  margin-top: -12rem;
  margin-bottom: 2rem;
}

.create-account-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: white;
  border-radius: 0.7vw;
  margin: 0;
  z-index: 1;
  padding: 2rem 4rem;
  width: 100%;
  max-width: 1000px;
}

.create-account-form>input {
  border: 0.13vw solid var(--border-color);
  border-radius: 0.5vw;
  padding: 1vw;
  margin-bottom: 1vw;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: rgba(70, 70, 70, 0.8);
  width: 100%;
}

.create-account-form>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.1vw;
  align-self: baseline;
  margin-top: 3vw;
}

.create-account-form .input-select>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 1.1vw;
  align-self: baseline;
  padding-bottom: 0.7vw;
  position: relative;
  bottom: 95%;
}

.button-submit {
  padding: 1rem 2rem;
  background: var(--color-primary);
  border-radius: 2rem;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  align-self: center;
  font-family: 'Montserrat';
}

.create-account-form>button>div {
  display: flex;
}

.create-account-form>button>div>p {
  color: white;
  font-weight: 600;
  font-size: 1.1vw;
  align-self: center;
  font-family: 'Montserrat';
  margin: 0;
}

.create-account-form>button>div>img {
  transform: rotate(180deg);
}

.roles-radios {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}

.roles-radios>div {
  display: flex;
  align-items: center;
  padding-right: 10%;
}

.roles-radios>div>p {
  margin-left: 0.7vw;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.create-account-body input[type='radio'],
.create-account-body input[type='radio']:checked {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: var(--headings-blue);
  width: 1.7vw;
  height: 1.7vw;
  border: 0.2vw solid var(--headings-blue);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.create-account-body input[type='radio']::before {
  content: '';
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--headings-blue);
}

.create-account-body input[type='radio']:checked::before {
  transform: scale(1);
}

.create-account-form>input:invalid,
.input-select>input:invalid,
.input-select.graduation>input:invalid,
#budgetChecked:invalid {
  border: 0.13vw solid red;
}

.create-account-form>input:invalid::placeholder,
.input-select>input:invalid::placeholder {
  color: red;
}

.create-account-form .input-select>input:focus:valid+p {
  color: black;
}

.create-account-form .input-select>input:invalid+p {
  color: red;
}

.search-colleges-first {
  margin-bottom: 3.5vw;
}

.search-colleges>.autocomplete {
  width: 100%;
}

.search-colleges>img {
  position: absolute;
  width: 1.2vw;
  top: 1vw;
  left: 1.2vw;
}

.search-colleges>input {
  border-radius: 0.5vw;
  border: 0.13vw solid var(--border-color);
  padding: 1vw;
  margin: 0;
  margin-bottom: 5vw;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: rgba(70, 70, 70, 0.8);
  padding-left: 3.5vw;
  width: 100%;
}

.create-account-form>.search-colleges-add-colleges>input,
.create-account-form>.search-colleges-first>input {
  width: 93%;
}

.input-select,
.search-colleges {
  position: relative;
}

.input-select>img {
  position: absolute;
  width: 1.5rem;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.input-select .location-img {
  position: absolute;
  top: 13%;
}

.input-select>.autocomplete {
  width: 100%;
}

.input-select>input {
  border: 0.13vw solid var(--border-color);
  border-radius: 0.5vw;
  padding: 1vw;
  margin: 0;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  color: rgba(70, 70, 70, 0.8);
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
}

.half-width {
  justify-content: flex-start;
  margin-bottom: 5%;
}

.half-width>div {
  width: 25%;
}

.selected-colleges-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4vw;
  margin-bottom: 4vw;
  position: relative;
}

.selected-college {
  background: #f6f7f9;
  border: 0.5px solid #d1d5db;
  border-radius: 29px;
  padding: 2%;
  width: fit-content;
  margin: 0 2% 2% 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.selected-college>span {
  font-weight: 600;
  color: #6a6e83;
  font-size: 1vw;
  padding-right: 0.7vw;
  cursor: default;
  white-space: pre-wrap;
}

.selected-college>img {
  cursor: pointer;
  width: 1vw;
}

.look-up {
  transform: rotate(180deg);
}

.look-down {
  transform: rotate(0);
}

.create-account-form>.hr-line {
  width: 100vw;
  align-self: center;
  background: #e5e5e5;
  margin-top: 2.7vw;
}

.create-account-form>.optional-fields-description {
  margin: 0;
  color: var(--color-primary);
  font-size: 1.1vw;
  opacity: 0.8;
}

.optional-fields-header {
  margin: 3vw 0 0.5vw 0;
  color: var(--color-primary);
  font-size: 2.1vw;
}

.container {
  display: block;
  position: relative;
  padding-left: 2.3vw;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: -0.7vw;
  border: 0.2vw solid var(--headings-blue);
  border-radius: 0.7vw;
  height: 1.7vw;
  width: 1.7vw;
  background: none;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.6vw;
  top: 0.33vw;
  width: 0.33vw;
  height: 0.7vw;
  border: solid var(--headings-blue);
  border-width: 0 0.2vw 0.2vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.create-account-form>.selected-colleges-list {
  margin-top: -5vw;
}

.input-select>.autocomplete,
.search-colleges>.autocomplete {
  top: 4.6vw;
}

.input-select>.autocomplete>div,
.search-colleges>.autocomplete>div {
  font-size: 1vw;
}

/* @media only screen and (min-width: 1520px) {
  .selected-colleges-list {
    margin-top: -2vw;
  }

  .input-select-major {
    margin-top: 3vw;
  }

  .create-account-form>.search-colleges-add-colleges>input,
  .create-account-form>.search-colleges-first>input {
    width: 97%;
    width: -moz-fit-content;
  }

  .graduation>img {
    top: 19%;
  }
} */

@media only screen and (max-width: 900px) {
  .create-account-body {
    margin-top: -6rem;
  }

  .header-create-account .wave {
    top: -3rem;
  }

  .create-account-form .input-select>p,
  .create-account-form>p,
  .create-account-form>button {
    font-size: 1rem;
  }

  .input-select>input,
  .search-colleges>input,
  .selected-college>span,
  .create-account-form>.optional-fields-description,
  .input-select>.autocomplete>div,
  .search-colleges>.autocomplete>div {
    font-size: 1rem;
  }

  .search-colleges>img {
    position: absolute;
    width: 1.5vw;
    top: 1.5vw;
    left: 1vw;
  }

  .create-account-form>.selected-colleges-list {
    margin-top: -6.5vw;
  }

  .create-account-form>button>div>img {
    width: 1.2vw;
  }

  .create-account-form>.search-colleges-add-colleges>input,
  .create-account-form>.search-colleges-first>input {
    width: 93%;
  }
}

@media only screen and (max-width: 520px) {

  .input-select>input,
  .create-account-form>input,
  .search-colleges>input {
    font-size: 3.6vw;
    border-radius: 1.3vw;
    border: 0.3vw solid var(--border-color);
    padding: 3.8vw 4.4vw;
  }

  .create-account-body input[type='radio'],
  .create-account-body input[type='radio']:checked {
    width: 5.1vw;
    height: 5.1vw;
  }

  .create-account-body input[type='radio']:checked::before {
    width: 3.3vw;
    height: 3.3vw;
  }

  .create-account-form {
    width: 88.5vw;
    padding: 0 5.6vw 15.1vw 5.6vw;
  }

  .create-account-form>.hr-line {
    height: 3.3vw;
    margin-top: 12.3vw;
  }

  .create-account-form .input-select>p,
  .create-account-form>p {
    font-size: 4.1vw;
  }

  .header-create-account {
    margin-bottom: 11.3vw;
    background: white;
  }


  .header-create-account .wave {
    top: -4vw;
  }

  .optional-fields-header {
    font-size: 4.6vw;
    margin-top: 10.6vw;
  }

  .create-account-form>.optional-fields-description {
    font-size: 3.6vw;
    margin: 3.5vw 0;
    opacity: 1;
  }

  .create-account-form>button {
    padding: 3.6vw 31.7vw;
    font-size: 4.6vw;
    margin: 4vw 0 0 0;
    border-radius: 10.3vw;
  }

  .create-account-body {
    background: white;
    margin: 0;
    padding: 0;
  }

  .input-select>img {
    width: 3.6vw;
    right: 5.1vw;
    top: 6vw;
    user-select: none;
  }

  input[type='radio'] {
    width: 5.1vw;
    height: 5.1vw;
    border-width: 0.5vw;
  }

  input[type='radio']::before {
    width: 3.1vw;
    height: 3.1vw;
  }

  .checkmark {
    width: 4.9vw;
    height: 4.9vw;
    left: -1vw;
    border: 0.5vw solid var(--headings-blue);
    border-radius: 1.5vw;
  }

  .container {
    padding-left: 6.3vw;
  }

  .container .checkmark:after {
    left: 1.3vw;
    top: 0.33vw;
    width: 1vw;
    height: 2.5vw;
    border-width: 0 1vw 0.8vw 0;
  }

  .input-select>.autocomplete,
  .search-colleges>.autocomplete {
    top: 15.6vw;
  }

  .input-select>.autocomplete {
    z-index: 1000;
  }

  .input-select .autocomplete>div,
  .search-colleges .autocomplete>div {
    font-size: 3.6vw;
  }

  .search-colleges>input {
    padding-left: 12.1vw;
    margin-bottom: 15vw;
  }

  .search-colleges>img {
    width: 4.7vw;
    top: 3.6vw;
    left: 3.6vw;
  }

  .search-colleges>.selected-colleges-list,
  .create-account-form>.selected-colleges-list {
    margin: -12vw 0 10vw 0;
  }

  .selected-college>img {
    width: 2vw;
  }

  .selected-college>span {
    font-size: 3vw;
    padding-right: 2vw;
  }

  .roles-radios:first-of-type {
    margin-bottom: 14%;
  }

  .create-account-form>button>div>p {
    font-size: 3vw;
  }

  .create-account-form>button>div>img {
    width: 3vw;
  }

  .create-account-form>.search-colleges-add-colleges>input,
  .create-account-form>.search-colleges-first>input {
    width: 93%;
  }

  .input-select>input.input-select-create-profile-mobile {
    width: 90%;
  }
}