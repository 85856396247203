/* loader */
.container-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loader div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: var(--color-primary);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}
.loader div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}
.loader div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}
.loader div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}
.loader div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}
.loader div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}
.loader div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}
.loader div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}
.loader div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}
.loader div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}
.loader div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}
.loader div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}
.loader div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}
@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

.loader.big {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
}
.loader.big div {
    position: absolute;
    width: 12px;
    height: 12px;
    background: var(--color-primary);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}
.loader.big div:nth-child(1) {
    animation-delay: 0s;
    top: 74px;
    left: 132px;
}
.loader.big div:nth-child(2) {
    animation-delay: -0.1s;
    top: 44px;
    left: 124px;
}
.loader.big div:nth-child(3) {
    animation-delay: -0.2s;
    top: 22px;
    left: 102px;
}
.loader.big div:nth-child(4) {
    animation-delay: -0.3s;
    top: 14px;
    left: 74px;
}
.loader.big div:nth-child(5) {
    animation-delay: -0.4s;
    top: 22px;
    left: 44px;
}
.loader.big div:nth-child(6) {
    animation-delay: -0.5s;
    top: 44px;
    left: 22px;
}
.loader.big div:nth-child(7) {
    animation-delay: -0.6s;
    top: 72px;
    left: 14px;
}
.loader.big div:nth-child(8) {
    animation-delay: -0.7s;
    top: 102px;
    left: 22px;
}
.loader.big div:nth-child(9) {
    animation-delay: -0.8s;
    top: 124px;
    left: 44px;
}
.loader.big div:nth-child(10) {
    animation-delay: -0.9s;
    top: 136px;
    left: 78px;
}
.loader.big div:nth-child(11) {
    animation-delay: -1s;
    top: 124px;
    left: 104px;
}
.loader.big div:nth-child(12) {
    animation-delay: -1.1s;
    top: 104px;
    left: 124px;
}
@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

@media only screen and (max-width: 544px) {
    .loader.big {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
    }
    .loader.big div {
        position: absolute;
        width: 3px;
        height: 3px;
        background: var(--color-primary);
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
    }
    .loader.big div:nth-child(1) {
        top: 17.5px;
        left: 33px;
    }
    .loader.big div:nth-child(2) {
        top: 11px;
        left: 31px;
    }
    .loader.big div:nth-child(3) {
        top: 5.5px;
        left: 26px;
    }
    .loader.big div:nth-child(4) {
        top: 3.5px;
        left: 11.5px;
    }
    .loader.big div:nth-child(5) {
        top: 5.5px;
        left: 11px;
    }
    .loader.big div:nth-child(6) {
        top: 11px;
        left: 5.5px;
    }
    .loader.big div:nth-child(7) {
        top: 18.5px;
        left: 3.5px;
    }
    .loader.big div:nth-child(8) {
        top: 26px;
        left: 5.5px;
    }
    .loader.big div:nth-child(9) {
        top: 31px;
        left: 11px;
    }
    .loader.big div:nth-child(10) {
        top: 33px;
        left: 18.5px;
    }
    .loader.big div:nth-child(11) {
        top: 31px;
        left: 26px;
    }
    .loader.big div:nth-child(12) {
        top: 26px;
        left: 31px;
    }
}

.loader.small {
    width: 30px;
    height: 30px;
}
.loader.small div {
    width: 2.25px;
    height: 2.25px;
}
.loader.small div:nth-child(1) {
    top: 13.85px;
    left: 24.5px;
}
.loader.small div:nth-child(2) {
    top: 8.25px;
    left: 23.5px;
}
.loader.small div:nth-child(3) {
    top: 4.125px;
    left: 19.5px;
}
.loader.small div:nth-child(4) {
    top: 2.625px;
    left: 13.875px;
}
.loader.small div:nth-child(5) {
    top: 4.125px;
    left: 8.25px;
}
.loader.small div:nth-child(6) {
    top: 8.25px;
    left: 4.125px;
}
.loader.small div:nth-child(7) {
    top: 13.875px;
    left: 2px;
}
.loader.small div:nth-child(8) {
    top: 19.5px;
    left: 4.125px;
}
.loader.small div:nth-child(9) {
    animation-delay: -0.8s;
    top: 23.5px;
    left: 8.25px;
}
.loader.small div:nth-child(10) {
    top: 24.75px;
    left: 13.875px;
}
.loader.small div:nth-child(11) {
    top: 23.25px;
    left: 19.5px;
}
.loader.small div:nth-child(12) {
    top: 19.5px;
    left: 23.5px;
}
@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}