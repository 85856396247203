.popup-modal-container {
    border-radius: 24px;
    background: white;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
    padding: 40px 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;
    box-sizing: border-box;
}
.popup-modal-close {
    position: absolute;
    width: 12px;
    top: 28px;
    right: 28px;
    cursor: pointer;
}
.popup-header {
    color: #030054;
    font-family: 'Source Sans 3';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.8px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 24px;
}
.popup-modal-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 24px;
}
.popup-modal-second-button, .popup-modal-first-button {
    width: 180px;
    height: 48px;
    padding: 8px 16px;
    border-radius: 24px;
    background: #7E106E;
    color: white;
    border: none;
    text-align: center;
    font-family: 'Source Sans 3';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    cursor: pointer;
}
.popup-modal-first-button {
    border-radius: 24px;
    border: 1px solid #A7A7A7;
    background: white;
    color: #030054;
}