.backgroundContainer {
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url('../../../../images/PowerSearchHeaderBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-color: var(--headings-blue);
    z-index: 3;
}

.backgroundContainer.active {
    pointer-events: none;
}

.container {
    font-family: 'Source Sans 3', sans-serif;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    max-width: 90rem;
}

.navBackBtn {
    margin-left: 1.5rem;
}

.inputsContainer,
.filtersBtn {
    margin-top: .75rem;
}

.title,
.subTitle {
    color: var(--white-color);
}

.title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 2.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
}

.titleIcon {
    width: 3.85rem;
    height: 3.35rem;
}

.subTitle {
    font-size: 1.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
}

.inputsContainer,
.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.inputsContainer {
    gap: 1.5rem;
}

.inputWrapper {
    width: 22.25rem;
}

.autocomplete {
    color: var(--primary-typography);
    font-size: 1.25rem;
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 600;
}

.buttonsContainer {
    margin-top: 1.5rem;
    gap: 1.25rem;
}

.buttonsContainer>button {
    width: 18.75rem;
}

.clearSearchBtn {
    margin: 1.25rem 0;
    font-size: 1.25rem;
    font-weight: 400;
    border-bottom: 1px solid var(--white-color);
    cursor: pointer;
}


@media only screen and (max-width: 900px) {
    .title {
        font-size: 2.5rem;
    }

    .subTitle {
        font-size: 1.5rem;
        text-align: center;
    }

    .inputWrapper {
        width: 70%;
    }

    .buttonsContainer {
        flex-wrap: nowrap;
        width: 22.25rem;
        margin-bottom: .75rem;
    }

    .buttonsContainer button:first-child {
        width: 60%;
    }

    .filtersBtn {
        font-size: 1.25rem;
    }

    .filtersBtnIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .clearSearchBtn {
        margin: 0 0 1.25rem 0;
    }
}

@media only screen and (max-width: 520px) {

    .title,
    .inputsContainer,
    .buttonsContainer,
    .filtersBtn {
        margin-top: 1.25rem;
    }

    .title {
        font-size: 1.75rem;
    }

    .titleIcon {
        gap: 0.5rem;
        width: 2.5rem;
        height: 2.1875rem;
    }

    .subTitle {
        font-size: 0.875rem;
        text-align: center;
    }

    .inputsContainer {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .inputWrapper {
        width: 80%;
    }

    .buttonsContainer {
        /*width: 80%;*/
        flex-wrap: nowrap;
        gap: 0.5rem;
    }

    .filtersBtn {
        font-size: 1rem;
        margin-bottom: 1.25rem;
    }

    .filtersBtnIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .clearSearchBtn {
        font-size: 1rem;
    }
}