.custom-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    cursor: pointer;
}

.custom-checkbox__input {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;

    margin-right: 16px;
    position: relative;
    left: 0;
    transition: 0.5s ease-in-out all;
}


.custom-checkbox__text {
    width: 90%;
}

.custom-checkbox__text.checked {
    color: var(--primary-typography);
    opacity: 0.6;
}
