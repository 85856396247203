.modal_content {
  width: 72.75vw;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 2.65vw 4.29vw;
  height: 50vw;
  max-height: 85vh;
  overflow-y: auto;
}
.modal_content::-webkit-scrollbar {
  width: 0.7vw;
  height: 0.7vw;
  cursor: pointer;
}
.modal_content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.7vw;
}
.modal_content::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 0.7vw;
  cursor: pointer;
}
.sort_information {
  align-self: flex-end;
  margin: 0;
  padding: 0;
  font-size: 1.06vw;
  font-weight: 600;
  color: #a7a7a7;
  text-align: right;
}
.modal_content > img {
  position: absolute;
  right: 2.31vw;
  top: 2.31vw;
  height: 0.93vw;
  cursor: pointer;
}

.modal_content > h2 {
  font-weight: 600;
  font-size: 1.59vw;
  margin-top: 0;
  margin-bottom: 1.5vw;
}

.offers_years {
  margin-bottom: 1.5vw;
}

.offers_year {
  margin-right: 4.63vw;
}

.offers_year > span {
  font-size: 0.93vw;
  color: var(--primary-typography);
  margin-left: 0.79vw;
}

.offers_year {
  display: flex;
  align-items: center;
}

input[type='radio'] {
  color: var(--dark-grey);
  width: 1.12vw;
  height: 1.12vw;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  border: 0.2vw solid var(--dark-grey);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

input[type='radio']:checked {
  color: var(--color-primary);
  border-color: var(--color-primary);
  width: 1.12vw;
  height: 1.12vw;
}

input[type='radio']::before {
  background-color: var(--color-primary);
  width: 0.5vw;
  height: 0.5vw;
  content: '';
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}
.scroll_block::-webkit-scrollbar {
  width: 0.7vw;
  height: 0.7vw;
  cursor: pointer;
}
.scroll_block::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.7vw;
}
.scroll_block::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 0.7vw;
  cursor: pointer;
}
.scroll_block.few_offers {
  padding-bottom: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.offers_table.few_offers::after {
  opacity: 0;
}
.sticky_block.few_offers::after {
  top: 0;
}
.empty_offers_list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
}
.empty_offers_list > h2 {
  color: var(--color-primary);
  font-size: 3vw;
}

.loader > span {
  padding: 0;
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  width: 100%;
}

.modal_content .loader {
  text-align: center;
  margin-top: 20%;
}

.offers_table_container * {
  box-sizing: border-box;
}
.offers_table {
  display: flex;
}
.sticky_block {
  flex: 0 0 24%;
  overflow-y: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.scroll_block {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 1.5vw;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.sticky_block::after {
  position: absolute;
  content: '';
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  top: -2.2vw;
  left: 0;
}
.offers_table_container {
  position: relative;
  overflow-y: hidden;
}
.offers_table::after {
  position: absolute;
  content: '';
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  top: -2.2vw;
  right: 1px;
}
.empty_block {
  font-size: 1.06vw;
  padding: 0.7vw 0;
  background-color: #fff;
  color: rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.count_offer {
  padding: 0.7vw 1.32vw;
  font-weight: 600;
  font-size: 1.06vw;
  color: var(--headings-blue);
}
.students_details_title {
  padding: 0.7vw 1.32vw;
  font-weight: 600;
  font-size: 1.06vw;
  color: var(--color-primary);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}
.scroll_block .students_details_title {
  color: rgba(0, 0, 0, 0);
}
.students_details_body__sticky {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.students_details_body__sticky:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.students_details_body__scroll {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.students_details_body__scroll:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.offers_flex_container > div:last-child .students_details_body__scroll {
  border-right: none;
}
.offers_flex_container {
  display: flex;
}
.value_item {
  font-weight: 400;
  font-size: 1.06vw;
  color: var(--primary-typography);
  padding: 0.79vw 1.32vw;
  margin: 0;
  white-space: nowrap;
}
.subtitle_item {
  margin: 0;
  font-weight: 400;
  font-size: 1.06vw;
  color: var(--headings-blue);
  padding: 0.79vw 1.32vw;
}
.select_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.offers_years > h4 {
  font-size: 1.1vw;
  font-weight: 600;
  margin: 0.5vw 0;
}
.years_select {
  position: relative;
}
.arrow_down {
  transform: rotateX(180deg);
}
.arrow_up {
  transform: rotateX(0deg);
}
.years_select > img {
  position: absolute;
  top: 45%;
  right: 1.4vw;
  cursor: pointer;
  width: 0.7vw;
  z-index: 10;
}
.years_select > input {
  font-weight: 400;
  font-size: 1.06vw;
  padding: 0.7vw 3vw 0.7vw 1.32vw;
  border-radius: 0.7vw;
  outline: none;
  border: 1px solid var(--dark-grey);
  color: var(--dark-grey);
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}

@media only screen and (max-width: 520px) {
  input[type='radio'] {
    width: 5.13vw;
    height: 5.13vw;
    color: var(--headings-blue);
    border-color: var(--headings-blue);
    padding: 1px;
  }
  .years_select > img {
    right: 2.5vw;
    width: 2vw;
  }
  .years_select > input {
    font-size: 3vw;
  }
  input[type='radio']:checked {
    width: 5.13vw;
    height: 5.13vw;
    color: var(--headings-blue);
    border-color: var(--headings-blue);
  }
  input[type='radio']::before {
    background-color: var(--headings-blue);
    width: 3.3333vw;
    height: 3.3333vw;
  }
  .offers_years > h4 {
    font-size: 3vw;
  }
  .empty_offers_list > h2 {
    font-size: 5vw;
  }
  .modal_content {
    width: 85.5vw;
    padding: 7.69vw 3.59vw;
    height: 125vw;
    margin-bottom: 0;
  }
  .modal_content > img {
    height: 3.93vw;
  }
  .modal_content > h2 {
    font-weight: 500;
    font-size: 4.6vw;
    padding: 0 3.59vw;
    margin-bottom: 5.12vw;
  }
  .offers_years {
    flex-direction: column;
  }
  .offers_year > span {
    font-size: 3.59vw;
    font-weight: 600;
    margin-left: 2.56vw;
  }
  .offers_year:nth-child(2) {
    margin: 5.12vw 0;
  }
  .empty_block {
    font-size: 4.1vw;
  }
  .count_offer {
    font-size: 4.1vw;
  }
  .sticky_block {
    max-width: 50%;
    overflow-y: initial;
  }
  .students_details_title {
    max-width: 100%;
    font-size: 4.1vw;
    font-weight: 600;
  }
  .subtitle_item,
  .value_item {
    font-weight: 400;
    font-size: 4.1vw;
  }
  .years_select {
    padding: 1vw 0 2vw;
  }
  .years_select > img {
    top: 36%;
  }
  .sort_information {
    font-size: 2.5vw;
    align-self: flex-start;
    padding-bottom: 10px;
  }
  .scroll_block::-webkit-scrollbar {
    width: 2vw;
    height: 2vw;
  }
  .sticky_block::after,
  .offers_table::after {
    top: -3.5vw;
  }
  .select_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .years_table_container,
  .offers_flex_container,
  .offers_flex_container > div {
    width: 100%;
  }
  .scroll_block {
    width: 50%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .empty_block {
    border: none;
  }
  .students_details_title {
    padding-left: 14vw;
  }
  .sticky_block > div:nth-child(2) {
    padding-left: 27vw;
  }
  .offers_table_container {
    border-radius: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 1%;
    box-sizing: border-box;
  }
  .sticky_block::after {
    opacity: 0;
  }
  .count_offer {
    padding-left: 25%;
  }
}
