.admin-dashboard > div,
.admin-dashboard > a{
  width: 30vw;
  background-color: var(--admin-main);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 15vw;
  border-radius: 5px;
  font-size: 2vw;
  font-family: 'Montserrat';
  cursor: pointer;
  margin: 1%;
  text-decoration: none;
  text-align: center;
}
.admin-dashboard > a:hover,
.admin-dashboard > div:hover {
  background-color: var(--admin-hover);
}
.admin-dashboard-wrapper {
  display: flex;
  justify-content: center;
  background: #f8fafc;
}
.admin-dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin-top: 5vw;
  width: 70%;
}
.admin-dashboard > a > img,
.admin-dashboard > div > img {
  height: 30%;
}

@media only screen and (max-width: 900px) {
  .admin-dashboard-wrapper {
    height: 95vh;
  }
}