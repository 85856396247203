.wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-height: 5rem;
    overflow-y: scroll;
}
.container::-webkit-scrollbar {
    width: 0.25rem;
    height: 2rem;
}
.container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}
.container::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
}


.item {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    color: var(--headings-blue);
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    background: #f6f7f9;
    border: 0.5px solid #d1d5db;
    border-radius: 29px;
    padding: 0.3rem 0.75rem;
    width: fit-content;
    margin: 0 2% 2% 0;
    white-space: break-spaces;
    align-items: center;
}

.itemText {
    margin: 0;
}

.crossIcon {
    width: 0.75rem;
    height: 0.75rem;
    cursor: pointer;
}