.header-left-block-admin {
  display: flex;
  flex-direction: column;
}

.header-left-block-admin .edit-user {
  font-size: 2.5vw;
  color: #030054;
  font-weight: 900;
}

.header-left-block-admin>a,
.header-left-block-admin>div {
  padding: 5% 0;
  font-size: 1.3vw;
  font-family: ''Source Sans 3', sans-serif';
  font-style: normal;
  text-align: center;
  text-decoration: none;
}

.header-right-block-admin {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-right-block-admin>button {
  border: none;
  background: var(--color-primary);
  border-radius: 40px;
  color: white;
  font-size: 20px;
  padding: 15px 10px;
  cursor: pointer;
  width: 320px;
}

.header-right-block-admin>.header-right-block-admin-login-btn {
  background-color: #030054;
  margin-bottom: 20px;
}

.header-left-block-admin>a>span,
.header-left-block-admin>div>span {
  display: flex;
  align-items: center;
}

.header-left-block-admin>a>span>img,
.header-left-block-admin>div>span>img {
  width: 2vw;
  max-width: 20px;
  margin-right: 10px;
}

.header-user-profile-admin {
  display: flex;
  justify-content: space-between;
  padding: 3% 7%;
  background-color: white;
  align-items: center;
}

.input-name-admin {
  margin-bottom: 5%;
}

.my-profile-body-admin {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid #f1f1f1;
}

.my-profile-body-admin>div {
  display: flex;
  padding: 3rem 2rem;
  justify-content: space-between;
}

.input-location-admin>p,
.input-select-admin>p {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 2vw;
  align-self: baseline;
  padding-bottom: 10px;
  position: relative;
  bottom: 87%;
}

.my-profile-body-admin .selected-colleges-list {
  width: 45%;
  justify-content: flex-start;
}

.input-select-admin .selected-colleges-list {
  margin-top: -8vw;
  margin-bottom: 6vw;
}

.input-location-admin,
.input-select-admin {
  position: relative;
  width: 45%;
  margin-bottom: -7%;
}

.my-profile-body-admin .upgrade-button {
  margin-bottom: 5%;
  justify-content: center;
  padding: 0 5% 2%;
}

.my-profile-body-admin .upgrade-button>button {
  border: none;
  background: var(--color-primary);
  border-radius: 30px;
  color: white;
  font-size: 1.5vw;
  padding: 1% 2%;
  cursor: pointer;
}

.input-location-admin>.autocomplete,
.input-select-admin>.autocomplete {
  width: 100%;
}

.input-location-admin>input,
.input-select-admin>input {
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 1vw 2vw;
  margin: 0 5px;
  margin-bottom: 2vw;
  font-family: ''Source Sans 3', sans-serif';
  font-weight: 600;
  font-size: 1.5vw;
  line-height: 24px;
  color: rgba(70, 70, 70, 0.8);
  width: -webkit-fill-available;
}

.my-profile-form-admin>input:invalid,
.input-location-admin>input:invalid,
.input-select-admin>input:invalid {
  border: 2px solid red;
}

.my-profile-form-admin>input:invalid::placeholder,
.input-location-admin>input:invalid::placeholder,
.input-select-admin>input:invalid::placeholder {
  color: red;
}

.input-location-admin>input:focus:valid+p,
.input-select-admin>input:focus:valid+p {
  color: black;
}

.input-location-admin>input:invalid+p,
.input-select-admin>input:invalid+p {
  color: red;
}

.user-subscriptions-body-admin {
  background: white;
  width: 82%;
  margin-left: 4%;
  margin-top: 2px;
  padding: 5%;
}

.user-subscriptions-body-admin>header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.user-subscriptions-body-admin>header>div {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  gap: 10px;
}

.user-subscriptions-body-admin>header>div>h3 {
  color: var(--headings-blue);
  font-size: 2vw;
}

.user-subscriptions-body-admin>header>div>h3>span {
  color: var(--color-primary);
}

.user-subscriptions-body-admin>header>div>button {
  border: none;
  background: var(--color-primary);
  border-radius: 40px;
  color: white;
  font-size: 1.5vw;
  padding: 1% 2%;
  cursor: pointer;
}

.user-subscriptions-body-admin>table {
  width: 100%;
  display: flex;
  border-radius: 11px;
  border-collapse: collapse;
  overflow-x: auto;
}

.user-subscriptions-body-admin>table>thead>tr {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-subscriptions-body-admin>table>thead {
  width: 100%;
}

.user-subscriptions-body-admin>table>thead>tr>th {
  text-align: start;
  color: var(--headings-blue);
}

.user-subscriptions-body-admin>table>thead>tr>th:last-child {
  height: 100%;
}

.user-subscriptions-body-admin>table>thead>tr {
  height: 100%;
}

.user-subscriptions-body-admin>table>tbody {
  display: flex;
  width: 100%;
}

.user-subscriptions-body-admin>table>tbody>tr {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: none;
}

.user-subscriptions-body-admin>table,
.user-subscriptions-body-admin>table th,
.user-subscriptions-body-admin td {
  border: 1px solid rgba(194, 194, 194, 1);
  font-weight: bold;
}

.user-subscriptions-body-admin th,
.user-subscriptions-body-admin td {
  padding: 20px;
  white-space: nowrap;
  line-height: 28px;
  font-family: 'Source Sans 3';
}

.user-subscriptions-body-admin>table th {
  font-family: 'Source Sans 3';
  font-size: clamp(16px, 1.5vw + 1px, 24px);
  font-weight: 600;
}

.user-subscriptions-body-admin .cancel-btn {
  padding: 10px 20px;
}

.user-subscriptions-body-admin .cancel-btn>button {
  height: 48px;
  font-family: 'Source Sans 3';
  font-size: clamp(14px, 1.2vw + 1px, 20px);
  font-weight: 600;
}

.user-subscriptions-body-admin>header>h3 {
  font-family: 'Source Sans 3';
  font-size: clamp(18px, 2vw + 1px, 32px);
  font-weight: 600;
}

.user-subscriptions-body-admin>header>h3>span {
  color: var(--color-primary);
}

.user-subscriptions-body-admin>table button {
  border: none;
  background: #d33841;
  border-radius: 40px;
  color: white;
  font-size: 15px;
  padding: 2%;
  cursor: pointer;
  width: 130px;
  height: 50px;
}

.user-subscriptions-body-admin>table tr:last-child td:first-child {
  border-top-right-radius: 10px;
}

.user-subscriptions-body-admin>table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.user-subscriptions-body-admin thead tr:last-child th:first-child {
  border-top-left-radius: 10px;
}

.user-subscriptions-body-admin thead tr:last-child th:last-child {
  border-bottom-left-radius: 10px;
}

/*.user-subscriptions-body-admin thead tr:last-child th {*/
/*  border-right-color: #fff;*/
/*}*/
.subscription-active {
  color: #3ead27;
}

.subscription-expired {
  color: #d33841;
}

.admin-user-profile-body-wrapper {
  border-radius: 10px;
  background: white;
  width: 100%;
}

.admin-body-wrapper {
  background-color: #f1f1f1;
  padding: 3rem 5rem;
  display: flex;
  justify-content: center;
}

.input-location-admin .container {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 0px;
  margin-top: -5rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  color: var(--headings-blue);
}

.input-location-admin .checkmark {
  position: absolute;
  left: 16px;
  border: 3px solid var(--headings-blue);
  border-radius: 10px;
  height: 25px;
  width: 25px;
  background: none;
  top: 6px;
}

.header-right-block-admin .error-message {
  color: #d33841;
}

.header-right-block-admin .success-message {
  color: #3ead27;
}

.my-profile-body-admin .upgrade-button-submit>button {
  background-color: #fff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  width: 300px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.my-profile-body-admin>div.input-name-admin {
  padding: 2rem 4rem;
}

.header-left-block-admin>a>span, .header-left-block-admin>div>span {
  color: #030054;
}

.user-subscriptions-body-admin>header>h3 {
  color: #030054;
}

.student-took-act-admin {
  color: var(--headings-blue);
  font-weight: 600;
  font-size: 2vw;
  align-self: baseline;
  padding-bottom: 10px;
  position: relative;
}

.student-took-act-sat-block-admin {
  margin-top: -70px;
}

input[type='radio'].choice-radio-curcle {
  border: 0.2em solid var(--headings-blue) !important;
  border-radius: 50% !important;
  height: 50px !important;
  margin: 0 !important;
  width: 50px !important;
}

input[type='radio'].choice-radio-curcle:checked::before {
  transform: scale(1);
}

input[type='radio'].choice-radio-curcle::before {
  background-color: var(--headings-blue);
  width: 30px;
  height: 30px;
  content: '';
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.choice-radio>div>.choice-radio-text {
  color: rgba(0, 0, 0, .5);
  font-size: 24px;
  margin-left: 15px;
}

.student-took-input {
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 1vw 2vw;
  margin: 0 5px;
  margin-bottom: 2vw;
  font-family: ''Source Sans 3', sans-serif';
  font-weight: 600;
  font-size: 1.5vw;
  line-height: 24px;
  color: rgba(70, 70, 70, 0.8);
  width: -webkit-fill-available;
}

.student-took-act-admin-inside {
  margin-bottom: 8px;
}

.student-took-act-sat-block-left-admin,
.student-took-act-sat-block-right-admin {
  width: 45%;
}

.choice-radio-sat,
.choice-radio-act {
  width: 100%
}

.downloaded-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(194, 194, 194, 1);
  padding: 12px 20px 13px 20px;
  border-radius: 12px;
  margin-top: 20px;
}

.downloaded-lists>h5 {
  margin: 0;
  font-family: 'Source Sans 3';
  font-size: clamp(16px, 1.5vw + 1px, 24px);
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .input-location-admin .checkmark {
    height: 20px;
    width: 20px;
  }

  .user-subscriptions-body-admin>header>div>button {
    font-size: 20px;
    padding: 5px 10px;
  }

  .header-left-block-admin>a>span, .header-left-block-admin>div>span {
    font-size: 22px;
    font-weight: bolder;
  }

  .input-location-admin .checkmark {
    top: -18px;
  }

  .input-location-admin .container>p {
    font-size: 18px;
  }

  .student-took-act-sat-block-left-admin,
  .student-took-act-sat-block-right-admin {
    margin-top: 45px;
  }

  input[type='radio'].choice-radio-curcle {
    width: 30px !important;
    height: 30px !important;
  }

  input[type='radio'].choice-radio-curcle::before {
    width: 20px;
    height: 20px;
  }

  .choice-radio>div>.choice-radio-text {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .header-left-block-admin>a>span, .header-left-block-admin>div>span {
    font-size: 20px;
  }

  .header-right-block-admin>button,
  .header-right-block-admin>.header-right-block-admin-login-btn {
    font-size: 18px;
  }

  .user-subscriptions-body-admin>header>div>button {
    font-size: 16px;
  }

  .user-subscriptions-body-admin>header>h3 {
    color: #030054;
    font-size: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .container .checkmark:after {
    left: 0.6vw;
    top: 0;
    width: 1vw;
    height: 1.5vw;
    border: solid var(--headings-blue);
    border-width: 0 0.4vw 0.4vw 0;
    transform: rotate(45deg);
  }

  .user-subscriptions-body-admin>header>div>button {
    min-width: 110px;
  }

  .user-subscriptions-body-admin>header>div {
    width: 55%;
  }
}

@media only screen and (max-width: 650px) {

  .header-right-block-admin>button,
  .header-right-block-admin>.header-right-block-admin-login-btn {
    font-size: 16px;
    width: 250px;
  }

  .user-subscriptions-body-admin>header>div>button {
    font-size: 13px;
    padding: 5px;
  }
}

@media only screen and (max-width: 520px) {

  .header-right-block-admin>button,
  .header-right-block-admin>.header-right-block-admin-login-btn {
    font-size: 14px;
    padding: 10px 5px;
    width: 200px;
  }

  .header-left-block-admin .edit-user {
    font-size: 16px;
  }

  .user-subscriptions-body-admin>header {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .user-subscriptions-body-admin>header>div>button {
    font-size: 18px;
    padding: 10px;
  }

  .user-subscriptions-body-admin>header>div {
    width: 100%;
    justify-content: center;
  }

  .input-location-admin>p, .input-select-admin>p {
    color: var(--headings-blue);
    font-weight: 600;
    font-size: 2vw;
    align-self: baseline;
    padding-bottom: 0;
    position: relative;
    bottom: 90%;
  }

  .input-location-admin>.input-location-admin-text-first-row {
    margin: 0;
  }

  .input-location-admin>input, .input-select-admin>input {
    font-size: 2.3vw;
  }

  .input-location-admin, .input-select-admin {
    height: 75px;
  }

  .header-left-block-admin>a>span>img,
  .header-left-block-admin>div>span>img {
    width: 4vw;
  }

  .input-location-admin .container>p {
    font-size: 14px;
  }

  .input-location-admin .checkmark {
    height: 12px;
    width: 12px;
    border-radius: 5px;
  }

  .input-location-admin .container {
    padding-left: 30px;
    margin-top: -15%;
  }

}

@media only screen and (max-width: 391px) {
  .header-right-block-admin>.header-right-block-admin-login-btn {
    background-color: #030054;
    margin-bottom: 5px;
  }

  .user-subscriptions-body-admin>header>div>button {
    font-size: 14px;
    padding: 10px;
  }

  .input-location-admin, .input-select-admin {
    height: 85px;
  }

  .input-location-admin>.input-location-admin-text-first-row {
    margin: 0;
    margin-top: 12px;
  }
}

#loading-cancel-subscription,
#loading-submit-subscription {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

#loading-submit-subscription {
  border: 3px solid #620d59;
  border-top-color: #fff;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}