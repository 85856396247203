.admin-configuration__main {
    padding: 20px 30px;
}

.admin-configuration__cont-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    padding-bottom: 8px;
    border-bottom: 1px solid #ced7d7;
}

.admin-configuration__cont-title__img {
    width: 35px;
    height: 35px;
}

.admin-configuration__cont-title__title {
    color: var(--headings-blue);
    font-size: 32px;
    font-weight: 600;
    align-items: center;

    padding: 0;
    margin: 0;
}

.admin-configuration__table__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
}

.admin-configuration__table__item > p,
.admin-configuration__table__header > span {
    color: var(--headings-blue);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.admin-configuration__table__item > p:first-child,
.admin-configuration__table__header > span:first-child {
    width: 15%;
    text-align: center;
}

.admin-configuration__table__item > p:nth-child(2),
.admin-configuration__table__header > span:nth-child(2) {
    width: 25%;
    text-align: center;
}

.admin-configuration__table__item > p:nth-child(3),
.admin-configuration__table__header > span:nth-child(3) {
    width: 40%;
}

.admin-configuration__table__item > div:last-child,
.admin-configuration__table__header > span:last-child {
    width: 20%;
}

.admin-configuration__table__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.admin-configuration__table__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;

    padding: 40px 0;
    background: #ffffff;
}

.admin-configuration__table__item.odd {
    background: #eff1f1;
}

.admin-configuration__table__item__cont-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-configuration__table__item > p {
    font-weight: normal;
    font-size: 16px;
    white-space: pre-wrap;
    margin: 0;
}

.admin-configuration__table__item > p.link {
    word-break: break-word;
}

.admin-configuration__table__item__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--headings-blue);
    border-radius: 15px;
    border: 0;
    height: 30px;
    width: 80px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}


/*LOADER*/
.admin-loader {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
}
.admin-loader:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: admin-loader 1.2s linear infinite;
}
@keyframes admin-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
