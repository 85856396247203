.modal-advanced-search-universal.modal-not-allowed {
    max-width: 450px;
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

.not-allowed-modal {
    background: rgba(0, 0, 0, 0.3) !important;
    backdrop-filter: blur(5px);
    z-index: 10;
    display: flex;
    justify-content: center !important;
    align-items: center;
    border-radius: 10px;
}

.modal-advanced-search-universal.modal-not-allowed > p {
    padding: 0 7px;
    text-align: center;
    margin: 8px 0;
}

.modal-advanced-search-universal.modal-not-allowed > h5 {
    text-align: center;
}

.modal-advanced-search-universal.modal-not-allowed > div {
    border: none;
    padding: 0;
}

.modal-advanced-search-universal.modal-not-allowed .modal-advanced-search-universal-button {
    margin: 0 auto;
    min-width: 180px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
}

.modal-not-allowed .lock-icon {
    margin: 0 auto;
    width: 25px;
    object-fit: contain;
}