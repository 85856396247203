.email-modal {
    padding: 32px 40px;
    box-sizing: border-box;

    background: var(--white-color);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
}

.email-modal__cont-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
}

.email-modal__arrow {
    width: 22px;
    height: 18px;
    cursor: pointer;
}

.email-modal__title {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: var(--color-primary);
    cursor: pointer;

    margin: 0;
    padding: 0;
}

.email-modal__multi-select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 30px;
}

.email-modal__multi-select__form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 600px;
    box-sizing: border-box;
    padding: 20px;
    max-height: 200px;
    overflow-y: scroll;

    border: 2px solid #a6a6a6;
}

.email-modal__multi-select__selected-emails {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.email-modal__multi-select__selected-email {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    padding: 8px 12px;

    background: rgba(126, 16, 110, 0.05);
    border: 1px solid var(--color-primary);
    border-radius: 30px;
}

.email-modal__multi-select__selected-email__text {
    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: var(--color-primary);
    padding: 0;
    margin: 0;
}

.email-modal__multi-select__selected-email__img {
    width: 12px;
    height: 12px;

    cursor: pointer;
}

.email-modal__multi-select__input {
    border:0;
    outline:0;

    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: var(--color-primary);
    width: 100%;
}

.email-modal__multi-select__input:focus {
    outline:none!important;
}

.email-modal__multi-select__cont-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    margin-top: 32px;
}

.email-modal__btn {
    background: var(--admin-main);
    border-radius: 24px;
    border: 0;
    padding: 9px 0;
    width: 180px;

    font-family: 'Source Sans 3', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--white-color);

    cursor: pointer;
}

.email-modal__btn.share {
    background: var(--color-primary);
}

/* Tablet */
@media only screen and (max-device-width: 768px) {}

/* Mobile */
@media only screen and (max-device-width: 480px) {
    .email-modal {
        width: 350px;
        padding: 24px;
    }

    .email-modal__title {
        font-size: 24px;
    }

    .email-modal__arrow {
        width: 16px;
        height: 14px;
    }

    .email-modal__multi-select__input,
    .email-modal__multi-select__input::placeholder,
    .email-modal__multi-select__selected-email__text {
        font-size: 14px;
    }

    .email-modal__multi-select__input {
        width: 100%;
    }

    .email-modal__btn {
        width: 140px;
        font-size: 20px;
    }

    .email-modal__multi-select__form {
        width: 100%;
        padding: 13px;
        gap: 8px;
    }

    .email-modal__multi-select__selected-emails {
        gap: 8px;
    }

    .email-modal__multi-select__cont-btn,
    .email-modal__multi-select {
        margin-top: 30px;
    }
}

