.autocomplete {
  position: absolute;
  top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: unset !important;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  padding: 0 1rem;
  z-index: 5;
  background: white;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.autocomplete.college_details {
  width: 24.5vw;
}

.autocomplete>.options>.container>.my-list-checkmark {
  width: 1vw;
  height: 1vw;
  left: -10px;
  top: -10px;
  border: 1px solid var(--headings-blue);
}

/*.autocomplete > .options {*/
/*  word-break: break-all;*/
/*}*/

.autocomplete>.options>.container>.my-list-checkmark:after {
  border: 1px solid var(--headings-blue);
  border-width: 0 1px 1px 0;
}

.autocomplete::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.autocomplete::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.autocomplete::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}

.autocomplete>div {
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  font-size: 16px;
  font-family: 'Inter';
}

.autocomplete>.not-allowed-item {
  cursor: not-allowed;
  position: relative;
}

.autocomplete>.not-allowed-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.autocomplete>.not-allowed-item::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
}

.autocomplete>.not-allowed-item:hover {
  background-color: white;
}

.autocomplete>div:hover {
  background: #e1ebf4;
}

.autocomplete>.item-hover {
  background: #e1ebf4;
}

.options>img {
  position: absolute;
  left: 7%;
}

.autocomplete.offers-table>div {
  font-size: 1vw;
}

@media only screen and (max-width: 520px) {
  .autocomplete>.options>.container>.my-list-checkmark {
    width: 3vw;
    height: 3vw;
  }
}

@media only screen and (max-width: 1000px) {
  .autocomplete {
    top: 50px;
  }

  .autocomplete.offers-table>div {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1500px) {
  .autocomplete>.options>.container>.my-list-checkmark {
    width: 1.2vw;
    height: 1.2vw;
    left: -10px;
    top: -17px;
  }
}