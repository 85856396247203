.modalContainer {
    background-color: white;
    border-radius: 20px;
    width: 50vw;
    max-width: 570px;
}
.modalContainer > h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--headings-blue);
    margin: 0;
    padding: 3% 0 0 5%;
}
.modalContainer > input {
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    padding: 2% 4%;
    border: 1px solid #c2c2c2;
    border-radius: 30px;
    width: 80%;
    margin: 15px 5% 20px 5%;
    font-family: 'Montserrat';
}
.modalContainer > div {
    display: flex;
    justify-content: flex-end;
    padding: 2% 5% 3%;
}
.modalContainer > .buttonWrapper {
    border-top: 1px solid #e4e6ed;
}
.modalContainer > img {
    position: absolute;
    right: 5%;
    top: 13%;
    cursor: pointer;
}
.negativeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 76px;
    font-weight: 600;
    background: #ffffff;
    border: 1px solid #cbd0e1;
    border-radius: 20px;
    font-family: 'Nunito Sans';
    cursor: pointer;
}
.positiveModalButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 76px;
    font-weight: 600;
    background: var(--color-primary);
    border: none;
    border-radius: 20px;
    color: white;
    white-space: nowrap;
    margin-left: 20px;
    font-family: 'Nunito Sans';
    cursor: pointer;
}
.error {
    color: red;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loader {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
@media only screen and (max-width: 520px) {
    .modalContainer {
        width: 85vw;
    }
}
