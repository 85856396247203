.college_details_cont {
  padding: 5rem;
  background-color: #e5e5e5;
}

.college_details_wrapper {
  padding: 3rem;
  position: relative;
  background: white;
  border-radius: 2rem;
}

.return_back {
  display: flex;
  cursor: pointer;
  position: absolute;
  z-index: 0;
  padding: 2% 3%;
  text-decoration: none;
}

.return_back>p {
  padding-left: 1rem;
  font-size: 1rem;
  color: var(--color-primary);
}

.return_back>img {
  width: 1rem;
}

.college_photo {
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: photo;
}

.college_photo>.photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2rem;
}

.main_college_block {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-template-areas:
    'name -'
    'photo info'
    'photo button';
}

.youtube_video_container {
  position: absolute;
  top: 40%;
  left: 40%;
  display: inline-block;
}

.college_details_buttons>button>a {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: normal;
  padding: 0;
}

.college_name_mobile {
  display: none;
}

.right_main_block {
  grid-area: name;
}

.right_main_block>h4 {
  margin: 2% 0;
  font-size: 2vw;
}

.right_main_block>h4>span {
  color: var(--primary-typography);
  font-size: 2vw;
}

.right_main_block>header {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.right_main_block>header>div>img {
  cursor: pointer;
  width: 1vw;
  max-height: 30px;
}

.right_main_block>header>h3 {
  font-weight: 600;
  font-size: 1.5rem;
  height: auto;
  color: var(--headings-blue);
  margin: 0;
  margin-right: 1rem;
  font-family: 'Montserrat', serif;
  font-style: normal;
}

.college_name {
  color: var(--color-primary);
}

.college_details_buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.right_main_block_info {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  grid-area: info;
}

.right_main_block_info .right_main_block_res {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-top: 0;
  justify-content: flex-end;
  width: 45%;
}


.right_main_block_info>div>h4, .right_main_block_info>div>span {
  margin: 0 0 1rem 0;
  line-height: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.college_details_buttons>a {
  text-align: center;
  text-decoration: none;
}

.college_details_buttons>a,
.college_details_buttons>button {
  padding: 1.25rem 1rem;
  white-space: nowrap;
  width: 14rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  cursor: pointer;
  position: relative;
}

.college_details_buttons>button>a {
  color: white;
  text-decoration: none;
}

.college_details_navigations {
  display: flex;
  justify-content: space-between;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  /*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
  padding: 0 3%;
}

.college_details_navigations>h6 {
  padding: 1vw 0;
  margin: 0;
  cursor: pointer;
  font-size: 1.2vw;
}

.college_details_navigations {
  margin: 1.7vw 0;
}

.selected_nav {
  color: var(--color-primary);
  border-bottom: 6px solid var(--color-primary);
  border-radius: 4px;
  width: fit-content;
}

.college_details_buttons .primary_button_first {
  border: none;
  background: #a7a7a7;
  color: white;
  border-radius: 40px;
  padding-left: 4%;
  font-weight: 600;
  margin: 0 1vw;
  width: 16.5vw;
}

.primary_button_first>img {
  width: 1vw;
  right: 13.2vw;
  top: 1.2vw;
  position: absolute;
}

.right_main_block>h4 {
  margin: 2% 0;
  font-size: 2vw;
}

.right_main_block>h4>span {
  color: var(--primary-typography);
  font-size: 2vw;
}

.right_main_block>header>div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.right_main_block>header>.college_header_mobile_container {
  flex-direction: row;
}

.image_block {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.image_block.locked_note {
  gap: .5rem;
  border-radius: 0;
  padding: 0;
}

.image_lock_icon {
  max-width: 18px !important;
}

.image_block img,
.right_main_block>header>div>img {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.right_main_block>header>img:last-child {
  position: absolute;
  right: -3vw;
}

.college_header_mobile {
  position: relative;
}

.autocomplete {
  width: 100%;
}

.college_name {
  color: var(--color-primary);
}

.college_details_navigations {
  display: flex;
  justify-content: space-between;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  padding: 0 3%;
  margin-bottom: 2vw;
}

.college_details_navigations>h6 {
  padding: 1vw 0;
  margin: 0;
  cursor: pointer;
  font-size: 1.2vw;
}

.selected_nav {
  color: var(--color-primary);
  border-bottom: 6px solid var(--color-primary);
  border-radius: 4px;
  width: fit-content;
}


.college_details_buttons .primary_button_first {
  border: none;
  background: #a7a7a7;
  color: white;
  border-radius: 40px;
  padding-left: 4%;
  font-weight: 600;
  margin: 0 1vw;
  width: 12.5vw;
}

.video_icon {
  position: absolute;
  top: 35%;
  left: 30%;
  width: 6rem;
  cursor: pointer;
}

.college_header_mobile {
  position: relative;
}

.autocomplete {
  width: 100%;
}

.college_name {
  color: var(--color-primary);
}

.college_details_navigations_cont {
  background: #f1f1f1;
  padding: 0 3% 0 0;
  /*margin-bottom: 2vw;*/
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.college_details_navigations {
  display: flex;
  justify-content: space-between;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
}

.college_details_navigations>h6 {
  padding: 1.5vw 0 1.1vw;
  margin: 0;
  cursor: pointer;
  font-size: 1.2vw;
}

.college_details_navigations_h6 {
  color: var(--color-primary);
  opacity: 0.5;
}

.selected_nav {
  color: var(--color-primary);
  border-bottom: 6px solid var(--color-primary);
  border-radius: 4px;
  width: fit-content;
  opacity: 1;
}

.college_details_navigations {
  margin-bottom: 0;
}

.right_main_block_res_mobile>div {
  display: none;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .college_details_cont {
    padding: 5rem 1rem;
  }

  .right_main_block_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2rem;
    gap: 1rem;
    grid-area: info;
  }

  .right_main_block_info .right_main_block_res {
    width: 100%;
  }

  .main_college_block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }


  .college_details_navigations>h6 {
    font-size: 11px;
  }

  .right_main_block>header>div>img {
    width: 1.5vw;
  }

  .right_main_block_info .right_main_block_res {
    margin-right: 0;
  }

  .primary_button_first>img {
    width: 1.5vw;
    left: 3vw;
    top: 1vw;
  }

  .right_main_block_info .right_main_block_res {
    margin-right: 0;
  }

  .primary_button_first>img {
    width: 1.5vw;
    left: 1vw;
    top: 0.9vw;
  }

  .college_details_navigations>h6 {
    font-size: 9px;
  }

  .right_main_block_info>div>h4, .right_main_block_res>span {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .right_main_block_info .right_main_block_res {
    margin-right: 0;
  }

  .right_main_block_res_mobile>div {
    display: flex;
    margin-bottom: 1rem;
  }

  .right_main_block_res_mobile>div>h4, .right_main_block_res_mobile>div>span {
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }

  .right_main_block_info {
    display: none;
  }

}

@media only screen and (max-width: 520px) {
  .college_details_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0;
  }

  /*  .main_college_block {
    grid-template-columns: 1fr 1fr;
  } */

  .right_main_block_info {
    margin-top: 15px;
  }


  .right_main_block_info {
    margin-left: 0;
  }

  .right_main_block {
    padding-left: 0;
    margin: 0;
  }

  .right_main_block>header>div>img {
    width: 4vw;
    max-height: 2.7vh;
  }

  .right_main_block>.college_name_mobile {
    display: block;
    font-weight: 600;
    font-size: 3.8vw;
    line-height: 4vw;
    color: var(--color-primary);
  }

  .college_details_buttons>button:nth-child(1) {
    margin-bottom: 10px;
  }

  .college_details_navigations {
    display: none;
  }

  .return_back {
    display: none;
  }

  .college_details_cont {
    border: none;
    padding-top: 7%;
  }

  .college_name {
    display: none;
  }

  .right_main_block_info .right_main_block_res {
    text-align: center;
  }

  .primary_button_first>img {
    width: 4vw;
    top: 4vw;
    right: 70vw;
  }

  .right_main_block_info {
    margin-top: 15px;
    margin-left: 0;
  }

  .right_main_block>header>div {
    flex-direction: column;
  }

  .right_main_block>header>div>img {
    width: 4vw;
    max-height: 2.7vh;
  }

  .right_main_block>.college_name_mobile {
    display: block;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 4.1vw;
    line-height: 5vw;
  }

  .college_details_navigations {
    display: none;
  }

  .college_details_cont {
    border: none;
    padding-top: 7%;
  }

  .college_name {
    display: none;
  }

  .college_details_cont {
    padding-left: 5%;
  }

  .right_main_block>header {
    align-items: flex-start;
  }

  .college_details_buttons .primary_button_first {
    width: 100%;
    margin: 3% 0;
    font-size: 4.6vw;
  }

  .college_details_buttons>button>a {
    font-size: 4.6vw;
  }

  .right_main_block>header {
    flex-direction: column;
  }

  .right_main_block>header>h3 {
    margin-right: 0;
  }

  .right_main_block>header>.college_header_mobile_container {
    flex-direction: column;
  }

  .right_main_block>header>img:last-child {
    position: absolute;
    right: -1%;
    top: 6vw;
  }

  .primary_button_first>img {
    width: 4vw;
    top: 3.8vw;
    right: 70vw;
    font-size: 4.6vw;
    left: 8vw;
  }

  .right_main_block_info {
    margin-top: 15px;
    margin-left: 0;
  }

  .right_main_block>header>div>img {
    width: 4vw;
    max-height: 2.7vh;
  }

  .right_main_block>.college_name_mobile {
    display: block;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 4.1vw;
    line-height: 5vw;
  }

  .college_details_navigations {
    display: none;
  }

  .college_details_cont {
    border: none;
    padding-top: 10%;
  }

  .college_name {
    display: none;
  }

  .college_details_cont {
    background: white;
    padding-left: 5%;
    padding-right: 6%;
  }

  .college_details_wrapper {
    padding: 0;
  }

  .right_main_block>header {
    align-items: flex-start;
  }

  .college_details_buttons .primary_button_first {
    width: 92.2%;
    margin: 3% 0;
    font-size: 4.6vw;
  }

  .image_block img {
    width: 5.5vw;
  }

  .right_main_block>header>div>img {
    max-width: none;
    max-height: none;
  }
}