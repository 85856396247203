.wrapper {
    position: sticky;
    bottom: 1rem;
    margin-left: 90%;
    background-color: rgba(126, 16, 110, 0.80);
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrow {
    display: inline-block;
    background: url("../../images/arrowUpOutline.svg");
    background-size: cover;
    width: 3.98275rem;
    height: 3.98275rem;
}


@media only screen and (max-width: 520px) {
    .wrapper {
        position: fixed;
        margin-left: 75%;
    }
}