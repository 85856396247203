.modal-advanced-search-universal {
    background-color: white;
    border-radius: 20px;
    width: 50vw;
    max-width: 570px;
}
.modal-advanced-search-universal > div {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e4e6ed;
    padding: 2% 5% 3%;
}
.modal-advanced-search-universal > h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--headings-blue);
    margin: 0;
    padding: 3% 0 0 5%;
}
.modal-advanced-search-universal > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 0 5%;
    margin-top: 8px;
}
.modal-advanced-search-universal > .modal-advanced-search-universal-img {
    position: absolute;
    right: 5%;
    top: 11%;
    cursor: pointer;
    width: 10px;
}
.modal-advanced-search-universal-cancel-button {
    padding: 8px 16px;
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 1.11vw;
    background: #ffffff;
    border: 1px solid #cbd0e1;
    border-radius: 20px;
    cursor: pointer;
    color: #403E59;
    text-decoration: none;
}
.modal-advanced-search-universal-cancel-button-hidden {
    visibility: hidden;
    width: 76px;
}
.modal-advanced-search-universal-button {
    padding: 8px 16px;
    background: var(--color-primary);
    border: none;
    border-radius: 20px;
    color: white;
    white-space: nowrap;
    margin-left: 20px;
    font-weight: 600;
    font-family: 'Nunito Sans';
    font-size: 1.11vw;
    cursor: pointer;
    text-decoration: none;
}
.modal-advanced-search-universal > .modal-advanced-search-universal-body {
    display: flex;
    flex-direction: column;
    border: none;
}
.modal-advanced-search-universal-body > div {
    display: flex;
}

@media only screen and (max-width: 920px) {
    .modal-advanced-search-universal > h5.modal-advanced-search-universal-title-looking {
        font-size: 2.5vw;
    }

    .modal-advanced-search-universal-cancel-button,
    .modal-advanced-search-universal-button {
        font-size: 1.6vw;
    }

}

@media only screen and (max-width: 590px) {
    .modal-advanced-search-universal-cancel-button, .modal-advanced-search-universal-button {
        font-size: 2vw;
    }
}


@media only screen and (max-width: 520px) {
    .modal-advanced-search-universal {
        width: 85vw;
    }
    .modal-advanced-search-universal-blur {
        height: 47vw;
    }


    .modal-advanced-search-universal > h5.modal-advanced-search-universal-title-looking {
        font-size: 4vw;
    }
    
    .modal-advanced-search-universal-cancel-button,
    .modal-advanced-search-universal-button {
        font-size: 2.5vw;
    }
}


@media only screen and (max-width: 390px) {
    .modal-advanced-search-universal-cancel-button,
    .modal-advanced-search-universal-button {
        font-size: 3vw;
    }
}

@media only screen and (max-width: 460px) {
    .modal-advanced-search-universal-cancel-button, .modal-advanced-search-universal-button {
        font-size: 2.9vw;
    }
}

@media only screen and (max-width: 460px) {
    .modal-advanced-search-universal-cancel-button, .modal-advanced-search-universal-button {
        font-size: 2.9vw;
    }
}


@media only screen and (max-width: 390px) {
    .modal-advanced-search-universal-cancel-button,
    .modal-advanced-search-universal-button {
        font-size: 3vw;
    }
}

@media only screen and (max-width: 320px) {
    .modal-advanced-search-universal > h5.modal-advanced-search-universal-title-looking {
        font-size: 4.2vw;
    }
    .modal-advanced-search-universal-cancel-button,
    .modal-advanced-search-universal-button {
        font-size: 2.5vw;
    }
}
