.count-download,
.count-download__cont-used,
.count-download_cont,
.count-download__cont-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.count-download {
    gap: 32px;
}
.count-download__cont-used {
    gap: 8px;
}

.count-download__cont-main {
    gap: 12px;
}

.count-download__used,
.count-download__used-count,
.count-download__count-click,
.count-download__available {
    font-family: 'Source Sans 3';
    font-size: clamp(16px, 1.5vw + 1px, 24px);
    font-weight: 600;
    color: #464646;

    padding: 0;
    margin: 0;
}

.count-download__used-count {
    color: #030054;
}

.count-download_cont {
    padding: 6px 8px;
    gap: 12px;

    background: var(--white-color);
    border: 1px solid var(--color-primary);
    border-radius: 24px;
}

.count-download__minus,
.count-download__plus {
    display: block;
    width: 32px;
    height: 32px;

    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../images/minusForm.svg");

    cursor: pointer;
}

.count-download__plus {
    background-image: url("../../images/plusForForm.svg");
}

.count-download__count-click {
    width: 20px;
    text-align: center;
}

#count-download__btn-submit {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 105px;
    height: 45px;

    background: var(--color-primary);
    border-radius: 30px;

    font-family: 'Source Sans 3';
    font-size: clamp(16px, 1.5vw + 1px, 24px);
    font-weight: 600;
    color: var(--white-color);
    border: none;

    cursor: pointer;
}