.inputsContainer {
    margin: .75rem 1rem 0 1rem
}

.inputsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.inputsContainer {
    gap: .75rem;
}
.inputWrapper {
    width: 22.25rem;
}

.autocomplete {
    top: 3rem;
    color: var(--primary-typography);
    font-size: 1rem;
    font-family: 'Source Sans 3', sans-serif;
}
.autocomplete > div {
    width: 100%;
}
.autocomplete > div:hover {
    background: rgba(126, 16, 110, 0.12);
}

.autocompleteSelectInput {
    border: 2px solid var(--white-color);
}

@media only screen and (max-width: 900px) {
    .inputsContainer.filtersModal {
        width: 25rem;
    }
    .inputsContainer.filtersModal {
        padding: 2rem 2.5rem;
        border-radius: 12px;
        background: var(--white-color);
        color: var(--color-primary);
    }
}


@media only screen and (max-width: 520px) {
    .inputsContainer.filtersModal {
        width: 100%;
    }
}