.modal-sign-up {
  background-color: white;
  border-radius: 20px;
  width: 50vw;
  max-width: 570px;
}
.modal-sign-up > h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--headings-blue);
  margin: 0;
  padding: 3% 0 0 5%;
}
.modal-sign-up > input {
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
  padding: 2% 4%;
  border: 1px solid #c2c2c2;
  border-radius: 30px;
  width: 80%;
  margin: 15px 5% 30px 5%;
  font-family: 'Montserrat';
}
.modal-sign-up > div {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e6ed;
  padding: 2% 5% 3%;
}
.modal-sign-up > img {
  position: absolute;
  right: 5%;
  top: 13%;
  cursor: pointer;
}
.cancle-button {
  padding: 8px 16px;
  width: 76px;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #cbd0e1;
  border-radius: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
}
.sign-up-modal-button {
  padding: 8px 16px;
  width: 76px;
  font-weight: 600;
  background: var(--color-primary);
  border: none;
  border-radius: 20px;
  color: white;
  white-space: nowrap;
  margin-left: 20px;
  font-family: 'Nunito Sans';
  cursor: pointer;
}
.create-list-err {
  color: red;
}
@media only screen and (max-width: 520px) {
  .modal-sign-up {
    width: 85vw;
  }
}
