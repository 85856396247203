.magic-dots.slick-dots ul {
  padding: 0;
  display: flex;
  transition: all 0.2s;
  position: relative;
  margin: 0;
}
.magic-dots.slick-dots {
  bottom: -3vw;
}
.magic-dots.slick-dots li.slick-active button:before {
  color: var(--color-primary);
  opacity: 1;
}
.magic-dots.slick-dots li button:before {
  transition: font-size 0.35s;
  font-size: 12px;
  content: '\2022';
}
.magic-dots.slick-dots li.small button:before {
  font-size: 8px;
  line-height: 20px;
}
